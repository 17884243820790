<template>
<div>

<v-container fluid class="purple-grad" style=" position: relative;">
    <v-row no-gutters style="height:700px">
        <v-col lg="3" md="3" class="purpleBarsleft hidden-sm-and-down"></v-col>
        <v-col lg="6" md="6" style="z-index:1">
            <div class="circlePosition circle" style="z-index:2"></div>
                <div class="text-center centered" style="z-index:3">
                <h1 class="my-10">A journey towards <br> Net Zero</h1>
                <p class="bodyBig">Carbon offsetting is the most immediate solution toward <br> achieving a net-zero future. Join CURBON and do your part in <br> transforming the way we treat the planet. </p>
            </div>
        </v-col>
        <v-col lg="3" md="3" class="purpleBarsRight hidden-sm-and-down"></v-col>
    </v-row>
</v-container>

<v-container fluid class="white text-center my-10">
    <v-row class=" my-10" >
        <v-col  lg="2" class="hidden-md-and-down dustyOrangeBars"></v-col>
        <v-col cols="12" md="6" lg="4" class="dustyOrange pa-5 mb-5 mb-md-0"
            style="border-left: solid 10px white !important; border-right: solid 10px white !important;"
        >
            <div>
            <h4 class="mb-4 text-left"><strong>Who we are</strong></h4>
            <p class="text-left mediumBody">
               We’re a small team on a mission to drive real-world change. CURBON is committed to making climate action cost-effective and accessible by helping people understand, manage and reduce their carbon impact.
            </p>
            </div>
        </v-col>
        <v-col cols="12" md="6" lg="4" class="projectsYellow pa-5 mt-5 mt-md-0"
            style="border-left: solid 10px white !important; border-right: solid 10px white !important;">
            <h4 class="mb-4 text-left"><strong>What we do</strong></h4>
            <p class="text-left mediumBody">
                CURBON equips businesses to provide a carbon offsetting solution to their customers at no added cost to company.  We calculate the climate cost of each transcation and utilise verified carbon credits to eliminate the ecological impact, helping cultivate a climate-conscious consumer culture. 
            </p>
        </v-col>
        <v-col lg="2" class="hidden-md-and-down dustyYellowBars"></v-col>
    </v-row>
</v-container>

<a id="partners"></a>
<br>
<v-container fluid class="lightGray px-10">
    <v-container class="my-10" wrap>
        <v-row class="mb-10">
            <v-flex sm12 md6>
                <h2 style="text-decoration:underline"  class="mb-10">Partners</h2>
                <p class="mediumBody">Carbon offsetting practices are pioneering the way forward and these innovative organisations are at the helm. We’ve partnered with these leading organisations to ensure that our services are transparent, effective and accountable. </p>
            </v-flex>
            <v-flex sm12 md6>
            </v-flex>
        </v-row>

        <v-row>
            <v-col cols="12" sm="6" md="5" class="pl-0" v-for="(partner,i) in partners" :key="partner.name">
                <v-card flat tile class=" lightGray pl-0" max-width="344">
                   <v-row class="ma-auto text-left">
                        <v-col cols="3" class="pl-0">
                            <a :href="partner.link" target="_blank" rel="noopener noreferrer">
                                <div style="height:60px; width:60px; padding:10px" class="charcoal">
                                    <v-img :src='partner.img'></v-img>
                                </div>
                            </a>
                        </v-col>
                        <v-col cols="6" class="text-left">
                            <p class="mediumBody text-left"><strong>{{partner.title}}</strong></p>
                        </v-col>
                   </v-row>
                    <p class="mt-8 smallBody">{{partner.intro}}</p>
                    <v-spacer></v-spacer>
                    <v-btn text class="text-decoration-underline  pa-0 smallBody" @click=openCard(i)>
                        Read More
                    </v-btn>

                    <v-expand-transition>
                    <v-card v-if="partner.readMore"  class="transition-fast-in-fast-out v-card--reveal">
                        <v-card-text class="pb-4">
                        <div style="height:60px; width:60px; padding:10px" class="charcoal">
                            <v-img :src='partner.img'></v-img>
                        </div>
                        <br>
                        <p class="smallBody">{{partner.description}}</p>
                        </v-card-text>
                        <v-card-actions class="pt-0">
                        <v-btn class="smallBody" text color="#A09FFA" @click="partner.readMore = ! partner.readMore">
                            Close
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                    </v-expand-transition>
                </v-card>
            
            </v-col>
        </v-row>
    </v-container>
</v-container>
<v-container fluid class="py-15">
    <v-container>
    </v-container>
        <v-row class="mx-5 mx-sm-10 mb-0 text-left" style="margin-left:0 !important; padding-left:0 !important">
            <v-col cols="1"></v-col>
            <v-col class="mx-sm-10 px-lg-n10 mx-lg-n4" cols="12" lg="6" xl="6">
                    <h3 style="text-decoration:underline" class="mb-5">Our Team</h3>
                    <p class="smallBody">We’re a small team trying to turn big ideas into reality. We prefer talking about climate change and how to curb it than we do ourselves, but here’s a little introduction to the creators.</p>
            </v-col>
            <v-col cols="1"></v-col>
        </v-row>
        <v-row class="my-md-10">
            <v-col  md="1" class="hidden-md-and-down dustyPurpleBars"></v-col>
            <v-col cols="12" lg="10" class="aboutTeamPurple pa-10 mt-5 mt-md-0">
                <v-row class="pa-5">
                    <v-col cols="12" sm="6">
                        <h4 class="mb-10"> Inspiring better business by connecting business and planet</h4>
                        <p class="smallBody">CURBON was founded by three deeply passionate individuals, who are on a mission to drive real world change; Steffen Burrows, Mark Jones, and Michael Pollock. Steffen is the overly enthusiastic climate change scholar, Mark’s an experienced creative entreprenuer, and Michael is our tech nerd. All three of us have vastly different qualities, but share the same unwavering commitment to curbing climate change.</p>
                    </v-col>
                    <v-col cols="12" sm="6" justify="center">
                        <img class="px-md-10 py-auto" style="width:100%;" src="images/assets/Images/Curbon team 1 1.png">
                    </v-col>
                </v-row>
            </v-col>
            <v-col md="1" class="hidden-md-and-down dustyPurpleBars rightDustyPurpleBars"></v-col>
        </v-row>
</v-container>
<v-container fluid class='charcoal'>
    <ReadyToGetStarted></ReadyToGetStarted>
</v-container>
</div>    
</template>

<script>
import ReadyToGetStarted from '../components/ReadyToGetStarted.vue';

export default {
    components:{
        ReadyToGetStarted
    },
    name:'About',
    data: () => ({
    partners:[
        // {name:'Gold standard',title:'Gold Standard',img:'images/assets/Icons/Goldstandard.svg', intro:'Gold Standard was established in 2003 by WWF and other international NGOs to ensure projects that reduced carbon emissions featured the highest levels of environmental...', readMore:false, description:'Gold Standard was established in 2003 by WWF and other international NGOs to ensure projects that reduced carbon emissions featured the highest levels of environmental integrity and also contributed to sustainable development. Since inception, Gold Standard has issued 191 million carbon credits from projects based in more than 98 different countries around the world. Gold Standard is often considered the authority in the carbon credit space and have their own carbon credit standard- Gold Standard for the Global Goals is a standard that sets requirements to design projects for maximum positive impact in climate and development.',link:'https://www.goldstandard.org/'},
        {name:'EXIOBASE',title:'Exiobase', img:'images/assets/Icons/Exiobase.svg', intro:'Exiobase produces country specific supply-use data which can be used for the analysis of the environmental impacts associated with the final consumption of product groups...', readMore:false, description:'Exiobase produces country specific supply-use data which can be used for the analysis of the environmental impacts associated with the final consumption of product groups. The EXIOBASE consortium consists of the Norwegian University of Science and Technology, Netherlands Organization for Applied Scientific Research, The Sustainable Europe Research Institute, and Vienna University of Economics and BusinessIt was developed by harmonizing and detailing supply-use tables for a large number of countries, estimating emissions and resource extractions by industry. The Exiobase tables give CURBON the ability to estimate the emissions of over 1000 product categories.',link:'https://www.exiobase.eu/'},
        {name:'Credbile Carbon ',title:'Credible Carbon',img:'images/assets/Icons/Credible-carbon-white-square.png', intro:'Credible Carbon is a registry that sells carbon credits from projects that are independently audited against carbon market standards that have been approved by the UNFCCC...', readMore:false, description:' Credible Carbon is a registry that sells carbon credits from projects that are independently audited against carbon market standards that have been approved by the UNFCCC. Credible carbon has created a transparent and robust carbon trading system that gets as much money as possible to projects that are part of South Africa’s climate change solution. Credible Carbon supports “real projects, real people, real benefits,... emission reduction plus poverty alleviation.”. CURBON is proud to purchase our South African carbon credits through such a reputable company. ',link:'https://www.crediblecarbon.com/'},
        {name:'The Green House',title:'The Green House' ,img:'images/assets/Icons/Greenhouse.svg', intro:'The Green House is a South African sustainability consulting company, with more than 30 years of collective experience working on a range of energy, climate...', readMore:false, description:'The Green House is a South African sustainability consulting company, with more than 30 years of collective experience working on a range of energy, climate change and sustainability-related projects. The Green House is comprised of a consulting team with years of experience in engineering and climate science, along with strong quantitative skills. These skills combined with their environmental passion make them the perfect consultants for CURBON.',link:'https://tgh.co.za/'},
    ],
      show: false,
      reveal: false,
    }),

    methods:{
         openCard(index){
              for (let i = 0; i < this.partners.length; i++) {
              if(i==index){
                this.partners[i].readMore=true;
              } else{
                this.partners[i].readMore=false;
              }
            }
        },
    }
}

</script>
 
<style>
.v-card--reveal {
  top: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
  height:auto;
  z-index:4;
}
</style>
