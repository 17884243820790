<template>
 <div>
<v-container fluid class="home-hero-image">
  <v-container>
      <v-row
      align="center"
      class="mt-10 mb-6 ml-6"
      justify="left"
      >
      <v-col cols="12" sm="6">
        <p class="subheading">LEGAL</p>
        <h2 class="mb-4">
          Terms of Service
        </h2>
        <p>Click <a style="color: #78786A !important;" href="/curbon-terms-of-use.pdf" target="_blank">here to download</a> our terms of service or read it below. </p>
      </v-col>
    </v-row>
  </v-container>
</v-container>

<v-container fluid >
  <v-container>
    <div>
      <div class="text-center">
            <h4>SOFTWARE AS A SERVICE TERMS AND CONDITIONS</h4>
            <p>
              Entered into between <br>
               CURBON TRADING (PTY) LTD <br>
              Reg. No. 2022/348970/07<br>
              (Hereinafter “CURBON TRADING”)<br>
              And<br>
              The party whose details are completed in terms of the Services Selection Agreement <br>
              (Hereinafter the “Customer”)
            </p> 
      </div>

      <ol>
        <li> <b>Definitions.</b>
            <br>
            In this Agreement, except where otherwise stated, the following definitions shall be applied:-

        <ol>
        <li> “Acceptable Use Policy"  means the acceptable use policy for the Services attached hereto as the AUP Schedule.</li>
        <li> “AUP” means the Acceptable Use Policy attached as the AUP Schedule.</li>
        <li> “Account” means Customer’s CURBON TRADING Account credentials and correlating access to the Services under this Agreement.</li>
        <li> “Admin Account” means a type of CURBON TRADING Account that a Customer may use to administer the Services.</li>
        <li> “Administrators” mean the Customer-designated personnel who administer the Services on a Customer’s behalf, and may have the ability to access Customer Data and the Services and may also be an End-User.</li>
        <li> “Affiliate” means any entity that directly or indirectly Controls, is Controlled by, or is under common Control with a party.</li>
        <li> “Brand Features” means each party’s trade names, trademarks, logos, domain names, and other distinctive brand features.</li>
        <li>  “Confidential Information” means information in relation to the protectable interests of the disclosing party, 
          which is not readily available in public domain or to any other person or entity and/or to a competitor or competitors 
          of the disclosing party in the ordinary, normal and regular course of business;
        </li>
        <li> “Control” means control of greater than 50% of the voting rights or equity interests of a party.</li>
        <li> “Core Services” means the Core Services as described further in terms of this agreement, such as the provision of the CURBON TRADING PLUGIN.</li>
        <li> “CURBON TRADING” means CURBON TRADING (PTY) LTD, a private company duly incorporated in terms of the laws of the Republic of South Africa with registration number: 2022/348970/07.</li>
        <li> “CURBON TRADING Account” means a CURBON TRADING-hosted account established by a Customer through its Administrator to use the Services.</li>
        <li> “CURBON TRADING PLUGIN” means the online console(s), dashboard(s), and/or tool(s) provided by CURBON TRADING to a Customer for administering the Services.</li>
        <li> “CURBON TRADING Services” means the services provided by CURBON TRADING to the Customer.</li>
        <li> “CURBON TRADING Indemnified Materials” means CURBON TRADING’s technology used to provide the Services and CURBON TRADING’s Branding and authorised Intellectual Property.</li>
        <li>“Customer”  means the person (natural, juristic, or otherwise) as reflected in the Services Selection Agreement, 
          and whose representative on having selected “I Accept” and/or signs the document, confirms that you are accepting on behalf of your employer or another entity, you represent and warrant that:
          <ol>
              <li>you have full legal authority to bind your employer or the applicable entity to these terms;</li>
              <li>you have read and understand this Agreement; and</li>
              <li>you agree on behalf of the party that you represent to this Agreement, and if you do not have authority to bind that party, that you are held responsible for the obligations in terms of this agreement.</li>
          </ol>
        </li>
        <li>“End User” means any person approved/using and/or seeking to engage as a prospective and/or actual client of the Customer through the CURBON TRADING PLUGIN.</li>
        <li>“Customer Data” means data submitted, stored, sent or received via the Services by the Customer, its Affiliates or End Users.</li>
        <li>“Customer Indemnified Materials” means the Customer Data.</li>
        <li>“Day” means a business day, and shall exclude Saturdays, Sundays and any recognised South African public Holiday.</li>
        <li>“Domain Name” means the domain name specified in the Services Selection Agreement to be used in connection with the Services.</li>
        <li>“End Users” means either the Authorised Representative of the Customer or an Administrator authorised in terms of this agreement.</li>
        <li>“Effective date” means the date you click the “I Accept” button below or, if applicable, the date the Agreement is countersigned.</li>
        <li>“Exchange Control Regulations” means all applicable export and Exchange Control Regulations and regulations as published and amended from time to time by the South African Reserve Bank.</li>
        <li>“Feedback” means feedback or suggestions about the Services provided to CURBON TRADING by a Customer.</li>
        <li>“Fees” shall mean either –
          <ol>
            <li> The percentage charged by CURBON TRADING against an End User’s cart total of the Merchants / Customer’s online store, 
              the percentage charged is set and agreed between the parties and billed automatically through the plugin when the End User makes payment; OR alternatively
            </li>
            <li> Such percentage that the Merchant / Customer offsets the End User’s cart total, paid by the Merchant on behalf of the End USer, in this event
                <ol>
                    <li>The fee is not charged for every transaction that occurs on the Merchants online store</li>
                    <li>The offset amount is confirmed by the Merchant / Customer and updated on the Merchants account with CURBON TRADING, and</li>
                    <li> And payments shall occur in the manner set out in terms of clause 4 hereinbelow.</li>
                </ol>
            </li>
          </ol>
        </li>

        <li>“High Risk Activities”  means activities that are prosecutable in terms of the
            Protection of Constitutional Democracy against Terrorist and Related Activities
            Act 33 of 2004 (as amended from time to time), and/or which successful
            prosecution will lead to a conviction under any schedule of the Criminal
            Procedure Act 51 of 1977 (as amended from time to time)
        </li>
        <li>“including” means including but not limited to.</li>
        <li>“Indemnified Liabilities” means any
            <ol>
              <li>(i) settlement amounts approved by the indemnifying party; and</li>
              <li>(ii) damages and costs finally awarded against the indemnified
                  party and its Affiliates by a court of competent jurisdiction.
              </li>
            </ol>
        </li>
   
        <li>"Intellectual Property" or "IP" means anything protectable by an Intellectual
             Property Right.
        </li>
        <li>“Intellectual Property Rights” means all patent rights, copyright, trade secrets
          (if any), trademark rights, design rights, database rights, domain name rights,
          moral rights, source code, and any other intellectual property rights (registered
          or unregistered) throughout the world.
        </li>
        <li>“Legal Process” means an information disclosure request made under law,
            governmental regulation, court order, subpoena, warrant, governmental
            regulatory or agency request, or other valid legal authority, legal procedure, or
            similar process.
        </li>
        <li>“Liability” means any liability, whether under contract, delict (including
            negligence), or otherwise, regardless of whether foreseeable or contemplated
            by the parties.
        </li>
        <li>“Notification Email Address” means the email address(es) designated by a Customer for all essential communications.</li>
        <li>“Order Term” means the period of time starting on the Services Start Date for
            the Services and continuing indefinitely, subject to early termination in
            accordance with this Agreement.
        </li>
        <li>
            “POPI” means the Protection of Personal Information Act 4 of 2013 (as
            amended from time to time).
        </li>
        <li>“Percentage Offset” means the applicable Percentage Offset of the total cart in
            order to offset the carbon equivalent cost of the End User’s purchase or in the
            alternative, where such offset is done by the Merchant on behalf of the
            customer, unless otherwise agreed to in an amendment.
        </li>
        <li>“Services” means the applicable Core Services and Other Services (including the
            CURBON TRADING Services) chosen by the Customer in terms of the applicable
            Services Selection Agreement.
         </li>
         <li>“Services Start Date” means the date stated in the Services Selection
              Agreement or, if later, the date CURBON TRADING makes the Services available
              to the Customer.
         </li>
         <li>“Services Summary” means the description of the services provided to the
              Customer in terms of the Services Selection Agreement.
         </li>
         <li>“Significant Deprecation” means a material discontinuance of or backwards
              incompatible change to the Core Services that results in Services no longer
              enabling a Customer or End Users to:
              <ol>
                <li>access the CURBON TRADING PLUGIN and use the CURBON TRADING PLUGIN in their online store</li>
                <li>process customer’s End Users data</li>
                <li>send and receive correspondence to and from End Users</li>
                <li>communicate with other End Users in real time; or</li>
                <li>search, archive, and export correspondence and data.</li>
              </ol>
          </li>
          <li>“Services Selection Agreement” means the Services Selection Agreement
              completed by the Customer, which Agreement specifically incorporates the
              Terms and Conditions of this Agreement, and includes such other online order
              issued by CURBON TRADING and accepted by the Customer.
           </li>
           <li>“Suspend” or “Suspension” means the disabling of access to or use of the
                Services, or components of the Services, including the right to suspend or
                disable third party software.
            </li>
            <li> “Taxes” means all government-imposed taxes, such as Valued Added Tax
                (“VAT”) in terms of the Value Added Tax Act 89 of 1991.
            </li>
            <li>“Term” means the period starting on the Effective Date and will remain in effect
                so long as there is an active Services Selection Agreement.
            </li>
            <li>“Third-Party Legal Proceeding” means any formal legal proceeding filed by an
              unaffiliated third party before a court or government tribunal (including any
              appellate proceeding).
            </li>
            <li>“Trademark Guidelines” means CURBON TRADING’s Brand Terms and
                Conditions and usage as set forth in the Trademark Guideline Schedule.
            </li>
            <li>“URL Terms” means the Acceptable Use Policy, and the Service Specific Terms.</li>
            <li>“Use Restrictions” means the restrictions set forth in this agreement in terms of
                clause 3.4 of the Agreement and any additional restrictions on the use of
                Services in the Service Specific Terms that may be agreed between the parties
                from time to time.
            </li>
        </ol>
        </li>

        <li class="mt-5"><b>Services.</b>
            <br>
            CURBON TRADING will provide the Services ordered in the applicable Services Selection
            Agreement. A Customer may use the Services ordered in the applicable Services Selection
            Agreement in accordance with this Agreement.

        <ol>
          <li> Facilities and Data Transfer. <br>
              All facilities used to store and process Customer Data will adhere to reasonable
              security standards no less protective than the security standards at facilities
              where CURBON TRADING stores and processes its own information of a similar
              type. As part of providing the Services, CURBON TRADING may transfer, store
              and process Customer Data, End User Data in any country in which CURBON
              TRADING or its agents maintain facilities. By using the Services, the Customer
              consents to this transfer, processing and storage of Customer Data and warrants
              that it has informed and obtained consent from its End User(s).
          </li>
          <li>New Features or Services. <br>
              CURBON TRADING retains the sole discretion and right to make new
              applications, features or functionality for the Services available from time to
              time, the use of which may be contingent upon a Customer’s agreement to
              additional terms.
           </li>
           <li>Verification to Use Services. <br>
              CURBON TRADING may require a Customer to verify third party software and/or
              services in order for CURBON TRADING to provide the service(s) to the
              Customer. The Customer shall provide CURBON TRADING with:-
              <ol>
                <li>a Domain Email Address or a Domain Name to use the Services;</li>
                <li>Third party application access permissions (which access will be
                    limited to rendering the service for Customer);
                </li>
              </ol>
              <p class="mt-2">If the Customer does not provide CURBON TRADING with the valid permissions
                  required to render its service(s) and/or does not give permission to use the
                  Domain Email Address and/or does not own or control the Domain Name, then
                  CURBON TRADING will have no obligation to provide the Customer with the
                  Services and may delete the Account and cease rendering any further Service to
                  the Customer without further notice.
              </p>
            </li>
            <li>
              Service Specific Terms. <br>
              The Service Specific Terms are incorporated by this reference into the
              Agreement.
            </li>
            <li>Modification(s) to services.
              <ol>
                <li>Deprecation Policy. <br>
                  CURBON TRADING will notify Customer at 14 days 3 before a
                  Significant Deprecation unless CURBON TRADING reasonably
                  determines that:
                  <ol>
                    <li>CURBON TRADING is not permitted to do so by
                        law or by contract (including if there is a change in
                        applicable law or contract)
                    </li>
                  </ol>
                </li>
                <li>
                  Other Modifications. <br>
                  <p>Subject to Section 2.5.1, CURBON TRADING may make changes to
                      the Services, which may include adding, updating, or discontinuing
                      any Services or portions of feature(s) of the Services. CURBON
                      TRADING will notify the Customer of any material change to the
                      Core Services.
                  </p>
                </li>
                <li>Objection to Changes. <br>
                  <p>Unless CURBON TRADING’s change to the URL Terms is required by
                      a court, judicial or administrative order issued by the competent
                      authority or by applicable law, or applies to new Services or
                      Functionality, then the following will apply:
                   </p>
                   <ol>
                    <li>If a change to the URL Terms has a material
                        adverse impact on a Customer, then Customer
                        may object to the change by notifying CURBON 
                        8TRADING within 30 days after CURBON TRADING
                        provides notice.
                    </li>
                    <li>If Customer so notifies CURBON TRADING,
                        then the Customer will remain governed by the
                        URL Terms in effect immediately before the
                        change until the earlier of:
                        <ol>
                          <li>the end of the thencurrent Order Term; or</li>
                          <li>3 (three) weeks after the notice was given.</li>
                        </ol>
                    </li>
                   </ol>
                </li>
              </ol>
            </li>
        </ol>
        </li>
        <li class="mt-5"><b>Customer Obligations.</b>
        <ol>
          <li>Compliance. <br>
            The Customer shall:- <br>
            <ol>
              <li>ensure that the Customer and its End Users’ and the End Users
                  use of the Services, including all access to and use of Customer Data
                  and End User Data by it and its End Users, complies with this
                  Agreement and with any of its applicable contract terms or policies,
                  including any employment contracts or employer’s policies
                  regarding technology usage, security, or confidentiality;
              </li>
              <li>use commercially reasonable efforts to prevent unauthorised
                  access or use of the Services; and
              </li>
              <li>promptly notify CURBON TRADING of any unauthorised use of, or access to, the Services of which the Customer becomes aware.</li>
            </ol>
          </li>
          <li> Additional Products. <br>
              CURBON TRADING makes optional Additional Products available to a Customer
              and its End Users. Use of Additional Products is subject to the Additional
              Product Terms so agreed to by the Customer.
          </li>
          <li> Administration of Services.
            <ol>
              <li>CURBON TRADING PLUGIN / ACCOUNT. <br>
                  CURBON TRADING will provide a Customer access to the CURBON
                  TRADING PLUGIN / ACCOUNT for the Customer and/or
                  Administrator(s) to manage its use of the Services (and use of the
                  Services by its End Users, if applicable). The Customer may use the 
                  9CURBON TRADING PLUGIN to specify one or more Administrators
                  who will have the rights to access Admin Account(s). The
                  Customer is responsible for:
                  <ol>
                    <li>maintaining the confidentiality and security of
                        the CURBON TRADING PLUGIN / ACCOUNT and
                        associated passwords; and
                    </li>
                    <li>any use of the CURBON TRADING PLUGIN / ACCOUNT.</li>
                  </ol>
              </li>
              <li>
                The Customer agrees that CURBON TRADING’s responsibilities
                do not extend to the internal management or administration of
                the Services for the Customer or any End Users.
              </li>
              <li>Administrator Access to the CURBON TRADING PLUGIN / ACCOUNT. <br>
                  An Administrator will have the ability to access, monitor, use,
                  modify, withhold, or disclose Customer Data associated with the
                  related Customer CURBON TRADING PLUGIN and control End
                  User’s access to the Services. An Administrator may also have the
                  ability to:
                  <ol>
                    <li>control account settings for the CURBON
                        TRADING PLUGIN / ACCOUNT (including changing
                        CURBON TRADING Account passwords) and
                    </li>
                    <li>remove or disable any Services or Additional
                        Products or other services/products enabled or
                        installed using the CURBON TRADING Account.
                    </li>
                    <li>Add or remove End User Accounts associated with the Customer’s account. <br>
                    </li>
                  </ol>
                    Use of Additional Products or other services/products with the
                    CURBON TRADING PLUGIN is at Customer’s own risk.
              </li>
              <li>Consents. <br>
                The Customer will obtain and maintain all required consents to permit:
                <ol>
                  <li>A Customer’s, and its End Users’ and/or End
                      User(s) if applicable, use of the Services; and 
                  </li>
                  <li>accessing, storing, and processing of Customer
                      Data and End User Data under this Agreement.
                  </li>
                </ol>
              </li>
              <li>Use Restrictions. <br>
                  A Customer shall not, and shall not allow End Users, End Users or third parties under its control to:
                  <ol>
                    <li>Have access to any part of the source code;</li>
                    <li>copy, modify, create a derivative work of, reverse engineer,
                        decompile, translate, disassemble, or otherwise attempt to extract
                        any of the source code of the Services (except to the extent such
                        restriction is expressly prohibited by applicable law);
                    </li>
                    <li>sublicense, transfer or distribute any of the Services;</li>
                    <li>sell, resell, or otherwise make the Services available to a third
                          party as part of a commercial offering that does not have material
                          value independent of the Services; or
                    </li>
                    <li>access or use the Services:
                        <ol>
                          <li>for High Risk Activities; </li>
                          <li>in a manner intended to avoid incurring Fees; </li>
                          <li> In an effort to circumnavigate or avoid any
                                financial services regulation and/or banking licensing
                                conditions, and/or credit regulations in force in the
                                Republic of South Africa during the term of this
                                agreement; or
                          </li>
                          <li>in a manner that breaches, or facilitates the
                              breach of Exchange Control Regulations.
                          </li>
                        </ol>
                    </li>
                    <li>Where any event in terms of clause 3.4 occurs the Customer shall
                        without fail, and without delay, notify CURBON TRADING thereof in
                        writing, and provide all reasonable assistance to CURBON TRADING
                        in enforcing any and all of its rights and remedies to rectify such
                        breach.
                    </li>
                  </ol>
              </li>
              <li> Abuse Monitoring. <br>
                  The Customer is solely responsible for monitoring, responding to and otherwise
                  processing emails sent to the “abuse” and “postmaster” aliases for any Domain
                  Name(s) verified for use with the Services, but CURBON TRADING may monitor 
                  11emails sent to these aliases for Domain Names to allow CURBON TRADING to
                  identify Services abuse or any item set out in terms of clause 3.4, above.
              </li>
              <li>Requesting Additional Services During Order Term. <br>
                  A Customer may purchase additional CURBON TRADING Services during an Order Term by:
                  <ol>
                    <li>executing an additional Services Selection Agreement with CURBON TRADING, or</li>
                    <li>via the CURBON TRADING PLUGIN. Such additional CURBON
                        TRADING Services will have a pro-rated term ending on the last day
                        of the applicable Order Term.
                    </li>
                  </ol>
              </li>
            </ol>
          </li>
        </ol>
        </li>
        <li class="mt-5"><b>Payment.</b>
        <ol>
          <li>Usage and Invoicing.
            <ol>
              <li>The Customer agrees to and will pay all Fees for the Services (plus applicable taxes).</li>
              <li>The Customer hereby acknowledges and consents to the
                  information contained in the Services Selection Agreement as true
                  and correct, including the billing information.
              </li>
              <li>CURBON TRADING will invoice the Customer all Fees for the Services.</li>
              <li>CURBON TRADING’s measurement tools will be used to determine a Customer’s usage of the Services.</li>
              <li>CURBON TRADING may change its offering of billing options,
                  including by limiting or ceasing to offer a billing option, or by
                  requesting that a Customer move to another billing option that
                  currently exists or is being introduced upon thirty days’ written
                  notice to a Customer (which may be by email).
              </li>
              <li>Certain billing options may not be available to all customers.</li>
            </ol>
          </li>
          <li> Billing plans.
            <ol>
              <li>Top-Up Billing – End User Pays for Offset
                  <ol>
                    <li>A Customer using this option will pay a fixed
                        amount that will be billed in advance of any of
                        CURBON TRADING’s Services being available. This 
                        12amount will increase the Customer’s contribution
                        balance. The contribution balance will decrease with
                        each End User’s election to make a
                        contributionUpon the balance reaching close to
                        zero, the initial Top-Up Amount will be billed again
                        to increase the balance.
                    </li>
                    <li>Top-Up Billing – Merchant Pays for Offset on behalf of End User
                      <ol>
                        <li>Where the Merchant
                            elects to offset the total amount
                            each End User makes on their store
                            the contribution balance will
                            decrease with each End User
                            purchase and be topped up when
                            the balance reaches close to zero.
                        </li>
                      </ol>
                    </li>
                  </ol>
              </li>
              <li> Arrears Billing
                  <ol>
                    <li> If a Customer is permitted to use arrears billing
                          while using CURBON TRADING’s service, CURBON
                          TRADING will count each contribution that happens
                          during a fixed monthly period, creating an
                          accumulating balance. Once the period has elapsed,
                          an Invoice will be created for the Customer’s
                          account and the Customer will be billed according
                    </li>
                  </ol>
              </li>
              <li> Billing Where the Customer opts to Pay All Contributions
                  <ol>
                    <li>Where a Customer decides to offset all
                        purchases made in the Customer’s store
                        themselves, the Customer’s balance will increase
                        each time a purchase is made in the store, not only
                        when a visitor
                    </li>
                  </ol>
              </li>
            </ol>
          </li>
          <li> Payment.
              <ol>
                <li>All payments are to be made in ZAR (South African Rands), unless
                    specifically requested, in which event CURBON TRADING reserves
                    the right to add a surcharge for a change in currency denomination.
                </li>
                <li>
                    Invoices. <br>
                    Payments for invoices are due 7 (seven)days after the invoice
                    date, unless otherwise specified on the Services Selection
                    Agreement, and are considered overdue after such date.
                </li>
              </ol>
          </li>
          <li>  Payment Methods
              <ol>
                <li>CURBON TRADING will record the bank card details of a Customer
                    which will be tokenised for security purposes. CURBON TRADING
                    will use this tokenised card to bill the Customer in Arrears or using
                    the Top-Up Billing method.
                </li>
                <li>In some circumstances the Customer will be permitted to make
                    manual payments to CURBON TRADING via Electronic Fund Transfer
                    (EFT).
                </li>
              </ol>
          </li>
          <li> Overdue Payments.
              <ol>
                <li>A Customer’s payment of Fees is overdue if CURBON TRADING
                    has not received it by the payment due date. If Customer’s
                    payment is overdue, CURBON TRADING may
                    <ol>
                      <li> charge interest at the prime rate of lending as
                            set out by the South African reserve Bank, and
                      </li>
                      <li>Suspend or terminate the Services.</li>
                    </ol>
                </li>
                <li>
                    The Customer shall be liable for any costs CURBON TRADING
                    incurs for the collection of any outstanding amount due to
                    CURBON TRADING (including attorneys' fees on an attorney and
                    own client scale and collection commissions of 10%).
                </li>
              </ol>
          </li>
          <li>  Escalation resulting from Third Party.
              <ol>
                <li>Where any Third Party service or product is reasonably utilised
                    by CURBON TRADING to provide the Services to the Customer,
                    and such Third Party increases its costs to CURBON TRADING,
                    CURBON TRADING reserves the right to increase its fees to
                    reasonably recoup from the Customer such an increase.
                </li>
              </ol>
          </li>
          <li>Taxes.
              <ol>
                <li>
                    All fees are charged exclusive of Taxes, such as Value Added Tax
                    (VAT). The Customer will pay all applicable Taxes for the Services.
                </li>
              </ol>
          </li>
          <li> Price Revisions. <br>
            All fees may increase annually and CURBON TRADING may modify the
            percentage it charges a Customer at any time unless otherwise expressly agreed
            in an Addendum or Services Selection Agreement. CURBON TRADING will notify
            The Customer within 10 (ten) days in advance of any Price increases.
          </li>
        </ol>
        </li>
        <li class="mt-5"><b>Technical Support Services (“TSS”).</b>
            <br>
            CURBON TRADING will provide TSS to the Customer during the Order Term in accordance
          with the TSS Guidelines subject to payment of support Fees, if applicable. 
        </li>
        <li class="mt-5"><b>Suspension of Services.</b>
          <ol>
            <li>AUP Violations. <br>
              If CURBON TRADING becomes aware that a Customer’s or any Administrator or
              End User’s use of the Services violates the AUP, CURBON TRADING will give the
              Customer notice of the violation by requesting that Customer corrects the
              violation. If the Customer fails to correct the violation within 24 hours of
              CURBON TRADING's request, then CURBON TRADING may Suspend all or part of
              the Customer's use of the Services until the violation is corrected. Suspension of
              the Services may include removal or unsharing of content that violates the AUP.
            </li>
            <li>  Other Suspension. <br>
              Notwithstanding clause 6.1 (AUP Violations) CURBON TRADING may
              immediately Suspend all or part of Customer’s use of the Services) if:
              <ol>
                <li>CURBON TRADING believes the Customer's or any Customer
                    Administrator's or End User’s use of the Services could adversely
                    impact the Services, other customers' or their end users' use of the
                    Services, or the CURBON TRADING network or servers used to
                    provide the Services;
                </li>
                <li>Customer’s use of the Services is prohibited in the Service Specific Terms;</li>
                <li>there is suspected unauthorised third-party access to the Services;</li>
                <li>CURBON TRADING believes it is required to Suspend immediately to comply with applicable law; or</li>
                <li>A Customer is in breach of clause 3.4 (Use Restrictions) or the
                    Service Specific Terms. CURBON TRADING will lift any such
                    Suspension when the circumstances giving rise to the Suspension
                    have been resolved. At the Customer’s request, unless prohibited by 
                    15law, CURBON TRADING will notify the Customer of the basis for the
                    Suspension as soon as is reasonably possible.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li class="mt-5"><b>Confidentiality.</b>
            <ol>
              <li>Obligations. <br>
                  Subject to clause 7.2, below, the recipient will use the other party’s Confidential
                  Information only to exercise rights and fulfill obligations under this Agreement.
                  The recipient will use reasonable care to protect against disclosure of the other
                  party’s Confidential Information to other parties other than the recipient’s
                  employees, Affiliates, agents, or professional advisors (“Delegates”) who need
                  to know it and who have a legal obligation to keep it confidential. The recipient
                  will ensure that its Delegates are also subject to the same non-disclosure and
                  use obligations.
              </li>
              <li>Disclosure of Confidential Information.
                <ol>
                  <li>General. <br>
                    Regardless of any other provision in the Agreement, the recipient
                    or its Affiliates may disclose the other party’s Confidential
                    Information
                    <ol>
                      <li>in accordance with a Legal Process, subject to clause 7.2.2 hereinbelow or</li>
                      <li>with the other party’s written consent.</li>
                    </ol>
                  </li>
                  <li>Legal Process Notification. <br>
                      The recipient will use commercially reasonable efforts to notify
                      the other party before disclosing that party’s Confidential
                      Information in accordance with Legal Process. Notice is not
                      required before disclosure if the recipient is informed that:
                      <ol>
                        <li> it is legally prohibited from giving notice or</li>
                        <li>the Legal Process relates to exceptional
                            circumstances involving danger of death or serious
                            physical injury.
                        </li>
                      </ol>
                  </li>
                  <li> Opposition. <br>
                    The recipient and its Affiliates will comply with the other party’s
                    reasonable requests to oppose disclosure of its Confidential
                    Information.
                  </li>
                </ol>
              </li>
            </ol>
          <li class="mt-5"><b>Intellectual Property.</b>
            <ol>
              <li>Intellectual Property Rights.
                <ol>
                  <li>Except as expressly stated in the Agreement, the Agreement does
                      not grant either party any rights, implied or otherwise, to the
                      other’s content or Intellectual Property. CURBON TRADING retains
                      all Intellectual Property Rights in the Services.
                  </li>
                  <li>
                    The Customer specifically agrees that it is a feature of the Services
                    for CURBON TRADING to process End User information and data
                    and consents to CURBON TRADING reasonably processing and using
                    same to contact the End User
                  </li>
                </ol>
              </li>
              <li>Brand Features
                <ol>
                  <li>CURBON TRADING shall not provide any support for any Customer
                      Brand Feature, logos, colours, and themes that make up the
                      Customer Brand Features on the CURBON TRADING PLUGIN for End
                      Users, unless agreed to in writing and signed between the parties.
                  </li>
                  <li>The Parties agree that CURBON TRADING may make use of the
                      Customer Brand Feature in any website, social media, or similar
                      promotional material illustrating the Customer makes use of
                      CURBON TRADING’s services.
                  </li>
                </ol>
              </li>
              <li>  Feedback. <br>
                At its option, a Customer may provide Feedback about the Services to CURBON
                TRADING. If a Customer provides Feedback, then the Customer assigns to
                CURBON TRADING all right, title, and interest in that Feedback.
              </li>
            </ol>
          </li>
          <li class="mt-5"><b>Marketing and Publicity.</b>
              <p>Each party may use the other party’s Brand Features in connection with this Agreement
                  only as permitted in the Agreement. The Customer may state publicly that it is a CURBON
                  TRADING customer and display CURBON TRADING Brand Features in accordance with the
                  Trademark Guidelines. CURBON TRADING may:
              </p>
              <ol>
                <li>state that the Customer is a CURBON TRADING customer and</li>
                <li>include the Customer’s name or Customer Brand Features in a list of CURBON
                    TRADING customers in CURBON TRADING’s promotional materials.
                </li>
              </ol>
              Any use of a party’s Brand Features will inure to the benefit of the party holding Intellectual
              Property Rights to those Brand Features. A party may revoke the other party’s right to use 
              17its Brand Features with written notice to the other party and a reasonable period to stop
              the use.
          </li>
          <li class="mt-5"><b>Representations, Warranties and Disclaimers.</b>
            <ol>
              <li>Representations and Warranties. <br>
                  Each party represents that it:
                  <ol>
                    <li>has full power and authority to enter into the Agreement; and</li>
                    <li>will comply with all laws and regulations applicable to its
                        provision, or use, of the Services, as applicable.</li>
                  </ol>
              </li>
              <li>Disclaimers by CURBON TRADING. <br>
                  Except as expressly provided for in the Agreement, to the fullest extent
                  permitted by applicable law, CURBON TRADING:
                  <ol>
                    <li>does not make any other warranties of any kind, whether express,
                        implied, statutory or otherwise, including warranties of
                        merchantability, fitness for a particular use, noninfringement, or
                        error-free or uninterrupted use of the Services; and
                    </li>
                    <li>makes no representation about content or information made
                        accessible through the Services.
                    </li>
                  </ol>
              </li>
              <li>Disclaimers by Customer. <br>
                Except as expressly provided for in the Agreement, to the fullest extent
                permitted by applicable law, Customer warrants that:
                <ol>
                  <li>Under no circumstances will the Customer sublicense any IP right,
                      service of CURBON TRADING or infer that it is an agent of CURBON
                      TRADING to any person or party;
                  </li>
                  <li> At all material times during the subsistence of this agreement, the
                        Customer shall be in possession of a valid requisite license as to
                        render services as a Financial Service Provider (if applicable), a bank
                        (if applicable), and/or Registered Credit Provider (if applicable); and
                  </li>
                  <li>All actions by its Administrators are authorised to be acts by the
                      Customer in relation to the Services, including support service
                      requests;
                  </li>
                  <li>It shall not without the express prior written consent of CURBON
                      TRADING solicit and attempt to solicit (indirectly or directly, through 
                      18an affiliate, connected party, legal advisor, or nominee directive)
                      any employee of CURBON TRADING, which consent shall not be
                      required if the employee has not been employed by CURBON
                      TRADING for a period of 1 (one) year; and
                  </li>
                  <li>
                      It shall make no attempts to gain, extrapolate, re-engineer and/or
                      otherwise recreate the source code of any part of the Service
                      without written consent of CURBON TRADING.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          
          <li class="mt-5"><b>Term and Termination.</b>
              <ol>
                <li>Agreement Term. <br>
                  This Agreement will remain in effect for the Term unless it expires or is
                  terminated in accordance with the Agreement.
                </li>
                <li>Renewal. <br>
                  Unless otherwise stipulated on the Services Selection Agreement, the agreement :
                  <ol>
                    <li>Shall continue to subsist on a monthly basis, if a monthly plan was chosen; or</li>
                    <li>Shall be renewed automatically on the anniversary date of at
                        the end of the Order Term, and the Services will renew consistent
                        with Customer’s elections on the Services Selection Agreement or
                        CURBON TRADING PLUGIN.
                    </li>
                  </ol>
                </li>
                <li> Termination
                    <ol>
                      <li>Any party may terminate this Agreement by providing 30 (thirty)
                          days’ written notice in the event of Significant Deprecation.
                      </li>
                      <li>Any party may terminate this Agreement by providing 60 (sixty)
                          days’ written notice of such termination.
                      </li>
                    </ol>
                </li>
                <li>Termination resulting from Breach. <br>
                    Either party may terminate the Agreement if the other party:
                    <ol>
                      <li>is in breach of the Agreement and fails to cure that breach
                          within 7 (seven) days after receipt of written notice; or
                      </li>
                      <li>ceases its business operations or becomes subject to insolvency
                          proceedings, notwithstanding that the insolvency proceedings 
                          19have merely been issued or may be a provisional order for
                          liquidation or insolvency.
                      </li>
                    </ol>
                </li>
                <li>Termination for Inactivity.<br>
                    CURBON TRADING reserves the right to terminate the Agreement and the
                    provision of the Services upon 30 days advance notice if, for a period of 60
                    consecutive days, Customer, including any End Users:
                    <ol>
                      <li>has not accessed the CURBON TRADING PLUGIN or</li>
                      <li>has not used the Services.</li>
                    </ol>
                </li>
                <li>Effects of Termination. <br>
                    If the Agreement terminates or expires, then all Services Selection Agreements
                    also terminate or expire, as applicable. If the Agreement terminates or expires,
                    then:
                    <ol>
                      <li>all rights and access to the Services under the Agreement will
                          terminate (including access to Customer Data); and
                      </li>
                      <li>CURBON TRADING shall be entitled to send Customer a final invoice.</li>
                    </ol>
                </li>
                <li>Survival. <br>
                    The following clauses will survive expiration or termination of this Agreement:
                    Clause 1(Definitions), 4 (Payment), 7 (Confidentiality), 8 (Intellectual Property),
                    10.3 (Disclaimer), 11.5 (Effects of Termination), 12 (Indemnification), 13
                    (Liability), and 15 (Miscellaneous).
                </li>
              </ol>
          </li>

          <li class="mt-5"><b>Indemnification.</b>
            <ol>
              <li>Customer Indemnification Obligations.
                  <ol>
                      <li>Unless prohibited by applicable law, the Customer will defend
                          CURBON TRADING and its Affiliates and indemnify them against
                          Indemnified Liabilities in any Third-Party Legal Proceeding to the
                          extent arising from:
                      </li>
                      <li>any Customer Indemnified Materials; or</li>
                      <li>The Customer’s or an Administrator’s or a End User’s use of the
                          Services in violation of the Acceptable Use Policy or the Use
                          Restrictions.
                      </li>
                  </ol>
              </li>
              <li>Remedies. <br>
                  <ol>
                    <li>If CURBON TRADING reasonably believes the Services might
                        infringe a third party’s Intellectual Property Rights, then CURBON
                        TRADING may, at its sole option and expense:
                        <ol>
                          <li>procure the right for Customer to continue using the Services;</li>
                          <li>modify the Services to make them noninfringing without materially reducing their functionality; or</li>
                          <li>replace the Services with a noninfringing, functionally equivalent alternative.
                              CURBON TRADING shall notify the Customer within 10 (ten) days
                              where an event in terms of clause 12.2.1 occurs.
                          </li>
                        </ol>
                        CURBON TRADING shall notify the Customer within 10 (ten) days
                        where an event in terms of clause 12.2.1 occurs.
                    </li>
                    <li>
                        If CURBON TRADING does not believe the remedies in clause
                        12.3 are commercially reasonable, then CURBON TRADING may
                        Suspend or terminate the impacted Services.
                    </li>
                  </ol>
               </li>
               <li> Sole Rights and Obligations. <br>
                  Without affecting either party’s termination rights, this clause 12
                  (Indemnification) states the parties’ sole and exclusive remedy under this
                  Agreement for any third party allegations of Intellectual Property Rights
                  Infringement covered by this Section 12 (Indemnification).
               </li>
            </ol>
          </li>
          <li class="mt-5"><b>Liability.</b>
            <ol>
              <li>Limited Liabilities.
                <ol>
                  <li>To the extent permitted by applicable law and subject to clause 13.2 (Unlimited Liabilities), neither party will have any Liability
                      arising out of or relating to the Agreement for any:
                    <ol>
                      <li>lost revenues, profits, savings or goodwill; or</li>
                      <li>indirect, special, incidental, consequential, or punitive damages.</li>
                    </ol>
                  </li>
                  <li>In the event of a Customer, an Administrator, or a End User’s use
                      of the services (or any part thereof) results in legal proceedings
                      brought against CURBON TRADING, CURBON TRADING retains the 
                      21right to join the Customer as a party to the proceedings and the
                      Customer shall undertake all reasonable steps to indemnify and
                      hold CURBON TRADING harmless from an adverse order against it.
                  </li>
                </ol>
              </li>

              <li> Unlimited Liabilities. <br>
                  Nothing in the Agreement excludes or limits either party’s Liability for:
                  <ol>
                    <li>death, personal injury or tangible personal property damage
                        resulting from its negligence or the negligence of its employees or
                        agents;
                    </li>
                    <li>its fraud or fraudulent misrepresentation;</li>
                    <li>obligations under clause 12 (Indemnification);</li>
                    <li>its infringement of the other party’s Intellectual Property Rights;</li>
                    <li>its payment obligations under the Agreement; or</li>
                    <li>matters for which liability cannot be excluded or limited under applicable law.</li>
                  </ol>
              </li>
            </ol>
          </li>
          <li class="mt-5"><b>Miscellaneous.</b>
              <ol>
                <li>Notices. <br> Each Party may provide the other with a notice in terms of this agreement by:
                  <ol>
                    <li>Sending a letter by registered post ; or</li>
                    <li>sending an email to Notification Email Address</li>
                  </ol>
                  The Customer elects the address provided on the Account and Platform, and
                  CURBON TRADING elects its address to be support@curbon.io .
                </li>
                <li>Notice will be treated as received when
                    <ol>
                      <li>7 (seven) days after a letter is dispatched via registered post; or</li>
                      <li>the email is sent, whether or not the other party has received the
                          email in terms of the Electronic Communications and Transactions
                          Act 25 of 2002.
                      </li>
                    </ol>
                </li>
                <li>Emails. <br>
                  Under this Agreement, the parties may use emails to satisfy written approval
                  and consent requirements in terms of the Electronic Communications and
                  Transactions Act 25 of 2002.
                </li>
                <li>Assignment. <br>
                    Neither party may assign the Agreement without the written consent of the
                    other party, except to an Affiliate where:
                    <ol>
                      <li>the assignee agrees in writing to be bound by the terms of the Agreement;</li>
                      <li>the assigning party remains liable for obligations incurred under the Agreement if the assignee defaults on them; and</li>
                      <li>the assigning party has notified the other party of the assignment. Any other attempt to assign is void.</li>
                    </ol>
                </li>
                <li>Change of Control. <br>
                    If a Customer experiences a change of Control other than an internal
                    restructuring or reorganization:
                    <ol>
                      <li>The customer will give written notice to CURBON TRADING within 30 days after the change of Control; and</li>
                      <li>the CURBON TRADING may immediately terminate the
                          Agreement any time within 30 (thirty) days after it receives that
                          written notice.
                      </li>
                    </ol>
                </li>
                <li> Force Majeure. <br>
                    Neither party will be liable for failure or delay in performance of its obligations
                    to the extent caused by circumstances beyond its control, including acts of God,
                    natural disaster, terrorism, riots, or war.
                </li>
                <li> Subcontracting. <br>
                    CURBON TRADING may subcontract obligations under the Agreement but will
                    remain liable to the Customer for any subcontracted obligations.
                </li>
                <li> No Waiver. <br>
                  Neither party will be treated as having waived any rights by not exercising (or
                  delaying the exercise of) any rights under the Agreement and no extension of
                  time or waiver or relaxation of any of the provisions or terms of this Agreement
                  shall operate as an estoppel against any party in respect of its rights under this 
                  23Agreement, nor shall it operate so as to preclude such party thereafter from
                  exercising its rights under this agreement
                </li>
                <li>Severability. <br>
                    If any clause (or any part) of the Agreement is invalid, illegal, or unenforceable,
                    the remainder of the Agreement will remain in effect.
                    14.10. No Agency or Partnership.
                    The Agreement does not create any agency, partnership, quasi-partnership, or
                    joint venture between the parties.
                </li>
                <li> No Third-Party Beneficiaries. <br>
                    This Agreement does not confer any benefits on any third party unless it
                    expressly states that it does.
                </li>
                <li> Governing Law. <br>
                    All claims arising out of or relating to this Agreement or the Services will be
                    governed by the Laws of the Republic of South Africa and the parties agree that
                    any proceeding initiated in terms of this agreement shall be done in the South
                    Gauteng High Court, of Johannesburg.
                </li>
                <li>Amendments. <br>
                    Except as specifically stated otherwise in the Agreement, any amendment to the
                    Agreement must be in writing, expressly state that it is amending the
                    Agreement and signed by both parties.
                </li>
                <li> Independent Development. 
                  <ol>
                    <li>Customer may not independently develop, alter, amend,
                        or in any manner change any part of the Services, including any
                        code, or acquiring any materials, services, products, programs, or
                        technology to further such aim unless CURBON TRADING provides
                        its express prior written consent thereto.
                    </li>
                    <li>
                        Customer specifically agrees that where the Customer or
                        any of its affiliates further develop, alter, amend or change any part
                        of the Services of the NISA Services, in that event such further
                        developments, alterations, or amendments shall belong to CURBON
                        TRADING, without any further reimbursement or right of recourse
                        to Customer. 
                    </li>
                    <li>Nothing in this agreement will be construed to prohibit
                        CURBON TRADING from independently developing, providing, or
                        acquiring any materials, services, products, programs, or technology
                        that are similar to the subject of the Agreement; provided that the
                        party does not breach its obligations under the Agreement in doing
                        so.
                    </li>
                  </ol>
                </li>
                <li> Entire Agreement. <br>
                    The Agreement together with the Services Selection Agreement contains all
                    terms agreed between the parties and supersedes any prior or
                    contemporaneous agreements between the parties relating to the subject
                    matter of this Agreement. In entering into the Agreement, neither party has
                    relied on, and neither party will have any right or remedy based on, any
                    statement, representation, or warranty (whether made negligently or
                    innocently), except those expressly stated in the Agreement. The Agreement
                    includes schedules, which are incorporated by reference into the Agreement.
                </li>
                <li>Conflicting Terms. <br>
                    If there is a conflict between the documents that make up this Agreement, the
                    documents will control in the following order: the Services Selection Agreement,
                    the Agreement, and the URL Terms.
                </li>
                <li>Counterparts. <br>
                    The parties may execute this Agreement in counterparts, including facsimile,
                    PDF or other electronic copies, which taken together will constitute one
                    instrument.
                </li>
                <li>Electronic Signatures. <br>
                    The parties consent to electronic signatures in terms of the Electronic
                    Communications and Transactions Act 25 of 2002.
                </li>
                <li>Data Usage and Storage <br>
                    The parties consent to the fair use of all Customer, End-User, End User Data and
                    terms of the POPI Schedule attached hereto.
                </li>
                <li>Headers. <br>
                    Headings and captions used in the Agreement are for reference purposes only
                    and will not have any effect on the interpretation of the Agreement.
                </li>
                <li> Costs <br>
                    Each party shall bear its own costs and expenses of and incidental to the
                    negotiation, preparation and completion of this Agreement.
                </li>
                <li>Rule of Construction. <br>
                    The rule of construction, that the agreement shall be interpreted against the
                    party responsible for its drafting or preparation, shall not apply.
                </li>
              </ol>
              <p> Agreed to without any change or amendment by clicking “I Accept” digitally on the sign-up portal
                and/or signature of the relevant Services Selection Agreement.</p>
          </li>
      </ol> 
      <b class="text-center" style="text-decoration:underline">26. SAAS AUP Schedule</b>
      <p class="mt-5">Use of the Services is subject to this acceptable use policy ("AUP"). <br>
        If not defined here, capitalised terms have the meaning stated in the applicable contract between <br>
        Customer, Authorised Administrators, and/or End Users (“You”) and CURBON TRADING. <br>
        You agree not to, and not to allow third parties or Your End Users, to use the Services:
      </p>
      <ul>
        <li>to generate or facilitate unsolicited bulk commercial email;</li>
        <li>to violate, or encourage the violation of, the legal rights of others;</li>
        <li>for any unlawful, invasive, infringing, defamatory, or fraudulent purpose;</li>
        <li>to intentionally distribute viruses, worms, Trojan horses, corrupted files, hoaxes, or other
            items of a destructive or deceptive nature;
        </li>
        <li>to interfere with the use of the Services, or the equipment used to provide the Services,
            by customer, authorised administrators, and/or End Users;
        </li>
        <li>to alter, disable, interfere with or circumvent any aspect of the Services;</li>
        <li>to test or reverse-engineer the Services in order to find limitations, vulnerabilities or
            evade filtering capabilities, save where such tests/ reverse-engineering are for the
            purposes of improving the Services and are done with the express consent of CURBON
            TRADING;
        </li>
        <li>to grant multiple individuals access to an individual End User Account other than via the
            delegation features provided within the Services;
        </li>
        <li>to create End User Accounts assigned to business functions rather than to human beings
            for the purpose of sharing files within or outside of the domain;
        </li>
        <li>to resell End User Accounts or parts thereof as added into a commercial product offered to third parties;</li>
        <li>to record audio or video communications without consent if such consent is required by
            applicable laws and regulations (You are solely responsible for ensuring compliance with
            all applicable laws and regulations in the relevant jurisdiction(s)).
        </li>
      </ul>
      <p class="mt-5">Your failure to comply with the AUP may result in immediate suspension or termination, or both, of the Services pursuant to the Agreement.</p>
      <b class="text-center" style="text-decoration:underline">Trademark Guideline Schedule</b>

      <p class="mt-5">The Parties record their consent for the use of various trademarks, logos, web pages, copyright material
         and such other distinctive features (Brand Features) as follows:-
      </p>
      <ol>
        <li>CURBON TRADING shall at times be entitled to display the agreed upon Brand Features on
            the CURBON TRADING PLUGIN and Services.
        </li>
        <li>The Customer shall at all times be enabled to display its Brand Features on the CURBON
            TRADING PLUGIN and Services that its End Users have access to.
        </li>
        <li>CURBON TRADING shall at times be entitled to display the agreed upon Brand Features on
            he CURBON TRADING Website and on their Social Media Accounts.
        </li>
        <li>CURBON TRADING shall at times request a Customer to display on their social media or the
            Customer’s website CURBON TRADING’s Brand Features. The Customer reserves the right
            to agree to this request.
        </li>
        <li>
          The Customer will be able to request that CURBON TRADING display their Brand Features
          on the CURBON TRADING Website and Services in certain prominent areas. CURBON
          TRADING reserves the right to charge a fee for this preferential advertising. Terms will be
          negotiated for this preferential advertising on an ah hoc basis.
        </li>
      </ol>
      <p class="mt-5">
        Both Parties agree that no provision of this agreement shall be construed to grant any manner of
        transfer of Intellectual Property, exclusive use, or royalty-based license or such a similar right in terms of
        the Brand Features of the other party to this agreement. <br><br>
        Any use of either Party’s Brand Features must be accompanied by a notice that clearly indicates that the
        CURBON TRADING Brand Features or the Customer Brand Features are trademarks or distinctive brand
        features of CURBON TRADING or the Customer (as the case may be). <br><br>
        Except as set forth above, nothing herein grants or should be deemed to grant either party any right,
        title or interest in or to the other party’s Brand Features. The use of
        Both parties agree not to challenge or assist others to challenge the other party’s Brand Features
        (except to the extent such restriction is prohibited by applicable law), and both parties agree not to
        register or attempt to register any domain names, trademarks, trade names, or other distinctive brand
        features that are confusingly similar to those of the other party to this agreements. <br><br>
        The CURBON TRADING Brand Features are provided “as is” and CURBON TRADING disclaims any
        warranties either expressed or implied by law regarding the CURBON TRADING Brand Features,
        including warranties of noninfringement. Furthermore, because you are not being charged for use of the
        CURBON TRADING Brand Features, in no event shall CURBON TRADING be liable to you for the subject
        matter of this Agreement under any theory of liability including for any direct, indirect, incidental,
        special, consequential, punitive, exemplary, or other damages arising out of this Agreement or the use
        of the CURBON TRADING Brand Features. This limitation shall apply even if CURBON TRADING was or
        should have been aware or advised of the possibility of such damages and notwithstanding any failure of
        essential purpose of any limited remedy stated herein. Some states do not allow exclusion of implied 
        28warranties or limitation of liability for incidental or consequential damages, so the above limitations or
        exclusions may not apply to you. <br><br>
        You may not assign your rights or delegate your obligations under this Agreement without CURBON
        TRADING’s prior written consent. This Agreement is not intended to benefit, nor shall it be deemed to
        give rise to, any rights in any third party. 
      </p>
    </div>
  </v-container>
</v-container>

<v-container fluid class="charcoal">
    <v-container>
        <ReadyToGetStarted></ReadyToGetStarted>
    </v-container>
</v-container>

</div>
</template>

<script>
import ReadyToGetStarted from '../components/ReadyToGetStarted.vue'
export default {
    name:'PrivacyPolicy',
    components:{
         ReadyToGetStarted,
    },

    data () {
      return {
        //
      }
    },

    methods: {
      // Create an array the length of our items
      // with all values as true
      all () {
        this.panel = [...Array(this.items).keys()].map((k, i) => i)
      },
      // Reset the panel
      none () {
        this.panel = []
      },
    },
}

</script>

<style type="text/css">
    ol {
      counter-reset: item
    }
    li {
      display: block
    }
    li:before {
      content: counters(item, ".") ". ";
      counter-increment: item
    }
</style>
