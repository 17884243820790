<template>
<v-container fluid class="lightGray">
    <v-row style="background:white; height:100vh">
        <v-flex class="login-hero-image register-hero-image" sm12 md7>
            <div class="ma-10 pt-10">
                <!-- <a href="/">
                    <v-img max-width="40" src="images\curbon\Curbon_logomark_black.svg">
                    </v-img>
                </a> -->
            </div>
            <v-img  src="images/assets/Illustrations/widescreen-hero.webp" style="width:60%;" class="ma-auto hidden-sm-and-down"></v-img>
        </v-flex>

        <v-flex sm12 md5>
            <v-row class="mx-10 my-md-10 mt-md-0 pt-5 pt-md-0">
                <v-col cols="12">
                    <h3 class="display-1">Let’s get started!</h3>
                    <p>Please enter your details.</p>
                </v-col>

                <v-col cols="12" class="text-left">
                    <v-form @submit.prevent="register()" ref="form" v-model="valid" id="reg-form">
                                              
                            <label>Online store platform</label>
                            <v-select
                                v-model="form.organisation_type"
                                :rules="rules.organisation_type"
                                :items="organisation_types"
                                item-value="name"
                                item-text="name"
                                outlined
                                dense
                                required
                            ></v-select>

                            <label>Online store name</label>
                            <v-text-field
                                name=""
                                v-model="form.organisation_name"
                                :rules="rules.organisation_name"
                                required
                                dense
                                outlined
                                class="mx-auto rounded-0"
                            ></v-text-field>
                            <label >Your first name</label>
                            <v-text-field
                            v-model="form.first_name"
                            :rules="rules.first_name"
                            required
                            outlined
                            dense
                            class="mx-auto rounded-0"
                            ></v-text-field>
                            
                            <label >Your last name</label>
                            <v-text-field
                            v-model="form.last_name"
                            :rules="rules.last_name"
                            required
                            outlined
                            dense
                            class="mx-auto rounded-0"
                            ></v-text-field>

                            <label >Phone number (optional)</label>
                            <v-text-field
                                v-model="form.phone"
                                :rules="rules.phone"
                                required
                                dense
                                outlined
                                class="mx-auto rounded-0"
                            ></v-text-field>

                            <label >Email</label>
                            <v-text-field
                                v-model="form.email"
                                :rules="rules.email"
                                required
                                dense
                                outlined
                                class="mx-auto rounded-0"
                            ></v-text-field>

                            <div v-if="password_required">
                            <label>Password</label>
                            <v-text-field
                                v-model="form.password"
                                class="mx-auto rounded-0"
                                :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="show_password  ? 'text' : 'password'"
                                :rules="rules.password"
                                required
                                outlined
                                dense
                                @click:append="show_password = !show_password"
                            ></v-text-field>
                            </div>

                    </v-form>
                </v-col>
                <v-col cols="12">
                    <v-btn width="100%"  @click="register" depressed class="black white--text rounded-0 register-form-button" id="register-form-button">
                        <span>{{ password_required ? 'Create account' : 'Get started' }}</span>
                    </v-btn>
                </v-col>
                <v-col cols="12" class="text-center px-0">
                    <p>Already have an account? <span><router-link class="pa-0 " text router to="/login">Log in</router-link></span></p>
                </v-col>
            </v-row>
        </v-flex>

  <div class="text-center">
    <v-dialog
      v-model="popup"
      width="1440px"
    >
      <div  class="register-pop-card-image pa-5" style="  background-size:cover;">
        <a href="/">
            <v-img max-width="40" src="images/assets/Curbon/Logo/Black/purple-black-logo.png"></v-img>   
        </a>
        
        <v-row class="pa-md-5 mx-md-5">
            <v-col cols="12" lg="6" style="position:relative">
                <div class="orangeCircle hidden-md-and-down"></div>
                <div class="orangeCircle2 hidden-md-and-down"></div>
                <v-card class="registerPopUpCards transparent">
                    <h4 style="font-size:24px">The planet appreciates you and your commitment to taking cliamte action with CURBON 🌍 🌳</h4>
                </v-card>
                <v-card class="registerPopUpCards transparent mb-md-15">
                    <p class="smallBody" style="line-height:40px">
                        Our developers are working on the final touches of the plugin for your selected platform. We will notify you the minute our plugin is live - see our go live dates 👉🏼
                    </p>
                      <p class="smallBody" style="line-height:40px">
                        One of our team members will be in touch with you shortly to share marketing content for your store ahead of going live, your customers will love it 🚀
                      </p>
                </v-card>

                <a class="hidden-md-and-down" href="/" style="color:black !important; text-decoration:none">
                 <p>Back to home page <v-icon>mdi-arrow-top-right</v-icon></p>
                </a>
            </v-col>
            <v-col cols="12" lg="6" class="pa-5 text-center">
                <!-- <img class="mb-10" style="max-width:100%" src="images/assets/Illustrations/coming-soon.png"> -->
                <img style="max-width:100%" src="images/assets/Images/timeline-4.png">
            </v-col>
            <a class="hidden-lg-and-up ml-5" href="/" style="color:black !important; text-decoration:none">
                 <p>Back to home page <v-icon>mdi-arrow-top-right</v-icon></p>
            </a>
        </v-row>
      </div>

    </v-dialog>
  </div>
    </v-row>
</v-container>
</template>

<script>
import axios from "axios";
import SuccessAlert from "../../mixins/successAlert";
import ErrorAlert from "../../mixins/errorAlert";

export default {
    name:'Register',
    mixins: [SuccessAlert, ErrorAlert],

    data() {
      return {
        popup: false,
        valid: true,
        organisation_types: [],
        show_password:false,
        show_password_confirm:false,
        password_required: false,
        form: {
            email: "",
            password: "",
            phone: "",
            first_name:"",
            last_name:"",
            organisation_name:"",
            organisation_type:"",
            token:""
        },

        rules: {
            organisation_name: [val => (val || '').length > 0 || 'This field is required'],
            organisation_type: [val => (val || '').length > 0 || 'This field is required'],
            first_name: [val => (val || '').length > 0 || 'This field is required'],
            last_name: [val => (val || '').length > 0 || 'This field is required'],
            email: [val => (val || '').length > 0 || 'This field is required'],
            password: [val => (val || '').length > 0 || 'This field is required'],
        },
      }
    },    

    watch: {
        form: {
            handler () {
              if(this.form.organisation_type == 'Custom built') {
                this.password_required = true;
              } else {
                this.password_required = false;
              }
            },
            deep: true,
        },
    },

    mounted() {
        this.getData();
    },

    methods: {
        getData() {
            axios.get(process.env.VUE_APP_API_URL + "/organisations/organisation-types")
            .then((response)=> {
                this.organisation_types = response.data.organisation_types;
            })
        },
        register() {
            
            if(!this.$refs.form.validate()) {
                //don't post
                return ;
            }

            window.grecaptcha.ready(() => {
                window.grecaptcha.execute('6LdyWO8lAAAAAE1GEeUM9OJa71gPhY9aLPwaDMos', { action: 'submit' }).then((token) => {
                    this.form.token = token;
                });
            });

            //redirect if not custom built
            console.log(this.form);
            axios
            .post(process.env.VUE_APP_API_URL + "/register", this.form)
            .then((response) => {

                //fake route change w query param
                this.$router.replace({ path: this.$route.path, query: { platform: this.form.organisation_type.toLowerCase().replace(" ", "_") } })

                var next_action = response.data.next_action;

                if(next_action == 'popup') {
                    //pop up if platform not active
                    this.popup = true;

                } else if (next_action == 'redirect') {
                    //redirect if active
                    if(response.data.redirect_link){

                        setTimeout(() => {
                            window.open(response.data.redirect_link,"_self").focus();
                        },500);

                    }
                } else {
                //login if going custom
                this.successAlert("Account created", "Welcome to Curbon"); //this is a mixin function 
                
                //login
                this.$store
                    .dispatch("login", this.form)
                    .then(() => {
                        this.$router.push('/email/verify');
                    })
                    .catch((err) => {
                        this.err = err;
                    });
                }
            })
            .catch((err) => {
                this.errorAlert("Something went wrong.",Object.values(err.response.data.errors).join(' '));
            });

        },
        clear() {
          this.password = ''
          this.email = ''
          // this.$refs.observer.reset()
        },
    },

}

</script>


