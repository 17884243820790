<template>
<div>
   <!-- elevate-on-scroll -->
  <v-app-bar
    id="navbar"
    flat
    app 
    :class="app_bar_class"
    :style="app_bar_style"
    >

    <v-tab flat router to="/">
      <img width="150" 
          src="/images/curbon/Curbon_logo_black.svg"
          alt="Curbon logo"
          >
      <img>
    </v-tab>

    <v-spacer></v-spacer>

    <v-toolbar-items class="hidden-sm-and-down py-2">
        <v-tabs color="#000000" background-color="rgba(255, 255, 255, 0)">
        <v-tab text v-for="link in links" :key="link.text"  router :to="link.route">
          {{ link.text }}
        </v-tab>

        <v-tab v-if="!!user.email" text  router to="/dashboard">
          Dashboard
        </v-tab>

        <v-tab v-if="!!user.email" text @click="logout()" style=" justify-content: left !important;">
          Log out
        </v-tab>

        <v-tab v-if="!user.email" text router to="/login">
          Log in
        </v-tab>
     
       <v-tab>
       <v-btn v-if="!user.email" text  router to="/register" class="black white--text rounded-0" dark>
           <span>Get started</span>
       </v-btn>
       </v-tab>
       </v-tabs>

    </v-toolbar-items>

      <v-app-bar-nav-icon class="hidden-md-and-up" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

    </v-app-bar>

    <v-navigation-drawer v-model="drawer" left temporary app class="home-hero-image" >

      <v-list >
          <v-list-item>
            <v-img max-width="100"  class="my-auto"
                src="images\curbon\Curbon_logo_black.svg">
            </v-img>
          </v-list-item>
      </v-list>

          <v-divider></v-divider>
       

          <v-tabs vertical color="#000000" background-color="rgba(255, 255, 255, 0)">
          <v-tab text v-for="link in links" :key="link.text"  router :to="link.route" style=" justify-content: left !important;" active-class="activeSideNavListItem">
            {{ link.text }}
          </v-tab>
          <v-divider></v-divider>
          <v-tab v-if="!!user.email" router to="/user-profile" style=" justify-content: left !important;">
           {{user.first_name}}
          </v-tab>

          <v-tab v-if="!!user.email" text @click="logout()" style=" justify-content: left !important;">
            Log out
          </v-tab>

          <v-tab v-if="!user.email" router to="/login" style=" justify-content: left !important;">
            Log in
          </v-tab>

          <v-tab v-if="!user.email"  router to="/register" style=" justify-content: left !important;">
              Get started
          </v-tab>
          </v-tabs>
          <v-divider></v-divider>
    </v-navigation-drawer>

    <div class="cookie" :style="cookie_bar_style">
      <v-row class="pa-5" justify="center">
          <v-col cols="12" md="8">
              <p class="smallBody" style="font-size:13px">We care about your privacy almost as much as we care about climate change. By accepting or continuing to use our site, you allow us to use the essential cookies to improve and enhance our webiste experience. View our <a href="/curbon-popi-and-privacy.pdf" style="color:#3D3D3D">Privacy Policy</a> for more information.</p>
          </v-col>
          <v-col cols="12" md="2">
              <v-btn class="cobalite white--text rounded-1" @click="acceptCookie()">Accept</v-btn>
          </v-col>
      </v-row>
    </div>

    </div>
</template>

<script>
// import axios from "axios";

export default {
    name:'Navbar',
    data () {
      return {
        right: null,
        drawer: false,
        app_bar_class: 'px-5 white',
        app_bar_style: 'background-color: #000000',
        cookie_bar_style: '',
        group: null,
        links:[
          {text:'How it works', route:'/'},
          {text:'Learn', route:'/learn'},
          {text:'About', route:'/about'},
          {text:'Projects', route:'/projects'},
          {text:'Blog', route:'/blogs'},
        ]
      }
    },
    computed: {
      user: {
        get() {
          return this.$store.getters.getUser;
        }
      }
    },
    watch: {
      group () {
        this.drawer = false
      },
      // whenever the route changes, update the navbar
      '$route.params.slug': {
        handler: function() {
          this.updateAppBar();
        },
        deep: true,
      },
    },
    created () {
      window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
      window.removeEventListener('scroll', this.handleScroll);
    },
    mounted() {
      this.updateAppBar();
      this.showCookie();
    },
    methods: {
      logout() {
        this.$store.dispatch("logout");
        this.$router.push('/');
      },
      updateAppBar() {
        if(this.$route.path == '/') {
          this.app_bar_style = "background: url('/images/assets/Images/BG 3.png'); background-size: cover; background-position:top";
          this.app_bar_class = "px-md-5";
        } else if (this.$route.path == '/blogs') {
          this.app_bar_style = "background-color: #d1faca;";
          this.app_bar_class = "px-md-5";
        } else if (this.$route.path == '/learn') {
          this.app_bar_style = "background-color: #faf8f5;";
          this.app_bar_class = "px-md-5";
        } else if (this.$route.path == '/pricing') {
          this.app_bar_style = "background: url('/images/assets/Images/pricing-page-background.png'); background-size: cover; background-position:top";
          this.app_bar_class = "px-md-5";
        }  else if (this.$route.path == '/about') {
          this.app_bar_style = "background-color: #FFFFFF;";
          this.app_bar_class = "px-md-5";
        } else if (this.$route.path == '/projects') {
          this.app_bar_style = "background-color: #dfddff;";
          this.app_bar_class = "px-md-5";
        } else {
          this.app_bar_style = "";
          this.app_bar_class = "px-md-5 white";
        }
      },
      handleScroll() {
        if(window.top.scrollY == 0) {
          this.updateAppBar();
          this.cookie_bar_style = "";
        } else {
          this.cookie_bar_style = "background: white";
          this.app_bar_class = "px-md-5 white";
          this.app_bar_style = "";
        }
      },

      showCookie(){
        const cookie= document.querySelector(".cookie")
        const cookies = document.cookie.indexOf("acceptedCookies=");

            if(cookies != -1){
                 cookie.style.display="none !important";
            } 
            else{
            setTimeout(() => {
                cookie.classList.add("active");
            }, 2000);
            }
        },

        acceptCookie(){
            const cookie= document.querySelector(".cookie")
            document.cookie="acceptedCookies=Yes; max-age="+1*365*24*60*60;
            cookie.classList.remove("active");
        },
    }
  }
</script>
