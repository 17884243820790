<template>
   <v-container>
    <v-card class="mb-6 projectsPurple mt-0 dashboardCard">
      <div class="rightBars rightGrayBars rightGrayBarsCard"></div>
      <v-row justify="space-between">
          <v-col lg="12" class="pa-5">
            <div style="float:left">
              <v-avatar fab class="white pa-1  mr-5" >
              <v-icon>mdi-store-settings</v-icon>
              </v-avatar> 
              <p v-if="organisation.id"  style="color:black; float: right" class="description mt-4">Editing details for {{organisation.name}}</p>
              <p v-else  style="color:black; float: right" class="description mt-4">Creat a New Store </p>
            </div>
          </v-col>
      </v-row>
    </v-card>

     <v-card  class="my-6 dashboardCard">
    <v-form ref="form" v-model="valid">
            <v-container>
              <v-row>
                  <v-col
                  cols="12"
                  md="4"
                  >
                  <v-text-field
                      v-model='organisation.name'
                      :rules="rules.name"
                      label="Name"
                      required
                  >
                  </v-text-field>
                  </v-col>
              </v-row>

              <v-row>
                  <v-col
                  cols="12"
                  md="6"
                  >
                  <v-textarea
                      v-model="organisation.details"
                      label="Description"
                      required
                  ></v-textarea>
                  </v-col>
              </v-row>
                <v-row>
                  <v-col
                  cols="12"
                  md="4"
                  >
                  <v-text-field
                      v-model='organisation.email'
                      label="Email"
                      required
                  >
                  </v-text-field>
                    </v-col>
                  <v-col
                      cols="12"
                      md="4"
                  >
                  <v-text-field
                      v-model='organisation.phone'
                      label="Phone"
                      required
                  >
                  </v-text-field>
                  </v-col>
              </v-row>

              <v-row>
                  <v-col
                  cols="12"
                  md="4"
                      >
                      <v-text-field
                          v-model="organisation.address_line1"
                          label="Address line 1"
                          required
                      ></v-text-field>
                  </v-col>

                  <v-col
                  cols="12"
                  md="4"
                  >
                      <v-text-field
                          v-model="organisation.address_line2"
                          label="Address line 2"
                          required
                      ></v-text-field>
                  </v-col>
              </v-row>
              <v-row>
                  <v-col
                  cols="12"
                  md="4"
                  >
                      <v-text-field
                          v-model="organisation.province"
                          label="Province"
                          required
                      ></v-text-field>
                  </v-col>
                  <v-col
                  cols="12"
                  md="4"
                  >
                      <v-text-field
                          v-model="organisation.postal_code"
                          label="Postal code"
                          required
                      ></v-text-field>
                  </v-col>

                  <v-col
                  cols="12"
                  md="4"
                  >
                      <v-text-field
                          v-model="organisation.country"
                          label="Country"
                          required
                      ></v-text-field>
                  </v-col>
              </v-row>
              <v-row class="mb-10">
                <v-col lg="6">
                  <v-text-field
                          v-model="organisation.tax_number"
                          label="Tax number"
                          required
                      ></v-text-field>
                </v-col>
                 <v-col lg="6">
                  <p>Pays tax</p>
                    <v-radio-group v-model="organisation.pays_tax" row>
                    <v-radio
                      label="Yes"
                      :value="1"
                    ></v-radio>
                    <v-radio
                      label="No"
                      :value="0"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              
              <v-btn
                  class="ma-2"
                  color="secondary"
                  @click="$router.go(-1)"
              >
                  Back
              </v-btn>

              <v-btn
                  v-if="organisation.id"
                  class="ma-2"
                  color="success"
                  @click="updateOrganisation()"
              >
                  Update
              </v-btn>

              <v-btn
                  v-else
                  class="ma-2"
                  color="success"
                  @click="saveOrganisation"
              >
                  Save
              </v-btn>
            </v-container>
        </v-form>
     </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import SuccessAlert from '../../../mixins/successAlert'
import ErrorAlert from '../../../mixins/errorAlert'

export default {
    mixins:[SuccessAlert,ErrorAlert],
    name:'CreateOrganisation',

    data() {
      return {
          valid: true,
          organisation:{
            name:"",
            address_line1:"",
            address_line2:"",
            province:"",
            country:"",
            details:"",
            postal_code:"",
            email:"",
            phone:"",
            tax_number:"",
            pays_tax:"",  
          },
          rules: {
            name: [val => (val || '').length > 0 || 'This field is required'],
            // default_percent: [val => (val > 0 || val < 1) || 'This field is required'],
          },
      }
    },

    mounted: function() {
    this.getData();
    },

    computed: {
      creator: {
        get() {
          return this.$store.getters.getUser;
        },
      },
    },

    methods: {
    saveOrganisation()
    {
        if(!this.$refs.form.validate()) {
            //don't post
            return ;
        }
       
        //note the store type is custom
        this.organisation.type = 'custom';

        axios
        .post(process.env.VUE_APP_API_URL + "/organisations/", this.organisation)
        .then((response) => {
            this.successAlert("Saved", response.data.success); //this is a mixin function successAlert($title,$message)
            setTimeout(() => {this.$router.go(-1);}, 1000);
        })
        
        .catch((error) => {
            this.errorAlert("Failed to save Organisation.",Object.values(error.response.data.errors).join(' '));  //this is a mixin function errorAlert($message,$error)
        });
    },

     updateOrganisation (){
          if(!this.$refs.form.validate()) {
              //don't post
              return ;
          }

          axios
            .put(process.env.VUE_APP_API_URL + "/organisations/"+ this.$route.params.slug,this.organisation)
            .then((response) => {
              this.successAlert("Saved", response.data.success); 
              setTimeout(() => {this.$router.go(-1);}, 1000);
            })
            
            .catch((error) => {
              this.errorAlert("Failed to update.",Object.values(error.response.data.errors).join(' ')); 
            });
    },

    getData() {
      if(this.$route.params.slug) {
        axios
          .get(process.env.VUE_APP_API_URL + '/organisations/' + this.$route.params.slug)
          .then((response) => {
            this.organisation = response.data.organisation;
          })
          .catch((error) => { 
            console.log(error);
        });
      } 
    },
}
}

</script>
