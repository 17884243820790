<template>
<div>
  <v-container class="lightGreen" fluid>
    <v-container>
      <div class="mt-15">
        <p class="description">Blog</p>
        <h1>News + articles</h1>
        <p class="bodyBig">Subscribe to learn about new features, the latest in climate news, and updates.</p>
      </div>
      <v-toolbar flat>
          <v-tabs v-model="tab" class="lightGreen">
            <v-tabs-slider color="black"></v-tabs-slider>
            <v-tab class="lightGreen">
              All
            </v-tab>
            <v-tab class="lightGreen">
              Latest
            </v-tab>
            <v-tab class="lightGreen" v-for="category in categories" :key="category.id" >
              {{ category.name }}
            </v-tab>
          </v-tabs>
      </v-toolbar>
    </v-container>
  </v-container>

  <v-container fluid class="lightGray">
     <v-container>
      <v-card flat>
        <v-tabs-items v-model="tab">
          <!-- all articles -->
          <v-tab-item class="lightGray">
            <v-row class="pa-4 mb-8" v-for="first_item in articles.slice(4, 5)" :key="first_item.id" justify="space-around">
              <v-col cols="12" md="6" class="mb-10">
                <v-img v-if="first_item.thumbnail_uuid" :src="first_item.thumbnail_url" height="400px" max-width="800px"/>
                <v-img v-if="!first_item.thumbnail_uuid && first_item.id < 7" :src="temporary_images.filter((e)=>{return e.id == first_item.id})[0].url" class="mx-auto mt-md-2 mr-lg-8"/>
              </v-col>
              <v-col cols="12" md="6">
                <router-link style="text-decoration: none; color: inherit;" :to="`/blogs/${first_item.slug}`">
                  <h3 class="mb-8">{{first_item.title}}</h3>
                  <p class="mb-8 mediumBody">{{first_item.excerpt}}</p>
                   <BlogCategoryCard :category_name="first_item.category.name" :link="`/blogs/${first_item.slug}`"/>
                </router-link>
              </v-col>
            </v-row>

            <v-divider class="black mb-8"></v-divider>

            <h4 class="my-10 ml-4"><strong>Latest articles</strong></h4>

            <v-row class="my-10">
              <v-flex sm12 md6 lg4 v-for="(article, latest_articles_index) in articles" :key="latest_articles_index">
                  <v-card
                    v-if="latest_articles_index < 3"
                    class="mx-auto lightGray"
                    max-width="344"
                    tile
                    flat
                    router :to="`/blogs/${article.slug}`"
                  >
                      <v-img v-if="article.thumbnail_uuid" :src="article.thumbnail_url"  height="220px" style="max-width:344px; background-size:fill;"/>
                      <v-img v-if="!article.thumbnail_uuid && article.id < 6" :src="temporary_images.filter((e)=>{return e.id == article.id})[0].url" height="220px" style="max-width:344px; background-size:fill;"/>


                      <p router :to="`/blogs/${article.slug}`" class="my-3 mediumBody"><strong>{{article.title}}</strong> <v-icon>mdi-arrow-top-right</v-icon></p>
                      <p class="smallBody">{{article.excerpt}}</p>
                      <BlogCategoryCard :category_name="article.category.name" :link="`/blogs/${article.slug}`"/>
                    </v-card>
              </v-flex>
            </v-row>

            <v-divider class="black mb-8"></v-divider>

            <v-row class="pt-4 my-10">
              <v-flex sm12 md6 v-for="item in articles.slice(3, 4)" :key="item.id" class="mb-8">
                <v-card tile class="my-0 mr-10 lightGray" flat router to="/blogs/climate-change-and-why-it-matters">
                
                   <v-img class="mb-8" v-if="item.thumbnail_uuid" :src="item.thumbnail_url" height="200px" max-width="374px"/>
                   <v-img class="mb-8" v-if="!item.thumbnail_uuid && item.id < 6" :src="temporary_images.filter((e)=>{return e.id == item.id})[0].url"/>
              
                  <h4 class="mb-8 px-5" router :to="`/blogs/${item.slug}`" ><strong>{{item.title}}<v-icon>mdi-arrow-top-right</v-icon></strong></h4>
                  <p class="mb-8 px-5 smallBody">{{item.excerpt}}</p>
                  <BlogCategoryCard class="mx-5" :category_name="item.category.name" :link="`/blogs/${item.slug}`"/>
                </v-card>
              </v-flex>

              <v-flex sm12 md6>
                <v-card v-for="item in articles.slice(4, 6)" :key="item.id" tile class="my-0 mx-10 lightGray" flat router :to="'/blogs/'+item.slug">
                  <v-row>
                    <v-col cols="6"  class="mb-5">
                      <v-img max-width="250" class="mb-8" v-if="item.thumbnail_uuid" :src="item.thumbnail_url"/>
                      <v-img v-if="!item.thumbnail_uuid && item.id < 6" :src="temporary_images.filter((e)=>{return e.id == item.id})[0].url" max-width="250" class="mb-8"/>
                    </v-col>
                    <v-col cols="12" lg="6" xl="6" md="6">
                      <p class="mediumBody" router :to="`/blogs/${item.slug}`">
                        <strong>{{item.title}} <v-icon>mdi-arrow-top-right</v-icon></strong>
                      </p>
                      <!-- <p class="smallBody">{{item.excerpt.substr(0,50)}} ...</p> -->

                      <v-card-actions>
                       <BlogCategoryCard :category_name="item.category.name" :link="`/blogs/${item.slug}`"/>
                      <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-col>
                  </v-row>
                </v-card>
              </v-flex>
            </v-row>

             <div v-for="category in categories" :key="category.id">
              <v-divider class="black mb-8"></v-divider>
              <v-row>
                <v-col cols="12" class="ml-2">
                  <h4><strong>{{category.name}}</strong></h4>
                </v-col>
              </v-row>
              <v-row class="mb-10">
                  <v-col v-for="(article, articles_in_category_index) in articles.filter((e)=>{return e.category_id == category.id})" :key="articles_in_category_index" cols="12" md="4">
                      <v-card
                        v-if="articles_in_category_index < 3"
                        class="mx-auto lightGray"
                        max-width="344"
                        tile
                        flat
                        router :to="`/blogs/${article.slug}`"
                      >
                          <v-img v-if="article.thumbnail_uuid" :src="article.thumbnail_url"  height="200px" max-width="374px"/>
                        <v-img v-if="!article.thumbnail_uuid && article.id < 6" :src="temporary_images.filter((e)=>{return e.id == article.id})[0].url" class="mx-auto mt-md-2 mr-lg-8"  height="200px" max-width="374px"/>
                          <p class="my-3"><strong>{{article.title}}</strong></p>
                          <p class="bodySmall">{{article.excerpt}}</p>
                      </v-card>
                  </v-col>
              </v-row>
            </div>

          </v-tab-item>

          <!-- latest articles tab -->
          <v-tab-item class="lightGray">
              <v-row class="mb-10">
                <v-flex sm12 md6 lg4  v-for="article in articles" :key="article.id">
                    <v-card
                      class="mx-auto lightGray"
                      max-width="344"
                      tile
                      flat
                      router :to="`/blogs/${article.slug}`"
                    >
                        <v-img v-if="article.thumbnail_uuid" :src="article.thumbnail_url" height="200px" max-width="374px"/>
                        <v-img v-if="!article.thumbnail_uuid && article.id < 6" :src="temporary_images.filter((e)=>{return e.id == article.id})[0].url" class="mx-auto mt-md-2 mr-lg-8"  height="200px" max-width="374px"/>

                        <p class="my-3 mediumBody"><strong>{{article.title}}</strong> <v-icon>mdi-arrow-top-right</v-icon></p>
                        <p class="smallBody">{{article.excerpt}}</p>
                        <BlogCategoryCard :category_name="article.category.name" :link="``"/>
                    </v-card>
                </v-flex>
              </v-row>
          </v-tab-item>
          
          <!-- tabs for categories -->
          <v-tab-item v-for="category in categories" :key="category.id" class="lightGray">
              <v-row class="mb-10">
                <v-flex sm12 md6 lg4  v-for="(article, articles_in_category_index) in articles.filter((e)=>{return e.category_id == category.id})" :key="articles_in_category_index">
                    <v-card
                      class="mx-auto lightGray"
                      max-width="344"
                      tile
                      flat
                      router :to="`/blogs/${article.slug}`"
                    >
                 
                        <v-img v-if="article.thumbnail_uuid" :src="article.thumbnail_url" height="200px" max-width="374px"/>
                        <v-img v-if="!article.thumbnail_uuid && article.id < 6" :src="temporary_images.filter((e)=>{return e.id == article.id})[0].url" class="mx-auto mt-md-2 mr-lg-8"  height="200px" max-width="374px"/>

                        <p class="my-3 mediumBody"><strong>{{article.title}}</strong> <v-icon>mdi-arrow-top-right</v-icon></p>
                        <p class="smallBody">{{article.excerpt}}</p>
                        <BlogCategoryCard :category_name="article.category.name" :link="``"/>
                    </v-card>
                </v-flex>
              </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
     </v-container>
  </v-container>

  <v-container fluid class='charcoal'>
      <ReadyToGetStarted></ReadyToGetStarted>
  </v-container>
</div>
</template>

<script>
import axios from "axios";
import ReadyToGetStarted from '@/components/ReadyToGetStarted.vue';
import BlogCategoryCard from '@/components/BlogCategoryCard.vue';

export default {
    name:'Blogs',
    components:{
        ReadyToGetStarted,
        BlogCategoryCard
    },
    data: () => ({
      articles:[],
      articles_in_categories: [],
      articles_key: 0,
      categories:[],
      articles_in_category:[],
      window: 0,
      tab: null,
      temporary_images:[
        {
          id: 1,
          url: '/images/assets/Images/unsplash_i9w4Uy1pU-s (2).png'
        },
        {
          id: 2,
          url: '/images/assets/Images/unsplash_i9w4Uy1pU-s.png'
        },
        {
          id: 3,
          url: '/images/assets/Images/purple-illustration.png'
        },
        {
          id: 4,
          url: '/images/assets/Images/unsplash_i9w4Uy1pU-s (1).png',
        },
        {
          id: 5,
          url: '/images/assets/Images/concept-of-offsetting.png'
        },
      ]
    }),

    mounted: function() {
      this.getArticles();
    },

      methods: {
        getThumbnail(uuid, post_index) {
          axios
            .get(process.env.VUE_APP_API_URL + "/uploads/download/public/" + uuid ,
            {
              responseType: 'blob',
            })
            .then((response) => {
              var thumbnail_url = window.URL.createObjectURL(new Blob([response.data]));
              this.articles[post_index].thumbnail_url = thumbnail_url;
              this.articles_in_category[post_index].thumbnail_url = thumbnail_url;
            })
        },

         getArticleThumbnail(uuid) {
          axios
            .get(process.env.VUE_APP_API_URL + "/uploads/download/public/" + uuid ,
            {
              responseType: 'blob',
            })
            .then((response) => {
             console.log(window.URL.createObjectURL(new Blob([response.data])));
            })
        },

        getArticles() {
          axios
          .get(process.env.VUE_APP_API_URL + '/articles/categories/all-active')
          .then((response) => {
            this.categories = response.data.categories;

            //change route to the tab with that cateogry
            if(this.$route.hash != '') {
              if(this.$route.hash == '#latest') {
                this.tab = 1;
              } else {
                var category = this.categories.filter((e) => {
                  return e.name.toLowerCase() == this.$route.hash.substring(1);
                })[0]
                this.tab = 2 + this.categories.indexOf(category);
              }
            }
          })

          axios
          .get(process.env.VUE_APP_API_URL + '/articles')
          .then((response) => {
            this.articles = response.data.articles.sort((a, b) => {
                                                        return b.id - a.id;
                                                    });
            this.articles_in_categories = response.data.articles_in_categories;

            for (let i = 0; i < this.articles.length; i++) {
              if(this.articles[i].thumbnail_uuid != null){
                this.getThumbnail(this.articles[i].thumbnail_uuid, i);
              }
            }
          })
        },
      },
}

</script>
