<template>
  <v-container fluid class="lightGray" style="top: 0"> 
      <Navbar />   
      <v-row style="background:white" class="fullscreen">
          <v-col cols="12" md="7" class="login-hero-image">
              <div class="ma-10">
                  <!-- <a href="/">
                      <v-img max-width="40"
                          src="images\curbon\Curbon_logomark_white.svg">
                      </v-img>
                  </a> -->
                  <div class="text-left pa-10 transparent-box hidden-sm-and-down">
                      <h3> You’re doing your part in working toward a 
                          net-zero future.
                      </h3>
                  </div>
              </div>
          </v-col>
          
          <v-col cols="12" md="5" class="mt-md-10">
            <router-view />
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
import Navbar from "@/layout/Navbar.vue";

export default {
    name:'AuthLayout',
    components: {
      Navbar,
    }
}
</script>
