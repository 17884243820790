var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"mb-6 projectsPurple mt-0 dashboardCard"},[_c('div',{staticClass:"rightBars rightGrayBars rightGrayBarsCard"}),_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"pa-5",attrs:{"lg":"4"}},[_c('div',{staticStyle:{"float":"left"}},[_c('v-avatar',{staticClass:"white pa-1 mr-5",attrs:{"fab":""}},[_c('v-icon',[_vm._v("mdi-post")])],1),_c('p',{staticClass:"description mt-4",staticStyle:{"color":"black","float":"right"}},[_vm._v("Articles")])],1)]),_c('v-col',{staticClass:"pa-5 my-auto",attrs:{"justify":"end","lg":"4"}},[_c('v-btn',{staticStyle:{"float":"right"},attrs:{"to":"/dashboard/articles/create","color":"secondary"}},[_vm._v(" Write an article ")])],1)],1)],1),_c('v-card',{staticClass:"my-6 dashboardCard"},[_c('v-card-title',[_vm._v(" Articles "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.articles,"search":_vm.search},scopedSlots:_vm._u([{key:"item.category_id",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.categories),function(category){return _c('p',{key:category.id},[(item.category_id==category.id)?_c('span',[_vm._v(_vm._s(category.name))]):_vm._e()])})}},{key:"item.is_published",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"color":"primary","value":"","input-value":!!item.published_at},on:{"click":function($event){return _vm.togglePublish(item)}}})]}},{key:"item.published_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(!!item.published_at ? item.published_at.substr(0,10) : '')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":("/blogs/" + (item.slug))}},[_c('v-icon',{staticClass:"mx-2",attrs:{"small":""},on:{"click":item}},[_vm._v(" mdi-eye ")])],1),_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":("/dashboard/articles/" + (item.slug) + "/edit")}},[_c('v-icon',{staticClass:"mx-2",attrs:{"small":""},on:{"click":item}},[_vm._v(" mdi-pencil ")])],1),_c('v-dialog',{attrs:{"content-class":"elevation-0","max-width":"700"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true),model:{value:(item.delete_dialog),callback:function ($$v) {_vm.$set(item, "delete_dialog", $$v)},expression:"item.delete_dialog"}},[_c('v-card',[_c('v-alert',{attrs:{"type":"error"}}),_c('v-card-title',{staticClass:"text-h6"},[_vm._v(" Delete "+_vm._s(item.title)+" ")]),_c('v-card-text',[_vm._v(" Are you sure you want to Proceed with deleting?. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){item.delete_dialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.deleteArticle(item); item.delete_dialog = false}}},[_vm._v(" Delete ")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }