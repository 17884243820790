<template>
<div>

<v-container fluid class="lightGray">
<v-container class="my-10 pa-10" wrap>
    <v-row>
        <v-col cols="12" md="6">
            <p class="subTitle">Climate change </p>
            <h2 class="mb-10">Carbon offsetting explained</h2>
        
            <p class="mediumBody">
                There’s so much to learn about the climate crisis and our role in changing the way we treat the environment. 
            </p>
            <p class="mediumBody">
                We’re making it simple. 
            </p>
            <p class="mediumBody">
                CURBON is committed to cultivating kinder consumerism, offering you the opportunity to make a big difference with just a small contribution. Here’s your lightning-quick guide to the definitions and concepts you need to know. 
            </p>
        </v-col>

        <v-col cols="12" md="6" text-right class="hidden-sm-and-down">
            <v-img src="/images/assets/Illustrations/Blocks.png" style="width:65%; float:right" class="mx-auto mx-sm-auto"></v-img>
        </v-col>
    </v-row>
</v-container>
</v-container>

<v-container fluid class="lightGray" id="definitions">
    <v-container class="mb-10" v-click-outside="closeCards" >
        <v-row class="mx-auto">
            <v-flex  xs12 sm6 md6 lg12 lg3 v-for="(topic,i) in about_topics" :key="i">
                <v-card flat tile class="lightGray ma-4" max-width="300">
                    <v-avatar tile class="ml-4 mt-4">
                     <v-img  max-width="50" :src='topic.image'></v-img>
                    </v-avatar>
                   
                    <v-card-title  class="text-left mediumBody my-5">
                        {{topic.title}}
                    </v-card-title>

                    <v-card-subtitle class="text-left smallBody pb-0">
                        {{topic.subtitle}}
                    </v-card-subtitle>

                    <!-- pop ups for mobile view -->
                    <v-card-actions @click="openCard(i)" class="d-flex d-lg-none">
                        <v-btn class="text-decoration-underline smallBody" text  >Read More</v-btn>
                    </v-card-actions>
                    <v-expand-transition>
                        <!-- v-click-outside="topic.read_more = !topic.read_more" -->
                        <v-card v-if="topic.read_more" class="transition-fast-in-fast-out v-card--reveal">
                            <v-card-text class="smallBody">
                                <div v-html="topic.text"></div>
                            </v-card-text>
                            <v-card-actions class="pa-0">
                                <v-btn text color="#A09FFA" class="smallBody ml-1" @click="topic.read_more = !topic.read_more">Close</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-expand-transition>
                </v-card>
            </v-flex>
        </v-row>

        <!-- pop ups for desktop view -->
        <v-row class="mx-auto d-none d-lg-flex">
            <v-col cols="12" md="6" lg="3" class="pa-0" v-for="(topic,i) in about_topics" :key="topic.title">
                <v-card flat tile class="lightGray ma-4" max-width="300">
                    <v-card-actions class="pt-0">
                        <v-btn class="text-decoration-underline smallBody" text  @click=openCard(i)>Read More</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</v-container>

<v-container fluid class="lightGray">
    <v-container class="my-10 pa-10 lightGreen" wrap>
        <h4 class="mb-10"><strong>CURBON’s offsetting methodology </strong></h4>
        <v-row justify-center  class="text-left" >
            <v-flex sm12 md5>
                <p class="mb-8 pl-3 mediumBody">
                    Offsetting using carbon credits is the simplest way to reduce your carbon footprint. CURBON offers you the opportunity to neutralise the carbon cost of your ecommerce needs.
                    <br><br> Harnessing the data and insight developed by leading research institutions, we are able to calculate the emissions generated by the products and services you use day-to-day.
                </p>
            </v-flex>
            <v-flex>
            </v-flex>
            <v-flex sm12 md5>
                <p class="mediumBody pl-3">
                    We believe in transparency and accountability. The carbon credits we utilise are audited and certified by The Voluntary Gold Standard (VGS) and The Verified Carbon Standard (VCS). 
                    Whether you’re a growing business, a major enterprise or someone trying to consume more consciously, you can trust that every online transaction is contributing toward real, positive change. 
                </p>
            </v-flex>
        </v-row>
    </v-container>
</v-container>

<v-container fluid class="lightGray">
    <v-container class="mt-5 mb-10 pa-5">
        <v-row>
            <v-col cols="12" md="6">
                <h2 class="mb-6 my-md-0 mx-lg-2">Our projects do more than just protect the planet.</h2>
            </v-col>
            <v-col cols="12" md="6">
                <p class="mediumBody">Each of these initiatives were selected by our sustainability specialists, chosen for their potential in offsetting carbon equivalent emissions and empowering communities. </p>
                    <br>
                <v-btn class="px-0 smallBody" text router to="/projects">View all <v-icon>mdi-arrow-top-right</v-icon></v-btn>
            </v-col>
        </v-row>
    </v-container>
    <v-slide-group class="px-n10 " show-arrows>
        <v-slide-item v-for="project in projects" :key="project.title">
            <v-card tile class="ma-4 lightGray" max-width="400" flat router :to="`/projects/` + project.slug">
                <v-img fit height="200" :src='project.thumbnail'></v-img>       
                
                <v-card-title class="smallBody px-0" style="white-space: nowrap;">
                    <strong>
                        {{project.title_plain_text}}
                        <v-icon>mdi-arrow-top-right</v-icon>
                    </strong>
                </v-card-title>

                <v-card-subtitle class="description px-0">
                {{project.country}}
                </v-card-subtitle>
            </v-card>
        </v-slide-item>
    </v-slide-group>
</v-container>

<v-container fluid>
    <v-container class="my-10">
        <div class="text-center mb-10">
            <p class="description">NEWS</p>
            <h1 class="hidden-sm-and-down">Inspiring change and <br> announcements</h1>
            <h3 class="hidden-md-and-up">Inspiring change and announcements</h3>
        </div>
        
        <v-divider></v-divider>
        <v-row class="pa-10" v-if="latest_science_article">
            <v-col cols="12" md="5">
                <h2 class="mb-6">Science</h2>
                <v-btn class="pa-0 smallBody" text router to="/blogs">View all</v-btn>
            </v-col>

            <v-col cols="12" md="7">      
                <v-card flat router :to="'/blogs/' + latest_science_article.slug">
                    <v-row class="mt-n10">
                        <v-col cols="12" md="6">
                            <p class="smallBody">{{latest_science_article.published_at.slice(0,10)}} </p>
                            <p class="mediumBody"><strong>{{latest_science_article.title}} </strong>
                            </p>
                            <p class="smallBody">
                               Carbon conscious commerce is the new normal. People are increasingly aware of...
                            </p>
                        </v-col>

                        <v-col cols="12" md="6" class="ma-auto">
                           <v-img fit max-height="400" max-width="400" src="images/assets/Images/purple-illustration.png"></v-img>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-divider></v-divider>

         <v-row class="pa-10" v-if="latest_markets_article">

            <v-col cols="12" md="5">
                <h2 class="mb-6">Markets</h2>
                <v-btn class="pa-0 smallBody" text router to="/blogs">View all</v-btn>
            </v-col>

            <v-col cols="12" md="7">      
                <v-card flat router :to="'/blogs/' + latest_markets_article.slug">
                    <v-row class="mt-n10">
                        <v-col cols="12" md="6">
                            <p class="smallBody">{{latest_markets_article.published_at.slice(0,10)}} </p>
                            <p class="mediumBody"><strong>{{latest_markets_article.title}} </strong>
                            </p>
                            <p class="smallBody">
                               Whether you’re a business looking to compensate for your operation’s carbon footprint, or... 
                            </p>
                        </v-col>

                        <v-col cols="12" md="6" class="ma-auto">
                           <v-img fit max-height="400" max-width="400" src="images/assets/Images/markets-image.png"></v-img>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row class="pa-10" v-if="latest_policy_article">
            <v-col cols="12" md="5">
                <h2 class="mb-6">Policy</h2>
                <v-btn class="pa-0 smallBody" text router to="/blogs">View all</v-btn>
            </v-col>


            <v-col cols="12" md="7">      
                <v-card flat router :to="'/blogs/' + latest_policy_article.slug">
                    <v-row class="mt-n10">
                        <v-col cols="12" md="6">
                            <p class="smallBody">{{latest_policy_article.published_at.slice(0,10)}} </p>
                            <p class="mediumBody"><strong>{{latest_policy_article.title}} </strong>
                            </p>
                            <p class="smallBody">
                               Whether you’re a business looking to compensate for your operation’s carbon footprint, or... 
                            </p>
                        </v-col>

                        <v-col cols="12" md="6" class="ma-auto">
                           <v-img fit max-height="400" max-width="400" src="images/assets/Images/policy-image.png"></v-img>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</v-container>

<v-container fluid class='charcoal'>
    <ReadyToGetStarted></ReadyToGetStarted>
</v-container>
   
</div>
</template>

<script>
import axios from "axios";
import ReadyToGetStarted from '../components/ReadyToGetStarted.vue'

export default {
    name:'Learn',
    components:{
        ReadyToGetStarted
    },

    data () {
      return {
        articles:[],
        latest_science_article: null,
        latest_markets_article: null,
        latest_policy_article: null,
        articles_key: 0,
        categories:[],
        window: 0,
        tab: null,
        component_key: 0,
        emission:false,
        carbon_credit :false,
        carbon_neutrality:false,
        about_topics:[
            {title:'Emissions',image:'images/assets/Icons/Emission.svg', subtitle:'The driver of climate change and global warming. These refer to human or machine-made chemical emissions that release carbon dioxide into the atmosphere.',read_more:false,text:'Emissions are, for the most part, what drive climate change and cause global warming. Greenhouse Gas Emissions (GHGs) are essential in keeping the planet habitable, but far too many contribute towards climate change and its associated negative impacts.'},
            {title:'Carbon offsetting',image:'images/assets/Icons/Carbon-offsetting.svg', subtitle:'Carbon offsetting involves calculating the carbon emissions generated by a particular activity  and then purchasing carbon credits that pay for avoiding or removing the same amount of carbon elsewhere in the environment.',read_more:false,text:'Carbon offsetting involves calculating the amount of carbon emissions generated by a particular activity (such as manufacturing a pair of shoes) and then purchasing carbon credits that pay for avoiding or removing the same amount of carbon elsewhere in the environment.</br></br> Because emissions move through the atmosphere, removing an equivalent amount of emissions elsewhere in the environment has the same net impact as if your emissions didn`t occur. Buying carbon credits that reduce or eliminate GHGs also creates a market that stimulates the creation of additional carbon-removal projects.'},
            {title:'Carbon credits',image:'/images/assets/Images/Union.svg', subtitle:'A carbon credit is an instrument representing the reduction, avoidance, or sequestration of 1 metric ton of carbon emissions. These instruments are used to compensate for an equal amount of carbon emissions elsewhere.',read_more:false,text:'A carbon credit is an instrument representing the reduction, avoidance, or sequestration of 1 metric ton of carbon emissions. Also referred to as carbon offsets, these instruments are used to compensate for an equal amount of carbon emissions elsewhere. Carbon offset projects are verified by third parties to ensure that they reduce, avoid, or sequester the amount of carbon that they claim.'},
            {title:'Carbon Neutral',image:'images/assets/Icons/Carbon-neutral.svg', subtitle:'A product becomes carbon neutral when the emissions of the product’s manufacturing and distribution are calculated and compensated for via carbon offsetting projects.',read_more:false,text:'A product becomes carbon neutral when the emissions of the product`s manufacturing and distribution are calculated and compensated for via carbon offsetting projects. </br></br> Offsetting carbon emissions, in addition to avoidance and reduction, is an important step in climate action.'},
        ],
      }
    },
    computed: {
      projects: {
        get() {
          return this.$store.getters.getProjects;
        },
      },
    },

    mounted: function() {
        this.getArticles();
    },

    methods: {
    openCard(index){
        for (let i = 0; i < this.about_topics.length; i++) {
            if(i==index){
                this.about_topics[i].read_more=true;
            } else{
                this.about_topics[i].read_more=false;
            }
        }
    },

    closeCards() {
        for (let i = 0; i < this.about_topics.length; i++) {
          this.about_topics[i].read_more=false;
        }
    },

    getThumbnail(uuid, post_index) {
      axios
        .get(process.env.VUE_APP_API_URL + "/uploads/download/public/" + uuid ,
        {
          responseType: 'blob',
        })
        .then((response) => {
          var thumbnail_url = window.URL.createObjectURL(new Blob([response.data]));
          this.articles[post_index].thumbnail_url = thumbnail_url;
          this.component_key += 1;
        })
    },

    getArticles() {
        axios
        .get(process.env.VUE_APP_API_URL + '/articles/categories/all')
        .then((response) => {
            this.categories = response.data.categories;
        })

        axios
        .get(process.env.VUE_APP_API_URL + '/articles')
        .then((response) => {
            this.articles = response.data.articles;

            //get latest articles of each category

            this.latest_science_article = this.articles.filter((e) => {return e.id == 3})[0];
            // var science_articles = this.articles.filter((e) => {return e.category_id == 1});
            // this.latest_science_article = science_articles[science_articles.length - 1];

            var markets_articles = this.articles.filter((e) => {return e.category_id == 2});
            this.latest_markets_article = markets_articles[markets_articles.length - 1];

            var policy_articles = this.articles.filter((e) => {return e.category_id == 3});
            this.latest_policy_article = policy_articles[policy_articles.length - 1];

            for (let i = 0; i < this.articles.length; i++) {
              if(this.articles[i].thumbnail_uuid != null){
                this.getThumbnail(this.articles[i].thumbnail_uuid, i);
              }
            }
        })
    },
    }
}
</script>

<style>
.v-card--reveal {
  top:0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
  z-index:4;
}
</style>
