import axios from "axios";
import store from "../store";
import router from '../router';

export default function setup() {

  //Include Laravel sanctum credentions
  axios.defaults.withCredentials = true;

  //logout on front-end if api auth fails
  axios.interceptors.response.use(function (response) {
    return response;
  }, function (err) {
    if (
      err.response.status == 401 
      || err.response.data.message === "Unauthenticated."
    ) {
      store.dispatch("logout"); //also redirects to login
    }

    if (
      err.response.status == 403 
      || err.response.data.message === "Forbidden."
    ) {
      router.push('/forbidden');
    }

    if (
      err.response.status == 404
    ) {
      router.push('/page-not-found');
    }

    return Promise.reject(err);
  });
}
