<template>
<v-container>

    <v-card  class="mb-6 projectsPurple mt-0 dashboardCard my-0">
      <div class="rightBars rightGrayBars rightGrayBarsCard"></div>
      <v-row justify="space-between">
        <v-col lg="4" class="pa-5">
          <div style="float:left">
            <v-avatar fab class="white pa-1  mr-5" >
            <v-icon>mdi-store-settings</v-icon>
            </v-avatar> 
            <p style="color:black; float: right" class="description mt-4">Transaction Details</p>
          </div>
        </v-col>
        <v-col class="pa-5 my-auto" justify="end" lg="4">
        </v-col>
      </v-row>
    </v-card>

    <v-row v-if="transaction">
        <v-col md="4">
            <v-card  class="dashboardCard my-0" style="height: 100%">
                <v-card-title>
                    <h4>Transaction</h4>
                </v-card-title>
                <v-simple-table dense>
                    <tbody>
                        <tr>
                            <td>Date</td>
                            <td>{{transaction.date}}</td>
                        </tr>
                        <tr>
                            <td>Purchase ID</td>
                            <td>{{transaction.purchase_id}}</td>
                        </tr>
                        <tr>
                            <td>Purchase Reference</td>
                            <td>{{transaction.purchase_reference}}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-card>
        </v-col>
        <v-col md="4">
            <v-card  class="dashboardCard my-0" style="height: 100%">
                <v-card-title>
                    <h4>Price</h4>
                </v-card-title>
                <v-simple-table dense>
                    <tbody>
                        <tr>
                            <td>Total Price</td>
                            <td class="text-right">{{transaction.currency}} {{transaction.total_price}}</td>
                        </tr>
                        <tr>
                            <td>Tax</td>
                            <td class="text-right">{{transaction.currency}} {{transaction.tax}}</td>
                        </tr>
                        <tr>
                            <td>Carbon offset price</td>
                            <td class="text-right">{{transaction.currency}} {{transaction.offset_price}}</td>
                        </tr>
                        <tr>
                            <td>Invoice Price</td>
                            <td class="text-right">{{transaction.currency}} {{transaction.invoice_price}}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-card>
        </v-col>
        <v-col md="4" class="mt-0">
            <v-card  class="dashboardCard my-0" style="height: 100%">
                <v-card-title>
                    <h4>Client</h4>
                </v-card-title>
                <v-simple-table dense>
                    <tbody>
                        <tr>
                            <td> Name</td>
                            <td>{{transaction.name}}</td>
                        </tr>
                        <tr>
                            <td> Number</td>
                            <td>{{transaction.phone}}</td>
                        </tr>
                        <tr>
                            <td> Email</td>
                            <td>{{transaction.email}}</td>
                        </tr>
                        <tr>
                            <td> Billing Address</td>
                            <td>
                                {{billing_address}}
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-card>
        </v-col>
    </v-row>

    <v-row>
        <v-col md="8">
            <v-card class="dashboardCard my-0" v-if="transaction.transaction_items">
                <v-card-title>
                    <h4>Transaction Items</h4>
                </v-card-title>
                <v-simple-table dense>
                    <thead>
                        <tr>
                          <th class="text-left">Item</th>
                          <th>Quantity</th>
                          <th class="text-right">Price</th>
                          <th class="text-right">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in transaction.transaction_items" :key="index">
                          <td>
                              {{item.item}}
                          </td>
                          <td>
                              {{item.quantity}}
                          </td>
                          <td class="text-right">
                              {{transaction.currency}} {{item.price}}
                          </td>
                          <td class="text-right">
                              {{transaction.currency}} {{item.quantity * item.price}}
                          </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td class="text-right" colspan="4">
                                {{transaction.currency}} {{
                                    transaction.transaction_items.reduce((sum, current_item)=> {return sum + current_item.price * current_item.quantity},0)
                                }}
                            </td>
                        </tr>
                    </tfoot>
                </v-simple-table>
            </v-card>
        </v-col>
    </v-row>
</v-container>
  
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
    name:'Transaction',
  
    data () {
    return {
        transaction:'',
        organisation:'',
    }},

    mounted: function() {
        this.getData();
    },

    methods:{
        formatDate(date) {
        return moment(date).format("YYYY-MM-DD");
        },

        getData() {
            axios
                .get(process.env.VUE_APP_API_URL + '/organisations/'+ this.$route.params.slug +'/transactions/' + this.$route.params.id)
                .then((response) => {
                    this.transaction = response.data.transaction;
                    this.organisation = response.data.organisation;
                    this.transaction.date = this.formatDate(this.transaction.created_at);
                    this.billing_address = JSON.parse(this.transaction.billing_address);
                })
                .catch((error) => { 
                    console.log(error);
                });
            
        },
    }
}

</script>
