<template>
  <div v-if="project" :key="page_key">
    <v-container fluid class="projectsYellow">
      <v-container>
        <div class="my-md-3">
           <router-link class="px-0 smallBody" style="text-decoration:  none; color: #78786A !important;" text router to="/projects" ><v-icon>mdi-arrow-left</v-icon>  Back to projects</router-link>
        </div>
      </v-container>

      <!-- desktop banner -->
      <div>
        <v-row class="d-none d-md-flex mb-10"> 
          <!-- previous project -->
          <v-col cols="1" class="d-none d-md-block d-lg-block d-xl-block">
            <router-link :to="`/projects/` + projects[prev_project_index].slug" style="text-decoration: none;">
              <v-img :lazy-src="this.projects[prev_project_index].banner" :height="project_hero_div_height + `px`"/>
              <v-icon x-large color="white" :style="`position: relative; top:`+ (-project_hero_div_height/1.9) +`px; left: 2vw; z-index: 1`">mdi-arrow-left-thin-circle-outline</v-icon>
            </router-link>
          </v-col>
          <v-col cols="12" md="10" class="px-0"> 
            <div class="ProjectHeroImages d-flex" ref="heroDiv">
              <v-img :src="project.banner"/>
              <div v-for="(supplier, index) in project.suppliers" :key="index"
                :style="`
                        position: absolute;
                        top: ` + (0 + (index == 0 ? 20 : 90*index)) + `px;
                        left: 20px;
                        padding:10px;
                        height:60px;
                        width:60px;
                        background: #FFFFFF;
                        z-index:2
                `">
                <a :href="supplier.link" :target="supplier.link ? `_blank` : ``">
                  <img :style="`background-size:contain; width:40px;`"
                  :src="supplier.image">
                </a> 
              </div>
            </div>
          </v-col>
          <!-- next project -->
          <v-col cols="1" class="d-none d-md-flex d-lg-flex d-xl-flex">
            <router-link :to="`/projects/` + projects[next_project_index].slug" style="text-decoration: none;">
              <v-img :src="this.projects[next_project_index].banner" :height="project_hero_div_height + `px`"/>
              <v-icon x-large color="white" :style="`position: relative; top:`+ (-project_hero_div_height/1.9) +`px; left: 2vw; z-index: 1`">mdi-arrow-right-thin-circle-outline</v-icon>
            </router-link>
          </v-col>
        </v-row>
      </div>

      <!-- mobile banner -->
      <v-container class="d-md-none d-lg-none d-xl-none">
        <v-row> 
          <v-col cols="12" md="10" class="px-0"> 

            <div class="ProjectHeroImages">
              <v-img :src="project.banner"/>
              <div v-for="(supplier, index) in project.suppliers" :key="index"
                :style="`
                        position: absolute;
                        top: 5px;
                        left: ` + (5 + (index == 0 ? 0 : 65*index)) + `px;
                        width:60px;
                        padding:10px;
                        height:60px;
                        background: #FFFFFF;
                        z-index:2
                `">
                <a :href="supplier.link" :target="supplier.link ? `_blank` : ``">
                  <img :style="`background-size:contain; width:40px;`"
                  :src="supplier.image">
                </a> 
              </div>
            </div>
            <!-- previous project -->
            <router-link :to="`/projects/` + projects[prev_project_index].slug" style="text-decoration: none;">
              <v-icon x-large color="charcoal" class="pl-2 pt-5">mdi-arrow-left-thin-circle-outline</v-icon>
            </router-link>
            <!-- next project -->
            <router-link :to="`/projects/` + projects[next_project_index].slug" style="text-decoration: none;">
              <v-icon x-large color="charcoal" class="pt-5">mdi-arrow-right-thin-circle-outline</v-icon>
            </router-link>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-row justify-center class="text-center my-md-0 mt-md-n5 pb-md-5" justify-space-around>
          <v-col cols="12" md="6" class="pr-8 text-left">
            <p class="subTitle">{{project.country}}</p>
            <h2 v-html="project.title"></h2>
          </v-col>
          <v-spacer />
          <v-col cols="12" md="6" class="pl-md-4 pr-lg-8 text-left">
            <div v-html="project.intro" style="white-space: pre-wrap;"></div>
          </v-col>
        </v-row>
      </v-container>
    </v-container>

    <v-container fluid class="lightGray">
      <v-container class="my-15">
        <p class="description mb-10">IMPACT</p>
        <v-row class="smallBody ml-lg-n5 mt-10">
            <v-col cols="12" md="3" class="py-md-0 fill-height" v-for="(impact_card, index) in project.impact_cards" :key="index">
              <v-row class="py-md-0">
                <v-divider style="min-height:15vh" class="d-none d-md-flex d-lg-flex d-xl-flex mx-lg-5" vertical></v-divider>
                <v-col class="fill-height">
                  <p class="smallBody"><strong>{{impact_card.card_title}}</strong></p>
                  <p>{{impact_card.card_text}}</p>
                </v-col>
              </v-row>
            </v-col>
        </v-row>
      </v-container>
    </v-container>

    <v-container fluid class='charcoal'>
        <ReadyToGetStarted></ReadyToGetStarted>
    </v-container>
  </div>
</template>

<script>
import ReadyToGetStarted from '@/components/ReadyToGetStarted.vue'

export default {
  name:'Project',
  components:{
      ReadyToGetStarted
  },
  data () {
    return {
      page_key: 0,
      banner_key: 0,
      project_slug: this.$route.params.slug,
      project_index: null,
      next_project_index: null,
      prev_project_index: null,
      project: null,
      project_hero_div_height: '0',//px
    }
  },
  computed: {
    projects: {
      get() {
        return this.$store.getters.getProjects;
      },
    }
  },
  watch: {
    // whenever the route changes, this function needs to run to update the project
    '$route.params.slug': {
      handler: function() {
         this.getData();
      },
      deep: true,
    },
  },
  created() {
    window.addEventListener("resize", this.resizeHandler);
  },
  mounted: function() {
    this.getData();
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  updated() {
    setTimeout(()=> {
                      this.matchHeight();
                    },100);
  },
  methods: {
    getData() {
      //get project index to show link to the project and the next/previous project
      var project_count = this.projects.length;
      for(var i = 0; i < project_count; i++) {
        if(this.projects[i].slug == this.$route.params.slug) {
          this.project_index = i;
          this.project = this.projects[i];
          this.prev_project_index = (i == 0) ? project_count - 1 : i - 1;
          this.next_project_index = ((i + 1) < project_count) ? i + 1 : 0;

        }
      }

      this.page_key ++;
    },
    matchHeight() {
      this.project_hero_div_height = this.$refs.heroDiv.clientHeight;
    },
    resizeHandler() {
      this.matchHeight();
    }
  }
}
</script>
