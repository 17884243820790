<template>
    <v-row class="mx-10 mt-md-10 pt-md-15" align="center">
        <v-col cols="12">
            <h1 class="h3 display-1 my-5">Password reset</h1>
            <p class="smallBody">Enter your email address to receive a password reset link.</p>
            <validation-observer
                ref="observer"
                v-slot="{ invalid }"
            >
                <form @submit.prevent="submit">
                    <validation-provider
                        v-slot="{ errors }"
                        name="email"
                        rules="required|email"
                    >
                        <v-text-field
                        v-model="form.email"
                        :error-messages="errors"
                        label="Email address"
                        required
                        class="mx-auto rounded-0"
                        dense
                        outlined
                        ></v-text-field>
                    </validation-provider>

                    <v-btn 
                        class="black white--text rounded-0 mb-md-5"
                        width="100%"
                        v-on:click.native="requestEmail()"
                        :disabled="invalid"
                    >
                        Request reset email
                    </v-btn>
                </form>
            </validation-observer>
            <p class="mt-2">Already have an account? <span><router-link to="/login">Login</router-link></span></p>
        </v-col>
    </v-row>
</template>

<script>
import axios from "axios";
import { required, email } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import SuccessAlert from '@/mixins/successAlert';
import ErrorAlert from '@/mixins/errorAlert';

setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})

extend('email', {
  ...email,
  message: 'Email must be valid',
})

export default {
  name: "ForgotPassword",
  mixins: [SuccessAlert, ErrorAlert],

  components: {
    ValidationProvider,
    ValidationObserver, 
  },

  data() {
    return {
        form: {
            email: ""
        },
    }
  },    

  methods: {
    requestEmail() {

        //get cookie first for xsrf-token then request reset
        axios
        .get(process.env.VUE_APP_API_URL + '../../../sanctum/csrf-cookie')
        .then(() => {
            //request reset
            axios
            .post(process.env.VUE_APP_API_URL + "/password/email", this.form)
            .then((response) => {
                this.successAlert("Sent!", response.data.success.message);
                setTimeout(()=>{
                    this.$router.push('login')
                },
                2000);
            })
            .catch((error) => { 
                this.errorAlert("Sorry", error.response.data.error.message);
            });
        })
    }
  }
}
</script>
<style></style>
