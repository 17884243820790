var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-sheet',{staticClass:"mx-auto",attrs:{"max-width":"800","height":"300"}},[_c('h1',{staticClass:"text-center"},[_vm._v("Change password")]),_c('v-container',{staticClass:"grey lighten-5"},[_c('v-row',{staticClass:"d-flex justify-center",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"6"}},[_c('v-card',{staticClass:"d-flex justify-center pa-2",attrs:{"outlined":"","tile":""}},[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Email address","required":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"password","rules":{
                                              required: true,
                                          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Password","type":"password","required":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_c('v-btn',{staticClass:"mr-4 mx-auto",attrs:{"type":"submit","disabled":invalid},nativeOn:{"click":function($event){return _vm.login()}}},[_vm._v(" Log in ")])],1)]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('router-link',{attrs:{"to":"/register"}},[_vm._v(" Register ")])],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('router-link',{attrs:{"to":"/forgot-password"}},[_vm._v(" Forgot password ")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }