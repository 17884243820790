<template>
<v-container v-if="organisation">
    <v-card class="mb-6 projectsPurple mt-0 dashboardCard">
      <div class="rightBars rightGrayBars rightGrayBarsCard"></div>
      <v-row justify="space-between">
          <v-col lg="12" class="pa-5">
            <div style="float:left">
              <v-avatar fab class="white pa-1  mr-5" >
              <v-icon>mdi-receipt-text-outline</v-icon>
              </v-avatar> 
              <p  style="color:black; float: right" class="description mt-4">{{organisation.slug}}'S TRANSACTIONS BY MONTH</p>
            </div>
          </v-col>
      </v-row>
    </v-card>

     <v-card class="my-6 dashboardCard" v-if="transactions">
      <v-card-title>
        <p> Transactions for {{selected_month}}</p>
        <v-spacer></v-spacer>
        <v-row>
          <v-col>
              <v-select
                :items="months"
                label="Select month"
                v-model="selected_month"
                @change="generateReport()"
                dense
                item-value="id"
                item-text="name"
              ></v-select>
          </v-col>
          <v-col>
             <v-text-field
              v-model="transaction_search"
              append-icon="mdi-magnify"
              label="Search in this month"
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="transaction_table_headers"
          :items="transactions"
          :search="transaction_search"
        >
          <template v-slot:item.created_at="{ item }">
            {{item.created_at.slice(0, 19)}}
          </template>
          <template v-slot:item.actions="{ item }">
            <router-link style="text-decoration:none" :to="`/dashboard/organisations/${organisation.slug}/transactions/${item.id}`">
              <v-icon small @click="item">
                mdi-eye
              </v-icon>
            </router-link>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
</v-container>
</template>

<script>
import axios from "axios";

export default {
    name:'Transactions',
    data () {
    return {
        months: [
         {"id":1,"name":'January'}, 
         {"id":2,"name":'February'},
         {"id":3,"name":'March'}, 
         {"id":4,"name":'April'},
         {"id":5,"name":'May'},
         {"id":6,"name":'June'},
         {"id":7,"name":'July'},
         {"id":8,"name":'August'},
         {"id":9,"name":'September'},
         {"id":10,"name":'October'},
         {"id":11,"name":'November'},
         {"id":12,"name":'December'},
        ],
        transactions: null,
        selected_month: new Date().toISOString().slice(0, 7),
        organisation:null, 
        transaction_search: '',
    }},


     computed: {
        transaction_table_headers () {
          return [
            {
              text: 'Created at',
              align: 'start',
              sortable: true,
              value: 'created_at',
            },
            {
              text: 'Purchase id',
              align: 'start',
              sortable: true,
              value: 'purchase_id',
            },
            {
              text: 'Purchase reference',
              align: 'start',
              sortable: true,
              value: 'purchase_reference',
            },
            {
              text: 'Items',
              align: 'start',
              sortable: true,
              value: 'transaction_item_count',
            },
            {
              text: 'State',
              align: 'start',
              sortable: true,
              value: 'state',
            },
            {
              text: 'Offset price',
              align: 'start',
              sortable: true,
              value: 'offset_price',
            },
            {
              text: 'Invoice price',
              align: 'start',
              sortable: true,
              value: 'invoice_price',
            },
            { text: 'Actions', value: 'actions' },
          ]
        },},

        mounted: function() {
          this.generateReport();
          this.getOrganisation();
        },

        methods:{
        getTransactions() {
            axios
            .get(process.env.VUE_APP_API_URL + '/organisations/' + this.$route.params.slug + '/transactions')
            .then((response) => {
              this.transactions = response.data.transactions;
            })
        },

        generateReport(){
            console.log('changing...');

            axios
            .get(process.env.VUE_APP_API_URL + '/organisations/' + this.$route.params.slug + '/transactions-by-month',{ params: {month: this.selected_month}})
            .then((response) => {
              this.months = response.data.months;
              this.transactions = response.data.transactions;
              console.log(this.transactions);
            })
        },

        getOrganisation() {
          axios
          .get(process.env.VUE_APP_API_URL + '/organisations/' + this.$route.params.slug)
          .then((response) => {
            this.organisation = response.data.organisation;
          })
        },
        }
}

</script>
