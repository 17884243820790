<template>
  <v-container bottom="0" fluid>
     <v-card class="mb-6 projectsPurple mt-0 dashboardCard">
       <div class="rightBars rightGrayBars rightGrayBarsCard"></div>
         <v-row justify="space-between">
          <v-col lg="4" class="pa-5">
            <div style="float:left">
              <v-avatar fab class="white pa-1  mr-5" >
              <v-icon>mdi-account-multiple-plus</v-icon>
              </v-avatar> 
              <p style="color:black; float: right" class="description mt-4">leads</p>
            </div>
          </v-col>
          <v-col class="pa-5 my-auto" justify="end" lg="4">
            <!-- <v-btn to="/dashboard/leads/create" color="secondary"  style="float: right">
              Create A User
            </v-btn> -->
          </v-col>
        </v-row>
    </v-card>

    <v-card  class="my-6 dashboardCard">
        <v-card-title>
          Leads
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="leads"
          :search="search"
        >
          <template v-slot:item.status="{ item }">
            <v-dialog
              content-class="elevation-0"
              v-model="item.status_dialog"
              max-width="700"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="transparent"
                  elevation="0"
                  class="float-right"
                  v-bind="attrs"
                  v-on="on"
                >
                  <span
                    :class="[item.status == 'new' ? 'sapling--text' : item.status == 'lost' ? 'dustyOrange--text' : 'cobalite--text']"
                    >●</span
                  >
                  {{item.status}}
                  <v-icon
                    small
                    v-bind="attrs"
                    class="mx-2"
                 >
                    mdi-list-status
                 </v-icon>
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="text-h6">
                  Update lead status for {{item.store}}
                </v-card-title>

                <v-card-text> 
                  <p></p>
                  <v-form>
                    <label>Select lead status</label>
                    <v-select
                        v-model="item.updated_status"
                        :items="['new','contacted','lost','closed']"
                        item-value="name"
                        item-text="name"
                        outlined
                        dense
                        required
                    ></v-select>
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-btn
                    color="charcoal darken-1"
                    text
                    class="float-left"
                    @click="item.status_dialog = false;"
                  >
                    Cancel
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="sapling darken-1"
                    text
                    @click="changeLeadStatus(item); item.status_dialog = false;"
                  >
                    Save
                  </v-btn>

                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>

          <template v-slot:item.created_at="{ item }">
            {{item.created_at.slice(0,19).replace('T',' ')}}
          </template>
          <template v-slot:item.updated_at="{ item }">
            {{item.updated_at.slice(0,19).replace('T',' ')}}
          </template>
        </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import SuccessAlert from '@/mixins/successAlert';
import ErrorAlert from '@/mixins/errorAlert';

export default {
    mixins:[SuccessAlert,ErrorAlert],
    name:'Leads',
      
    data () {
      return {
        leads:[],
        search: '',
        headers: [
            { text: 'Store name', value: 'store' },
            { text: 'Store platform', value: 'store_platform' },
            {
              text: 'First Name',
              align: 'start',
              sortable: true,
              value: 'first_name',
            },

            {
              text: 'Last Name',
              align: 'start',
              sortable: true,
              value: 'last_name',
            },
            { text: 'Email',sortable: false, value: 'email' },
            { text: 'Phone',  value: 'phone' },
            { text: 'Status', value: 'status' },
            { text: 'Created at', value: 'created_at', width: '15%'},
            { text: 'Updated at', value: 'updated_at', width: '15%'},
        ],
      }
    },


    mounted: function() {
      this.getLeads();
    },

    methods: {
      getLeads() {
        axios
        .get(process.env.VUE_APP_API_URL + '/lead/leads/')
        .then((response) => {
          this.leads = response.data.leads;
          for(var i = 0; i < this.leads.length; i++) {
            this.leads[i].updated_status = this.leads[i].status;
          }
        })
      },
      changeLeadStatus(lead){
        axios
        .put(process.env.VUE_APP_API_URL + '/lead/leads/' + lead.id, 
          { "status":lead.updated_status}
        )
        .then((response)=>{
          this.successAlert("Saved", response.data.success);
          this.getLeads();
        })

      }

    },
  }

</script>
