<template>
  <div style="min-height: 50vh;">
    <h1 class="text-center mt-15 pt-15">403 | Forbidden.</h1>
  </div>
</template>

<script>

export default {
    name:'403'
}

</script>
