<template>
  <v-container v-if="organisation">
    <v-card  class="mb-6 projectsPurple mt-0 dashboardCard">
      <div class="rightBars rightGrayBars rightGrayBarsCard"></div>
      <v-row justify="space-between">
        <v-col lg="4" class="pa-5">
          <div style="float:left">
            <v-avatar fab class="white pa-1  mr-5" >
            <v-icon>mdi-store-settings</v-icon>
            </v-avatar> 
            <p style="color:black; float: right" class="description mt-4">{{organisation.name}}</p>
          </div>
        </v-col>
        <v-col class="pa-5 my-auto" justify="end" lg="4">
        </v-col>
      </v-row>
    </v-card>

    <v-card class="my-6 dashboardCard">
      <v-card-title>
        <v-row>
          <v-col cols="12" md="4">
            Store details
          </v-col>
          <v-col cols="12" md="8" class="text-right">
            <v-btn :to="'/dashboard/organisations/'+$route.params.slug+'/transactions'" >
              <v-icon
                  small
                  class="mx-2"
                >
                mdi-receipt-text-outline
              </v-icon>
              Transactions
            </v-btn>

            <v-btn :to="'/dashboard/organisations/'+$route.params.slug+'/integration-setup'" class="ml-5">
              <v-icon
                  small
                  class="mx-2"
                >
                mdi-cog
              </v-icon>
              Integration Setup
            </v-btn>

            <v-btn :to="'/dashboard/organisations/'+$route.params.slug+'/edit'" class="ml-5">
              <v-icon
                  small
                  class="mx-2"
                >
                mdi-pencil
              </v-icon>
              Edit Details
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
          <p>Description: {{organisation.details}}</p>
          <p>Email: {{organisation.email}}</p>
          <p>Phone: {{organisation.phone}}</p>
          <p>Address: {{organisation.address_line1}}, {{organisation.address_line2}}, {{organisation.province}}, {{organisation.postal_code}}, {{organisation.country}},</p>
          <p>Value-Added Tax number: {{organisation.tax_number}}</p>
          <p>VAT is paid on all transactions: {{organisation.pays_tax}}</p>
      </v-card-text>
    </v-card>

    <v-card class="my-6 dashboardCard">
      <v-card-title>
        Users
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-btn to="/dashboard/users/create">
            Add User
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="user_table_headers"
          :items="users"
          :search="user_search"
        >
          <template v-slot:item.active="{ item }">
                 <v-switch 
                  color="primary"
                  value
                  :input-value="userStatus(item)"
                   @click="changeUserStatus(item)"
                ></v-switch>
          </template>

          <template v-slot:item.actions="{ item }">
             <router-link style="text-decoration:none" :to="`/dashboard/users/` + item.slug">
                <v-icon
                  small
                  @click="item"
                >
                   mdi-eye
                </v-icon>
            </router-link>

          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    
    <!-- <v-card class="my-6 dashboardCard" v-if="transactions">
      <v-card-title>
        Transactions
        <v-spacer></v-spacer>
        <v-text-field
          v-model="transaction_search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="transaction_table_headers"
          :items="transactions"
          :search="transaction_search"
        >
          <template v-slot:item.actions="{ item }">
            <router-link style="text-decoration:none" :to="`/dashboard/organisations/${organisation.slug}/transactions/${item.id}`">
            <v-icon small @click="item">
              mdi-eye
            </v-icon>
          </router-link>

          </template>
        </v-data-table>
        <v-btn class="rounded-1" router :to="'/dashboard/organisations/'+$route.params.slug+'/transactions'">
          <v-icon small class="mx-2">
            mdi-file-chart
          </v-icon>
          Generate report
        </v-btn>
      </v-card-text>
    </v-card> -->
  </v-container>
</template>

<script>
import axios from "axios";
import SuccessAlert from '@/mixins/successAlert';
import ErrorAlert from '@/mixins/errorAlert';

export default {
      mixins:[SuccessAlert,ErrorAlert],
      name:'Organisation',
      
      data () {
        return {
          switch1: true,
          switch2: false,
          users:[],
          organisation: null,
          transactions: null,
          status:null,
          user_search: '',
          transaction_search: '',
          calories: '',
        }
      },

      computed: {
        transaction_table_headers () {
          return [
            {
              text: 'Created at',
              align: 'start',
              sortable: true,
              value: 'created_at',
            },
            {
              text: 'Purchase id',
              align: 'start',
              sortable: true,
              value: 'purchase_id',
            },
            {
              text: 'Purchase reference',
              align: 'start',
              sortable: true,
              value: 'purchase_reference',
            },
            {
              text: 'Items',
              align: 'start',
              sortable: true,
              value: 'transaction_item_count',
            },
            {
              text: 'State',
              align: 'start',
              sortable: true,
              value: 'state',
            },
            {
              text: 'Offset price',
              align: 'start',
              sortable: true,
              value: 'offset_price',
            },
            {
              text: 'Invoice price',
              align: 'start',
              sortable: true,
              value: 'invoice_price',
            },
            { text: 'Actions', value: 'actions' },
          ]
        },
        user_table_headers () {
          return [
            {
              text: 'First Name',
              align: 'start',
              sortable: true,
              value: 'first_name',
            },

            {
              text: 'Last Name',
              align: 'start',
              sortable: true,
              value: 'last_name',
            },
            
            { text: 'Email',sortable: false, value: 'email' },
            { text: 'Phone',  value: 'phone' },
            { text: 'Active', value: 'active' },
            { text: 'Actions', value: 'actions' },
          ]
        },
      },

      mounted: function() {
        this.getOrganisation();
        // this.getTransactions();
      },

      methods: {
        getOrganisation() {
          axios
          .get(process.env.VUE_APP_API_URL + '/organisations/' + this.$route.params.slug)
          .then((response) => {
            this.organisation = response.data.organisation;
            this.users = this.organisation.users;
          })
        },

        getTransactions() {
          axios
          .get(process.env.VUE_APP_API_URL + '/organisations/' + this.$route.params.slug + '/transactions')
          .then((response) => {
            this.transactions = response.data.transactions;
          })
        },

        editUser() {
          this.$router.push('/edit-user');
        },

        filterOnlyCapsText (value, search) {
          return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().toLocaleUpperCase().indexOf(search) !== -1
        },

        userStatus(user){
          if (user.is_active==1){return true}
          return false;
        },

        changeUserStatus(user){
          axios
          .put(process.env.VUE_APP_API_URL + '/users/' + user.slug + '/status')
          .then((response) => {
            this.successAlert("User status updated",response.data.success); //this is a mixin function successAlert($title,$message)
          })
          .catch((error) => {
            this.errorAlert("Failed to update user status.",Object.values(error.response.data.errors).join(' '));
            setTimeout(
              () => {this.$router.go()},//reloads the page with correct data
              3000
            )
          });
        },
      },
  }

</script>
