<template>
  <div>
     <v-sheet
        max-width="800"
        height="300"
        class="mx-auto"
        >
        <h1 class="text-center">Change password</h1> 
        <v-container class="grey lighten-5">
            <v-row no-gutters class="d-flex justify-center">
                <v-col
                    cols="12"
                    sm="8"
                    md="6"
                >
                    <v-card
                    class="d-flex justify-center pa-2"
                    outlined
                    tile
                    >

                        <v-row>
                            <v-col
                                cols="12"
                                class="text-center"
                            >
                                <validation-observer
                                    ref="observer"
                                    v-slot="{ invalid }"
                                >
                                    <form @submit.prevent="submit">
                                        <validation-provider
                                            v-slot="{ errors }"
                                            name="email"
                                            rules="required|email"
                                        >
                                            <v-text-field
                                            v-model="form.email"
                                            :error-messages="errors"
                                            label="Email address"
                                            required
                                            ></v-text-field>
                                        </validation-provider>

                                        <validation-provider
                                            v-slot="{ errors }"
                                            name="password"
                                            :rules="{
                                                required: true,
                                            }"
                                        >
                                            <v-text-field
                                            v-model="form.password"
                                            :error-messages="errors"
                                            label="Password"
                                            type="password"
                                            required
                                            ></v-text-field>
                                        </validation-provider>

                                        <v-btn
                                            class="mr-4 mx-auto"
                                            type="submit"
                                            v-on:click.native="login()"
                                            :disabled="invalid"
                                        >
                                            Log in
                                        </v-btn>
                                    </form>
                                </validation-observer>

                            </v-col>
                            <v-col
                                cols="6"    
                            >
                                <router-link to="/register">
                                    Register
                                </router-link>
                            </v-col>
                            <v-col
                                cols="6"
                                class="text-right"
                            >
                                <router-link to="/forgot-password">
                                    Forgot password
                                </router-link>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
      
        </v-sheet>
  </div>
</template>
<script>
import axios from "axios";
import { required, digits, email, max } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

setInteractionMode('eager')

extend('digits', {
...digits,
message: '{_field_} needs to be {length} digits. ({_value_})',
})

extend('required', {
...required,
message: '{_field_} can not be empty',
})

extend('max', {
...max,
message: '{_field_} may not be greater than {length} characters',
})

extend('email', {
...email,
message: 'Email must be valid',
})

export default {
  name: "ChangePassword",

  components: {
      ValidationProvider,
      ValidationObserver,
  },

  data() {
      return {
          form: {
              email: "",
              password: "",
          },
      }
  },   

  methods: {
    register() {
      axios
        .post(process.env.VUE_APP_API_URL + "/register", this.form)
        .then((response) => {
          alert(response);
        })
        .catch((err) => {
          alert(err);
        });
    },
    clear() {
      this.password = ''
      this.email = ''
      // this.$refs.observer.reset()
    },

    // resetPassword(values) {

    //     if(values.new_password != values.new_password_confirmation){
    //         this.reset_error = "The new password and new password confirmation do not match."
    //         return;
    //     }

    //     this.reset_error = null;
    //     this.reset_status = 'Updating password...';

    //     values.token = this.$route.params.token;

    //     axios
    //     .post(process.env.VUE_APP_API_URL + "/password/change", values)
    //     .then((response) => {
    //         this.reset_status = response.data.success.message;
    //         this.$swal.fire('Success!', response.data.success.message, 'success')
    //         setTimeout(
    //             this.$router.push('/login'),
    //             2000
    //         )
    //     })
    //     .catch((error) => { 
    //         this.reset_status = null;
    //         if(error.response.data.error?.old_password) {
    //             this.reset_error = 'The old password entered does not match with old password in the database.';
    //         } else {
    //             this.reset_error = error.response.data;
    //         }
    //     });
    // }
  }
};
</script>
<style></style>
