<template>
  <v-container>
      <v-row class="text-left">
            <v-col cols="12" sm="4" class="py-0">
                <v-card class="mb-6 dashboardCard">
                    <v-card-text>
                        <v-row class="pa-auto ma-auto">
                            <v-col cols="10">
                                <p class="my-5 bigBody" style="font-size:34px;"> Store{{(organisation_count > 1) ? 's' : ''}}</p>
                            </v-col>
                            <v-col cols="2">
                                <v-avatar style="float:right; transform: scale(1)" fab class="sapling pa-1" width="10">
                                    <v-icon>mdi-store-settings</v-icon>
                                </v-avatar> 
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <h4 class="ml-3 mt-3">{{organisation_count}}</h4>
                    </v-card-text>
                </v-card>
            </v-col>
        
            <v-col cols="12" sm="4" class="py-0">
                <v-card class=" mb-6 dashboardCard">
                    <v-card-text>
                        <v-row class="pa-auto ma-auto">
                            <v-col cols="10">
                                <p class="my-5 bigBody" style="font-size:34px;">Purchases</p>
                            </v-col>
                            <v-col cols="2">
                                <v-avatar style="float:right; transform: scale(1)" fab class="combustion pa-1" width="10">
                                   <v-icon>mdi-post</v-icon>
                                </v-avatar> 
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <h4 class="ml-3 mt-3">{{transaction_count}}</h4>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col  cols="12" sm="4" class="py-0">
                <v-card class="mb-6 dashboardCard">
                    <v-card-text>
                        <v-row class="pa-auto ma-auto">
                            <v-col cols="10">
                                <p class="my-5 bigBody" style="font-size:34px;">Reduction</p>
                            </v-col>
                            <v-col cols="2">
                                <v-avatar style="float:right; transform: scale(1);" fab class="sapling pa-1" width="10">
                                     <v-icon>mdi-sprout</v-icon>
                                </v-avatar> 
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <h4 class="ml-3 mt-3">{{total_quantity}} tCO<sub>2</sub>e</h4>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>


        <v-card class="pa-5 dashboardCard" v-if="organisations">
          <v-card-title>
            Stores

            <v-spacer></v-spacer>
            <v-btn to="/dashboard/organisations/create">
                Add Store
            </v-btn>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="organisations"
            :search="search"
          >
            <template v-slot:item.default_percent="{ item }">
                {{item.default_percent*100}} %
            </template>

            <template v-slot:item.actions="{ item }">
              <router-link style="text-decoration:none" :to="`/dashboard/organisations/${item.slug}`">
                <v-icon
                  small
                  @click="item"
                  class="mx-2"
                >
                  mdi-eye
                </v-icon>
              </router-link>


              <router-link style="text-decoration:none" :to="`/dashboard/organisations/${item.slug}/integration-setup`">
                <v-icon
                  small
                  @click="item"
                  class="mx-2"
                >
                  mdi-cog
                </v-icon>
              </router-link>

              <router-link style="text-decoration:none" :to="`/dashboard/organisations/${item.slug}/edit`">
                <v-icon
                  small
                  @click="item"
                  class="mx-2"
                >
                  mdi-pencil
                </v-icon>
              </router-link>
          </template>

          </v-data-table>
        </v-card>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
    name:'dashboard',
    data() {
        return {
            organisations: null,
            organisation_count: 0,
            transaction_count: 0,
            total_quantity: 0,
            search: '',
        }
    },

    computed: {
        headers () {
          return [
              {
                text: 'Name',
                align: 'start',
                sortable: true,
                value: 'name',
              },
              { text: 'Country',  value: 'country' },
              { text: 'Email',sortable: false, value: 'email' },
              { text: 'Phone',sortable: false, value: 'phone' },
              { text: 'Default percentage',sortable: false, value: 'default_percent'},
              { text: 'Actions', value: 'actions', sortable: false },
          ]
        },
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            axios
            .get(process.env.VUE_APP_API_URL + '/organisations/my-organisations')
            .then((response) => {
                this.organisations = response.data.organisations;
                this.organisation_count = this.organisations.length;
                this.transaction_count = this.organisations.map(org => org.transction_count).reduce((acc, amount) => acc + amount);
                this.total_quantity = this.organisations.map(org => org.transction_total_quantity).reduce((acc, amount) => acc + amount);

            })
        }
    }
}

</script>
