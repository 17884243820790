<template>
<v-container fluid class="lightGray">    
    <v-row style="background:white" class="fullscreen">
        <v-col cols="12" md="7" class="login-hero-image">
            <div class="ma-10">
                <!-- <a href="/">
                    <v-img max-width="40"
                        src="images\curbon\Curbon_logomark_white.svg">
                    </v-img>
                </a> -->
                <div class="text-left pa-10 transparent-box hidden-sm-and-down">
                    <h3> You’re doing your part in working toward a 
                        net-zero future.
                    </h3>
                </div>
            </div>
        </v-col>
        
        <v-col cols="12" md="5">
            <v-row class="mx-10 my-md-10 mt-md-15 pa-10">
                <v-col cols="12">
                    <h1 class="h3 display-1">Reset Password</h1>
                    <p class="smallBody my-5">Complete the form to create your new password.</p>
                    <validation-observer
                        ref="observer"
                        v-slot="{ invalid }"
                    >
                        <form>
                            <validation-provider
                                v-slot="{ errors }"
                                name="email"
                                rules="required|email"
                            >
                                <v-text-field
                                v-model="form.email"
                                :error-messages="errors"
                                label="Email"
                                type="text"
                                required
                                class="mx-auto rounded-0"
                                dense
                                outlined
                                ></v-text-field>
                            </validation-provider>

                            <validation-provider
                                v-slot="{ errors }"
                                name="password"
                                rules="required|min:8"
                            >
                                <v-text-field
                                v-model="form.password"
                                :error-messages="errors"
                                label="Password"
                                type="password"
                                required
                                class="mx-auto rounded-0"
                                dense
                                outlined
                                ></v-text-field>
                            </validation-provider>

                            <validation-provider
                                v-slot="{ errors }"
                                name="password_confirmation"
                                rules="required|min:8"
                            >
                                <v-text-field
                                v-model="form.password_confirmation"
                                :error-messages="errors"
                                label="Password confirmation"
                                type="password"
                                required
                                class="mx-auto rounded-0"
                                dense
                                outlined
                                ></v-text-field>
                            </validation-provider>

                            <v-btn
                                class="black white--text rounded-0"
                                width="100%"
                                depressed
                                type="button"
                                v-on:click.native="resetPassword()"
                                :disabled="invalid"
                            >
                                Reset
                            </v-btn>
                        </form>
                    </validation-observer>

                </v-col>
            </v-row>
        </v-col>
    </v-row>
</v-container> 
</template>
<script>
import axios from "axios";
import { required, min } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import SuccessAlert from "../../mixins/successAlert";
import ErrorAlert from "../../mixins/errorAlert";

setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})

extend('min', {
  ...min,
  message: '{_field_} may not be less than {length} characters',
})

export default {
  name: "Reset Password",
  mixins: [SuccessAlert, ErrorAlert],
  components: {
      ValidationProvider,
      ValidationObserver, 
  },
  data() {
    return {
      form: {
          token: "",
          password: "",
          password_confirmation: "",
      },
    };
  },
  methods: {

    resetPassword() {
        this.form.token = this.$route.params.token;

        //post change
        axios
        .post(process.env.VUE_APP_API_URL + "/password/reset", this.form)
        .then((response) => {
            this.reset_status = response.data.success.message;
            this.successAlert("Success", response.data.success.message);
            setTimeout(
                this.$router.push('/login'),
                2000
            )
        })
        .catch((error) => { 
            this.errorAlert("Error", error.response.data.error.message);
            console.log(error);
        });

    }
  }
};
</script>
<style></style>
