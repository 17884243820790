<template>
  <v-container>

    <v-card class="mb-6 projectsPurple mt-0 dashboardCard">
       <div class="rightBars rightGrayBars rightGrayBarsCard"></div>
        <v-row justify="space-between">
          <v-col lg="4" class="pa-5">
            <div style="float:left">
              <v-avatar fab class="white pa-1  mr-5" >
              <v-icon>mdi-store-settings</v-icon>
              </v-avatar> 
              <p style="color:black; float: right" class="description mt-4">Custom-built stores</p>
            </div>
          </v-col>
          <v-col class="pa-5 my-auto" justify="end" lg="4">
            <!-- <v-btn to="/dashboard/organisations/create" color="secondary"  style="float: right">
              Create Organisation
            </v-btn> -->
          </v-col>
        </v-row>
    </v-card>
    
    <v-card class="dashboardCard ">
      <v-card-title>
        All custom-built stores on the platform
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="organisations"
        :search="search"
      >
      <template v-slot:item.glutenfree="{ item }">
               <v-switch 
                color="primary"
                value
                :input-value="organistionStatus(item)"
                 @click="changeOrganisationStatus(item)"
              ></v-switch>
      </template>

        <template v-slot:item.actions="{ item }">
        

          <router-link style="text-decoration:none" :to="`/dashboard/organisations/${item.slug}/transactions`">
            <v-icon
              small
              @click="item"
              class="mx-2"
            >
              mdi-receipt-text-outline
            </v-icon>
          </router-link>

          <router-link style="text-decoration:none" :to="`/dashboard/organisations/${item.slug}`">
            <v-icon
              small
              @click="item"
              class="mx-2"
            >
              mdi-eye
            </v-icon>
          </router-link>

          <router-link style="text-decoration:none" :to="`/dashboard/organisations/${item.slug}/edit`">
            <v-icon
              small
              @click="item"
              class="mx-2"
            >
              mdi-pencil
            </v-icon>
          </router-link>

          <v-dialog
            v-model="dialog"
            persistent
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
               <v-icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  class="mx-2"
               >
                  mdi-delete
               </v-icon>
            </template>

            <v-card>
              <v-alert type="error"> </v-alert>

              <v-card-title class="text-h6">
                Delete {{item.name}}
              </v-card-title>

              <v-card-text> 
                Are you sure you want to Proceed with deleting?.
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="dialog = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="red darken-1"
                  text
                  @click="deleteOrganisation(item)"
                >
                  Delete
                </v-btn>

              </v-card-actions>
            </v-card>
          </v-dialog>
      </template>

      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import SuccessAlert from '../../../mixins/successAlert'
import ErrorAlert from '../../../mixins/errorAlert'

 export default {
      mixins:[SuccessAlert,ErrorAlert],
      name:'organisations',
      
      data () {
        return {
          dialog:false,
          edit_dialog:false,
          switch1: true,
          switch2: false,
          organisations:[],
          status:null,
          search: '',
          calories: '',

          organisation:{
            name:"",
            address_line1:"",
            address_line2:"",
            province:"",
            country:"",
            details:"",
            postal_code:"",
            email:"",
            phone:"",
            creator_id:"",
            tax_number:"",  
          }
        }
      },

      computed: {
        headers () {
          return [
          {
            text: 'Name',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          { text: 'Country',  value: 'country' },
          { text: 'Email',sortable: false, value: 'email' },
          { text: 'Phone',sortable: false, value: 'phone' },
          { text: 'Tax number',sortable: false, value: 'tax_number' },
          { text: 'Active', value: 'glutenfree' },
          { text: 'Actions', value: 'actions', sortable: false },
          ]
        },
      },

      mounted: function() {
        this.getOrganisations();
      },

      methods: {
        getOrganisations() {
          axios
          .get(process.env.VUE_APP_API_URL + '/organisations/')
          .then((response) => {
            this.organisations = response.data.organisations;
          })
        },

        setDefaultValues(organisation){
          this.organisation=organisation;
        },

        deleteOrganisation(organisation) {
        this.dialog = false;
        axios
          .delete(process.env.VUE_APP_API_URL + '/organisations/'+ organisation.slug)
          .then(() => {
            this.successAlert("Organization Deleted", "successfully"); //this is a mixin function successAlert($title,$message)
            setTimeout(() => {location.reload();}, 4000);
          })

          .catch((error) => {
            this.errorAlert("Deactivation failed.",Object.values(error.response.data.errors).join(' '));
          });
         },

        updateOrganisation (organisation){
          axios
            .put(process.env.VUE_APP_API_URL + "/organisations/"+organisation.slug,this.organisation)
            .then(() => {
              this.successAlert("Organisation updated", "successfully"); //this is a mixin function successAlert($title,$message)
              setTimeout(() => {location.reload();}, 4000);
            })
            
            .catch((error) => {
              this.errorAlert("Failed to update organisation.",Object.values(error.response.data.errors).join(' '));  //this is a mixin function errorAlert($message,$error)
            });
        },

        filterOnlyCapsText (value, search) {
          return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().toLocaleUpperCase().indexOf(search) !== -1
        },

        organistionStatus(organisation){
          if (organisation.is_active==1){return true}
          return false;
        },

        changeOrganisationStatus(organisation){
          axios
          .put(process.env.VUE_APP_API_URL + '/organisations/' + organisation.slug + '/status')
          .then((response) => {
            this.successAlert("Organisation status updated",response.data.success); //this is a mixin function successAlert($title,$message)
          })
          .catch((error) => {
            this.errorAlert("Failed to update organisation status.",Object.values(error.response.data.errors).join(' '));
            setTimeout(
              () => {this.$router.go()},//reloads the page with correct data
              3000
            )
          });
        },
      },
  }

</script>
