<template>
  <div style="min-height: 50vh;">
    <h1 class="text-center mt-15 pt-15">This is not the page you are looking for</h1>
  </div>
</template>

<script>

export default {
    name:'404'
}

</script>
