<template>
    <div>
        <v-card  class="my-6 dashboardCard">
            <v-card-title>
              Users
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="users"
              :search="search"
            >
              <template v-slot:item.active="{ item }">
                     <v-switch 
                      color="primary"
                      value
                      :input-value="userStatus(item)"
                       @click="changeUserStatus(item)"
                    ></v-switch>
              </template>

              <template v-slot:item.roles="{ item }">
                {{
                  item.roles.map(role => role.name).join(', ')
                }}
              </template>

              <template v-slot:item.actions="{ item }">
                 <router-link style="text-decoration:none" :to="`/dashboard/users/` + item.slug">
                    <v-icon
                      small
                        class="mx-2"
                      @click="item"
                    >
                       mdi-eye
                    </v-icon>
                </router-link>
                
                <router-link style="text-decoration:none" :to="`/dashboard/users/` + item.slug">
                    <v-icon
                      small
                        class="mx-2"
                      @click="item"
                    >
                       mdi-pencil
                    </v-icon>
                </router-link>

              </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script type="text/javascript">
import axios from "axios";
    
export default {
    name: "UsersTable",
    props: [
        'users'
    ],
    data () {
      return {
        search: '',
      }
    },

    computed: {
      headers () {
        return [
        {
          text: 'First Name',
          align: 'start',
          sortable: true,
          value: 'first_name',
        },

        {
          text: 'Last Name',
          align: 'start',
          sortable: true,
          value: 'last_name',
        },
        
        { text: 'Email',sortable: false, value: 'email' },
        { text: 'Roles', sortable: false, value: 'roles' },
        { text: 'Phone',  value: 'phone' },
        { text: 'Active', value: 'active' },
        { text: 'Actions', value: 'actions' },
        ]
      },
    },

    methods: {


        userStatus(user){
          if (user.is_active==1){return true}
          return false;
        },

        changeUserStatus(user){
          axios
          .put(process.env.VUE_APP_API_URL + '/users/' + user.slug + '/status')
          .then((response) => {
            this.successAlert("User status updated",response.data.success); //this is a mixin function successAlert($title,$message)
          })
          .catch((error) => {
            this.errorAlert("Failed to update user status.",Object.values(error.response.data.errors).join(' '));
            setTimeout(
              () => {this.$router.go()},//reloads the page with correct data
              3000
            )
          });
        },
    }
}

</script>