<template>
  <v-container>    
    <v-row class="mx-md-10 my-md-10 mt-md-15 pa-10">
        <v-col cols="12">
            <h1 class="h3 display-1">Log in</h1>
            <p class="smallBody my-5">Welcome back please enter your details.</p>
        </v-col>
        <v-col cols="12" class="text-left">
                <v-form @submit.prevent="login()"  ref="form" v-model="valid">
                    <label>Email</label>
                        <v-text-field
                        v-model="form.email"
                        :rules="rules.email"
                        name="email"
                        class="mx-auto rounded-0"
                        dense
                        required
                        outlined
                        >
                            
                        </v-text-field>

                    <label>Password</label>
                    <v-text-field
                        v-model="form.password"
                        :rules="rules.password"
                        name="password"
                        class="mx-auto rounded-0"
                        dense
                        :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show_password  ? 'text' : 'password'"
                        required
                        outlined
                        @click:append="show_password = !show_password"
                    ></v-text-field>

                    <p v-if="err" class="red--text text-center">
                        Invalid login details.
                    </p>
                </v-form>
        </v-col>
    
        <v-col cols="12" class="py-0 my-0">
            <v-row>
                <v-col cols="12" md="6" class="my-n4">
                    <v-checkbox class="my-0" label="Remember me" v-model="remember_me">
                    </v-checkbox>
                </v-col>

                <v-col cols="12" md="6" class="text-md-right pt-md-0">
                    <router-link to="/forgot-password">
                        Forgot password?
                    </router-link>
                </v-col>
            </v-row>
        </v-col>

        <v-col cols="12">
            <v-btn              
                class="black white--text rounded-0 my-md-5"
                width="100%"
                depressed
                @click=login()
            >
                Sign In
            </v-btn>
        </v-col>
        

        <!-- <v-col cols="12">
            <v-btn              
                class=" rounded-0"
                width="100%"
                depressed
                @click=login()
            >
                Sign In with Google
            </v-btn>
        </v-col> -->

        <v-col
            cols="12" 
            class="text-center px-0"  
        >
            <p>Don't have an account? <span><router-link to="/register">Sign up</router-link></span></p>
        </v-col>
    </v-row>
  </v-container>
</template>


<script>
import SuccessAlert from '@/mixins/successAlert';
import ErrorAlert from '@/mixins/errorAlert';

export default {
    name:'Login',
    
    components: {
    },

    mixins:[SuccessAlert,ErrorAlert],

    data() {
        return {
            valid: false,
            remember_me: false,
            show_password:false,
            err: "",
            form: {
                email: "",
                password: "",
            },

            rules: {
                email: [val => (val || '').length > 0 || 'This field is required'],
                password: [val => (val || '').length > 0 || 'This field is required'],
            },
        }
    },    

    methods: {
        login() {

            if(!this.$refs.form.validate()) {
                //don't post
                return ;
            }

            this.$store
                .dispatch("login", this.form)
                .then(() => {
                    this.$router.push('/dashboard');
                })
                .catch((err) => {
                    this.err = err;
                });
        },
        clear() {
            this.form.password = ''
            this.form.email = ''
        },
    }

}

</script>
