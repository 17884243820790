<template>
 <div>
<v-container fluid class="home-hero-image">
  <v-container>
      <v-row
      align="center"
      class="mt-10 mb-6 ml-6"
      justify="left"
      >
      <v-col
        cols="12"
      >
        <p class="subheading">DATA</p>
        <h2 class="mb-4">
          Privacy policy
        </h2>
        <p>Click <a style="color:#3D3D3D !important;" href="/curbon-popi-and-privacy.pdf" target="_blank">here to download</a> our privacy policy or read it below.</p>
      </v-col>
    </v-row>
  </v-container>
</v-container>

<v-container fluid >
  <v-container>
    <v-row class="mt-10 mb-6 ml-6">
      <v-col cols="12">
    <div class="text-center">
      <h4>POPI Act Schedule</h4>
    </div>
    <h5 class='mt-5'>INTRODUCTION</h5>
    <ol>
      <li>For the purposes of this schedule “User”
      shall mean Customer, End User, Administrator, and/or End User as
      indicated from the context.
      <li>
      CURBON TRADING respects Customer Privacy
      and will take reasonable measures to protect it, as more fully
      detailed below.
      <li>
      CURBON TRADING may require the User to
      provide CURBON TRADING with personal information which includes but
      is not limited to company names and registration numbers, name and
      surname, email address, physical address, gender, mobile number and
      date of birth.
      <li>
      Should the User’s personal information
      change, please inform CURBON TRADING and provide CURBON TRADING with
      the updates within 7 days to enable CURBON TRADING to update the
      User’s personal information.
      <li>
      Users may choose to provide additional
      personal information to CURBON TRADING, in which event the Users to
      provide accurate and current information, and not to impersonate or
      misrepresent any person or entity or falsely state or otherwise misrepresent the User’s
      affiliation with anyone or anything.
      <li>
      CURBON TRADING asks each of its
      Customers to abide by all applicable laws, rules and regulations, as
      CURBON TRADING promises its Users that CURBON TRADING will do. 
      </li>
    </ol>
    <h5 class='mt-5'>CONSENT</h5>
    <ol style="counter-reset: top-level-counter 7;">
      <li>
      By using CURBON
      TRADING and its Website and/or CURBON TRADING PLUGIN, the User
      consents to the transfer of the User personal data. Subject to
      approval received from the User and the below-mentioned items,
      CURBON TRADING will not, without the User’s express consent, use
      the User’s personal information for any purpose other than as set
      out below:
      </li>
      <li>
      CURBON TRADING uses that information for
      several purposes: to register the User’s for inclusion in our
      Service; to fulfil the User’s request; to contact Users if CURBON
      TRADING need to obtain or provide additional information; to verify
      the accuracy of our records; or to contact Users regarding customer
      satisfaction surveys;
      <ol>
        <li>
        in relation to the ordering, sale and
        delivery of Products and Services;
        <li>
        to contact Users regarding current or
        new Products or Services or any other Products offered by CURBON
        TRADING (where Users have opted in and consented to receiving
        marketing material from CURBON TRADING);
        <li>
        to inform Users of new features,
        special offers and promotional competitions offered by CURBON
        TRADING (where Users have opted in and consented to receiving
        marketing material from CURBON TRADING);
        <li>
        to improve our Products, Services and
        Users selection and the User’s experience on our Website and/or
        CURBON TRADING PLUGIN by, for example, monitoring the User’s
        browsing habits, or tracking the User’s sales on the Website
        and/or CURBON TRADING PLUGIN, and, CURBON TRADING undertakes to
        ensure that all monitoring shall be on an anonymized basis;
        </li>
        <li>
        collect certain technical information,
        such as the browser version and operating system.
        </li>
      </ol>
      </li>
      <li>
      Where applicable, to store and or create
      a back up of the User’s information and data in multiple
      jurisdictions and to a degree of safety and security utilized by
      CURBON TRADING itself in order to safeguard the User’s information
      and Data.
      <ol>
        <li>
        This consent shall include moving any
        EU Citizen’s information and data to and from any EU jurisdiction
        in order to provide such optimal safety features.  
        </li>
      </ol>
      </li>
      <li>
      A User’s personal information will
      only be shared to a third party:
      </li>
        <ol>
          <li>
          to our employees and/or third-party
          Service providers who assist CURBON TRADING to interact with
          Customer via our Website and/or CURBON TRADING PLUGIN, or any other
          method, for the ordering and support<!-- this was added --><!-- 100% -->
          of Products or Services and thus need to know the User’s personal
          information in order to assist CURBON TRADING to communicate with
          Users properly and efficiently;
          <li>
          to our Users (including their employees
          and/or third party Service providers) in order for them to interact
          directly with Users via email or any other method for purposes of
          delivering additional services and/or products, sending Users
          marketing material regarding any current or new Products or
          Services, new features, special offers or promotional items offered
          by them (unless Users have opted out from receiving marketing
          material from CURBON TRADING);
          <li>
          to law enforcement, government
          officials, fraud detection agencies or other third parties when
          CURBON TRADING believe in good faith that the disclosure of
          personal information is necessary to prevent physical harm or
          financial loss, to report suspected illegal activity, or to
          investigate violations of the Privacy Policy;
          <li>
          to our Service providers (under
          contract with CURBON TRADING) who help with parts of our business
          operations (fraud prevention, marketing, technology Services etc).
          However, our contracts dictate that these Service providers may
          only use the User’s information in connection with the Services
          they perform for CURBON TRADING and not for their own benefit,
          CURBON TRADING commits that where at all possible the personal
          information will be anonymised or redacted;<!-- This was added --><!-- 100% -->
          <li>
          to our suppliers in order for them to
          liaise directly with Users in the event of Users submitting a
          warranty claim regarding any Products or Services Users have
          purchased which requires their involvement. From time to time
          CURBON TRADING may also administer random “interactive pop-up”
          surveys to some Users on their sites. These surveys request
          additional demographic, non-personally identifiable information.
          The Users’ participation in a survey is always voluntary, and
          Users therefore have a choice as to whether or not to provide the
          requested survey information. CURBON TRADING uses the information
          collected in the survey, in conjunction with information gathered
          through cookies, analytical tools<!-- this was added --><!-- 100% -->
          and code, in order to provide Users with an even more detailed
          understanding of its target market.
          <li>
          Some voluntarily provided personally
          identifiable information may be captured; for example, when an
          email address or telephone number is incorporated into a survey for
          the potential awarding of a prize draw, or when the respondent has
          opted-in to possible follow up research. This information may be
          shared with entities or other Users who in turn have agreed to
          adhere to this Privacy Policy.
          <li>
          CURBON TRADING is entitled to use or
          disclose the User’s personal information if such use or
          disclosure is required in order to comply with any applicable law,
          subpoena, order of court or legal process served on CURBON TRADING,
          or to protect and defend our rights or property.
          </li>
          <li>CURBON TRADING is authorised to use the
          personal data collected under this policy to maintain, improve,
          test, research, and design new systems for the benefit of its
          customers. </li>
          <li>
          CURBON TRADING will ensure that all of
          our employees, third party Service providers, divisions and
          partners (including their employees and third-party Service
          providers) having access to the User’s personal information are
          bound by appropriate and legally binding confidentiality
          obligations in relation to the User’s personal information.
          </li>
        </ol>
    </ol>
    <h5 class='mt-5'>PERSONAL INFORMATION</h5>
    <ol style="counter-reset: top-level-counter 11;">
      <li>
      CURBON TRADING
      will –
      <ol>
        <li>
        treat the User’s personal information
        as strictly confidential, save where CURBON TRADING is entitled to
        share it as set out in this Policy;
        <li>
        take appropriate technical and
        organisational measures to ensure that the User’s personal
        information is kept secure and is protected against unauthorised or
        unlawful processing, accidental loss, destruction or damage,
        alteration, disclosure or access;
        <li>
        provide Users with access to the User’s
        personal information to view and/or update personal details;
        <li>
        promptly notify Users if CURBON TRADING
        become aware of any unauthorised use, disclosure or processing of
        the User’s personal information;
        <li>
        provide Users with reasonable evidence
        of our compliance with our obligations under this Policy on
        reasonable notice and request;
        </li>
        <li>
        upon the User’s request, promptly
        return or destroy any and all of the User’s personal information
        in our possession or control, save for that which CURBON TRADING
        are legally obliged to retain;
        </li>
      </ol>
      <li>
      CURBON TRADING will not retain the
      User’s personal information longer than the period for which it
      was originally needed, unless CURBON TRADING is required by law to
      do so or in line with requirements set out by the Financial Sector
      Conduct Authority (“FSCA”) and/or the Financial Advisory and
      Intermediary Services Act 37 of 2002 (“FAIS”), which additional
      requirements shall be conveyed timeously by the Customer to CURBON
      TRADING and the Customer shall assist CURBON TRADING to comply with
      such additional requirements where requested to do so; or Users
      consent to CURBON TRADING retaining such information for a longer
      period;
      <li>
      CURBON TRADING undertakes never to sell
      or make the User’s personal information available to any third
      party other than as provided for in this Policy, unless CURBON
      TRADING is compelled to do so by law. In particular, in the event of
      a fraudulent online payment, CURBON TRADING is entitled to disclose
      relevant personal information for criminal investigation purposes or
      in line with any other legal obligation for disclosure of the
      personal information which may be required of it;
      <li>
      Whilst CURBON TRADING will do all things
      reasonably necessary to protect the User’s rights of Privacy,
      CURBON TRADING cannot guarantee or accept any liability whatsoever
      for unauthorised or unlawful disclosures of the User’s personal
      information, whilst in our possession, made by third parties who are
      not subject to our control, unless such disclosure is as a result of
      our gross negligence;
      <li>
      If Users disclose the User’s personal
      information to a third party, such as an entity which operates a
      Website and/or CURBON TRADING PLUGIN linked to this Website and/or
      CURBON TRADING PLUGIN or anyone other than CURBON TRADING, CURBON
      TRADING WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE, HOWSOEVER
      ARISING, SUFFERED BY YOU AS A RESULT OF THE DISCLOSURE OF SUCH
      INFORMATION TO THE THIRD PARTY. This is because CURBON TRADING does
      not regulate or control how that third party uses the User’s
      personal information. The User should always ensure that it reads
      the Privacy Policy of any third party;<!-- This feels a bit like we're not going to do any due diligence on the parties we partner with to make sure that they are going to respect the personal information that we hand over. Could this be rewritten to indicate that we will do what we can to make sure that their personal information is used carefully, but can't guarantee that the third party will adhere to it. Also we'd need to let people supplying their personal information know who we're giving their information to in order for them to read their privacy policies? --><!-- While I understand your point of view - it is a concern to NOT limit your liability in this instance. It’s a legal risk --><!-- Ok no problem, let's keep it as is -->T
      <li>
      Retention Period. CURBON TRADING will
      retain the User’s personal information for the period necessary to
      fulfill the purposes outlined in this Privacy Policy unless a longer
      retention period is required or allowed by law;
      <li>
      Cross-Border Transfer for Users: The
      personal information from Users may be stored and processed in any
      country where CURBON TRADING has facilities or where the Products
      from its Users originate, and by using the User’s consent to the
      transfer of information to countries outside of the User’s country
      of residence, which have different data protection rules than in the
      User’s country;
      <li>
      Children and Privacy. The CURBON TRADING
      Website and/or CURBON TRADING PLUGIN does not offer information
      intended to attract children. CURBON TRADING does not knowingly
      solicit personal information from children under the age of 14;
      <li>
      Your right to access information. Th End
      User will have the right to request a copy of the personal
      information we hold about the User and/or User. To do so, simply
      email CURBON TRADING on our contact details below and send a request
      to review the personal information or contact us at the email
      addresses listed below and specify what information is required<!-- Can we keep it to just email? --><!-- Technically yes --><!-- Updated accordingly -->.
      CURBON TRADING will take all reasonable steps to confirm the User
      and/or User’s identity before providing details of its personal
      information. Please note that any such access request may be subject
      to a payment of a legally allowable fee;
      <li>
      Correction of information. The User
      and/or User has the right to ask CURBON TRADING to update, correct
      or delete its personal information. The User and/or User may do this
      by contacting CURBON TRADING at the numbers/addresses provided
      below. CURBON TRADING will take all reasonable steps to confirm the
      of the User and/or User’s identity before making changes to
      personal information we may hold about it;
      </li>
      <li>
      CURBON TRADING’s POPI contacts. If the
      User and/or User have questions about this Policy, believe CURBON
      TRADING has not adhered to it, need further information about our
      privacy practices, wish to give or withdraw consent, exercise
      preferences, access or correct personal information, please contact
      CURBON TRADING at the following numbers/addresses: Email: <a  href="mailto:support@curbon.io">support@curbon.io</a>
      </li>
    </ol>
    <h5 class='mt-5'>
    SECURITY</h5>
    <ol style="counter-reset: top-level-counter 22;">
      <li>
      CURBON TRADING uses reasonable
      organisational, technical and administrative measures to protect
      personal information under its control. Unfortunately, no data
      transmission over the Internet or data storage system can be
      guaranteed to be 100% secure.
      </li>
      <li>
      If Users have reason to believe that the
      User’s interaction with CURBON TRADING is no longer secure (for
      example, if Users feel that the security of any account Users might
      have with CURBON TRADING has been compromised), please immediately
      notify CURBON TRADING of the problem by contacting CURBON TRADING in
      accordance with the “Contact Us” section.
      </li>
    </ol>
    <h5 class='mt-5'>
    COOKIES</h5>
    <ol style="counter-reset: top-level-counter 24;">
      <li>
      This Website and/or CURBON TRADING
      PLUGIN makes use of “cookies” to automatically collect
      information and data through the standard operation of the Internet
      servers. “Cookies” are small text files a Website and/or CURBON
      TRADING PLUGIN can use (and which CURBON TRADING may use) to
      recognise repeat Users, facilitate the User’s on-going access to
      and use of a Website and/or CURBON TRADING PLUGIN and allow a
      Website and/or CURBON TRADING PLUGIN to track usage behaviour and
      compile aggregate data that will allow the Website and/or CURBON
      TRADING PLUGIN operator to improve the functionality of the Website
      and/or CURBON TRADING PLUGIN and its content.
      <li>
      The type of information collected by
      cookies is not used to personally identify Users. CURBON TRADING
      only makes use of essential information through the use of cookies.
      If Users do not want information collected through the use of
      cookies, there is a simple procedure in most browsers that allows
      Users to deny or accept the cookie feature.
      </li>
      <li>
      Please note that cookies may be
      necessary to provide Users with certain features available on our
      Website and/or CURBON TRADING PLUGIN, and thus if Users disable the
      cookies on the User’s browser Users may not be able to use those
      features, and the User’s access to our Website and/or CURBON
      TRADING PLUGIN will therefore be limited. If Users do not disable
      “cookies”, Users are deemed to consent to our use of any
      personal information collected using those cookies.
      </li>
    </ol>
    <h5 class='mt-5'>
    CHANGES TO THE PRIVACY POLICY</h5>
    <ol style="counter-reset: top-level-counter 27;">
      <li>
      CURBON TRADING will at all times provide
      updated communications to any changes to this privacy policy and it
      may, in its sole discretion, change any of the terms and conditions
      of this Privacy Policy at any time. It is the User’s
      responsibility to regularly check the Privacy Policy and make sure
      that Users are satisfied with the changes. Should a User not be
      satisfied, Users must not place any further orders on, or in any
      other way use, the Website and/or CURBON TRADING PLUGIN.</li>
      <li>
      Any such change will only apply to the
      User’s use of this Website and/or CURBON TRADING PLUGIN after the
      change is displayed on the Website and/or CURBON TRADING PLUGIN. If
      Users use the Website and/or CURBON TRADING PLUGIN after such
      amended Privacy Policy, Users will be deemed to have accepted such
      changes.</li>
    </ol>
    <h5 class='mt-5'>
    ELECTRONIC COMMUNICATIONS</h5>
    <ol style="counter-reset: top-level-counter 29;">
      <li>
      When Users visit the Website and/or
      CURBON TRADING PLUGIN or send emails to CURBON TRADING, Users
      consent to receiving communications from CURBON TRADING or any of
      our Users electronically in accordance with our Privacy Policy.</li>
    </ol>
    <h5 class='mt-5'>
    DISCLAIMER</h5>
    <ol style="counter-reset: top-level-counter 30;">
      <li>
      The use of the Website and/or CURBON
      TRADING PLUGIN is entirely at the User’s own risk and Users assume
      full responsibility for any risk or loss resulting from use of the
      Website and/or CURBON TRADING PLUGIN or reliance on any information
      on the Website and/or CURBON TRADING PLUGIN.
      <li>
      Whilst CURBON TRADING takes reasonable
      measures to ensure that the content of the Website and/or CURBON
      TRADING PLUGIN is accurate and complete, CURBON TRADING makes no
      representations or warranties, whether express or implied, as to the
      quality, timeliness, operation, integrity, availability or
      functionality of the Website and/or CURBON TRADING PLUGIN or as to
      the accuracy, completeness or reliability of any information on the
      Website and/or CURBON TRADING PLUGIN. If any such representations or
      warranties are made by CURBON TRADING’s representatives, CURBON
      TRADING will not be bound thereby.</li>
      <li>
      CURBON TRADING disclaims liability for
      any damage, loss or expenses, whether direct, indirect or
      consequential in nature, arising out of or in connection with the
      User’s access to or use of the Website and/or CURBON TRADING
      PLUGIN and/or any content therein unless otherwise provided by
      law.<br/>
    Although Products sold
      from the Website and/or CURBON TRADING PLUGIN by Users may, under
      certain specifically defined circumstances, be under warranty, the
      Website and/or CURBON TRADING PLUGIN itself and all information
      provided on the Website and/or CURBON TRADING PLUGIN is provided “as
      is” without warranty of any kind, either express or implied,
      including, but not limited to, the implied warranties of
      merchantability, fitness for a particular purpose, completeness, or
      non-infringement, as may be allowed in law.
      <li>
      Any views or statements made or
      expressed on the Website and/or CURBON TRADING PLUGIN are not
      necessarily the views of CURBON TRADING, its directors, employees
      and/or agents.</li>
      <li>
      In addition to the disclaimers contained
      elsewhere on the Website and/or CURBON TRADING PLUGIN or in this
      Privacy Policy, CURBON TRADING also makes no warranty or
      representation, whether express or implied, that the information or
      files available on the Website and/or CURBON TRADING PLUGIN are free
      of viruses, spyware, malware, trojans, destructive materials or any
      other data or code which is able to corrupt, destroy, compromise,
      disrupt, disable, harm, jeopardise or otherwise impede in any manner
      the operation, stability, security functionality or content of the
      User’s computer system, computer network, hardware or software in
      any way. The Users accept all risk associated with the existence of
      such viruses, destructive materials or any other data or code which
      is able to corrupt, compromise, jeopardise, disrupt, disable, harm
      or otherwise impede in any manner the operation or content of a
      computer system, computer network, any handset or mobile device, or
      the User’s hardware or software, save where such risks arise due
      to the gross negligence or wilful misconduct of CURBON TRADING, its
      employees, agents or authorised representatives. CURBON TRADING thus
      disclaims all liability for any damage, loss or liability of any
      nature whatsoever arising out of or in connection with the User’s
      access to or use of the Website and/or CURBON TRADING PLUGIN.</li>
    </ol>
    <h5 class='mt-5'>
    AVAILABILITY AND TERMINATION</h5>
    <ol style="counter-reset: top-level-counter 35;">
      <li>
      CURBON TRADING will use reasonable
      endeavors to maintain the availability of the Website and/or CURBON
      TRADING PLUGIN, except during scheduled maintenance periods, and are
      entitled to discontinue providing the Website and/or CURBON TRADING
      PLUGIN or any part thereof with or without notice to Users.</li>
      <li>
      CURBON TRADING may in its sole
      discretion suspend and modify this Website and/or CURBON TRADING
      PLUGIN, with or without notice to Users. You agree that CURBON
      TRADING will not be liable to Users in the event that it chooses to
      suspend, modify or terminate this Website and/or CURBON TRADING
      PLUGIN other than for processing any orders made by Users prior to
      such time, to the extent possible.</li>
      <li>
      If Users fail to comply with the User’s
      obligations under this Privacy Policy, including any incident
      involving payment of the price of an order for any Products from
      other Users, this may (in our sole discretion with or without notice
      to Users) lead to a suspension and/or termination of the User’s
      access to the Website and/or CURBON TRADING PLUGIN without any
      prejudice to any claims for damages or otherwise that CURBON TRADING
      or the other User may have against Users.</li>
      <li>
      CURBON TRADING is entitled, for purposes
      of preventing suspected fraud and/or where it suspects that Users
      are abusing the Website and/or CURBON TRADING PLUGIN and/or have
      created multiple User profiles to take advantage, to blacklist Users
      on its database (including suspending or terminating the User’s
      access to the Website and/or CURBON TRADING PLUGIN), refuse to
      accept or process payment on any order, and/or to cancel any order
      concluded between Users and CURBON TRADING, in whole or in part, on
      notice to Users and accepts no liability which may arise as a result
      of such blacklisting and/or refusal to process any order for the
      CURBON TRADING Services or through a User.
      <li>
      In order to maintain availability of the
      Services, CURBON TRADING undertakes to such security and cloud-based
      strategies to provide the maximum security and safety for the
      Customer and End User's Data. CURBON TRADING may adopt such
      additional and/or other Disaster Recovery features that it deems
      necessary to protect Customer and End User Data.</li>
      <li>
      CURBON TRADING undertakes to notify the
      Customer of any security or disaster recovery techniques and
      mechanisms deployed for its benefit within a reasonable time.</li>
    </ol>
    <h5 class='mt-5'>
    GOVERNING LAW AND JURISDICTION</h5>
    <ol style="counter-reset: top-level-counter 41;">
      <li>
      This Privacy Policy and our relationship
      and/or any dispute arising from or in connection with the Privacy
      Policy will be governed and interpreted in accordance with the laws
      of the Republic of South Africa.</li>
      <li>
      Your continued use of the Website and/or
      CURBON TRADING PLUGIN will constitute the User’s consent and
      submission to the jurisdiction of the South African courts regarding
      all proceedings, transactions, applications or the like instituted
      by either party against the other, arising from any of the Privacy
      Policy.</li>
      <li>
      In the event of any dispute arising
      between Users and CURBON TRADING, Users hereby consent to the
      non-exclusive jurisdiction of the High Court of the Republic of
      South Africa (Gauteng Division, Johannesburg) notwithstanding that
      the quantum in the action or proceedings may otherwise fall below
      the monetary jurisdiction of that court.</li>
      <li>
      Nothing in this clause or the Privacy
      Policy limits the User’s right to approach any court, tribunal or
      forum of competent jurisdiction in terms of the POPI or CPA.</li>
    </ol>
    <h5 class='mt-5'>
    NOTICES</h5>
    <ol style="counter-reset: top-level-counter 45;">
      <li>
      CURBON TRADING hereby selects the
      address as it reflects on its Website and/or CURBON TRADING PLUGIN
      from time to time, as its address for the Service of all formal
      notices and legal processes in connection with the Privacy Policy
      (“legal address”). CURBON TRADING may change this address from
      time to time by updating the Privacy Policy.
      <li>
      You hereby select the physical address
      specified with the User’s invoice as the User’s legal address,
      but Users may change it to any other physical address by giving
      CURBON TRADING not less than 7 days’ notice in writing.</li>
      <li>
      Notices must be sent either by hand,
      prepaid registered post or email. All notices sent –</li>
      <ol>
        <li>
        by courier will be deemed to have been received on the date of delivery;</li>
        <li>
        by email before 16h30 on a business day
        will be deemed to have been received, on the date of successful
        transmission of the email. Emails sent after 16h30 or on a day
        which is not a business day will be deemed to have been received on
        the following business day.</li>
      </ol>
    </ol>
    <h5 class='mt-5'>
    GENERAL</h5>
    <ol style="counter-reset: top-level-counter 48;">
      <li>
      Any failure on the part of Users or
      CURBON TRADING to enforce any right in terms hereof will not
      constitute a waiver of that right.
      <li>
      If any term or condition contained
      herein is declared invalid, the remaining terms and conditions will
      remain in full force and effect.
      <li>
      No indulgence, extension of time,
      relaxation or latitude which any party (the “grantor”) may show
      grant or allow to the other (the “grantee”) will constitute a
      waiver by the grantor of any of the grantor’s rights and the
      grantor will not thereby be prejudiced or stopped from exercising
      any of its rights against the grantee which may have arisen in the
      past or which might arise in the future.</li>
      <li>
      No other warranty or undertaking is
      valid, unless contained in this document between the parties.</li>
    </ol>
      </v-col>
    </v-row>
  </v-container>
</v-container>

<v-container fluid class="charcoal">
    <v-container>
        <ReadyToGetStarted></ReadyToGetStarted>
    </v-container>
</v-container>

</div>
</template>

<script>
import ReadyToGetStarted from '../components/ReadyToGetStarted.vue'
export default {
    name:'PrivacyPolicy',
    components:{
         ReadyToGetStarted,
    },

    data () {
      return {
        //
      }
    },

    methods: {
      // Create an array the length of our items
      // with all values as true
      all () {
        this.panel = [...Array(this.items).keys()].map((k, i) => i)
      },
      // Reset the panel
      none () {
        this.panel = []
      },
    },
}

</script>


<style type="text/css">
    ol {
      counter-reset: item;
      padding-top: 5px;
    }
    li {
      display: block;
      padding-top: 5px;
    }
    li:before {
      content: counters(item, ".") ". " !important; 
      counter-increment: item
    }
</style>