<template>
<v-app id="inspire">
   <v-app-bar
    class=" cobalite"
    flat
    short
    app
  >
      <v-app-bar-nav-icon class="hidden-lg-and-up" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-toolbar-items>
      
        <v-tab text router :to="'/dashboard/users/' + user.slug">
          <v-icon class="mr-2">mdi-account</v-icon>
          {{user.first_name}} {{user.last_name}}
        </v-tab>

        <v-tab text @click="logout()">
          <v-icon class="mr-2">mdi-logout-variant</v-icon>
          Logout
        </v-tab>

      </v-toolbar-items>
   </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      app
      elevation="10" 
    >
    <v-sheet
      class=" cobalite pb-2 pt-3"
      color="cyan"
    >
     <v-btn plain text router to="/">
          <v-img max-width="150" 
            src="/images/curbon/Curbon_logo_black.svg">
          </v-img>
        </v-btn>
    </v-sheet>
    

      <v-divider></v-divider>

      <v-tabs vertical class="my-0" color="black" background-color="rgba(255, 255, 255, 0)">

          <v-tab v-if="user.ability.cannot('view','any_organisation') && user.ability.cannot('view','any_user')" style=" justify-content: left !important;"  router to="/dashboard">
              <v-icon class="mr-5" color="#A09FFA">mdi-finance</v-icon>Dashboard
          </v-tab>

          <v-tab v-if="user.ability.can('view','any_organisation') && user.ability.can('view','any_user')" router to="/dashboard/admin" style=" justify-content: left !important;">
            <v-icon class="mr-5" color="#A09FFA">mdi-finance</v-icon>Curbon Dashboard
          </v-tab>

          <v-tab v-if="user.ability.can('view','any_lead')" style=" justify-content: left !important;" router to="/dashboard/leads">
            <v-icon class="mr-5">mdi-account-multiple-plus</v-icon>Leads
          </v-tab>

          <v-tab v-if="user.ability.can('view','any_user')" style=" justify-content: left !important;" router to="/dashboard/users">
            <v-icon class="mr-5">mdi-account-group</v-icon>Users
          </v-tab>

          <v-tab v-if="user.ability.can('view','any_organisation')" router to="/dashboard/organisations" style=" justify-content: left !important;">
            <v-icon class="mr-5">mdi-store-settings</v-icon>Custom-built stores
          </v-tab>

          <v-tab v-if="user.ability.can('view','any_article')" router to="/dashboard/articles" style=" justify-content: left !important;">
            <v-icon class="mr-5">mdi-post</v-icon>Articles
          </v-tab>


          <v-divider></v-divider>


          <v-tab  router :to="'/documentation'" style=" justify-content: left !important;">
            <v-icon class="mr-5">mdi-book</v-icon>API Documentation
          </v-tab>

          <v-tab  router :to="'/help-center'" style=" justify-content: left !important;">
            <v-icon class="mr-5">mdi-help</v-icon>Help Center
          </v-tab>


          <v-divider></v-divider>

          <v-tab  router :to="'/dashboard/users/' + user.slug" style=" justify-content: left !important;">
            <v-icon class="mr-5">mdi-account</v-icon>My profile
          </v-tab>

          <v-tab  @click="logout()" style=" justify-content: left !important;">
            <v-icon class="mr-5">mdi-logout-variant</v-icon>Logout
          </v-tab>
      </v-tabs>

      <v-footer
        bottom 
        absolute
        color="white"
        style="border-right:1px solid #E0E0E0 !important"
      >
        <small> &copy; Curbon {{ new Date().getFullYear() }}. <br>All Rights Reserved. </small>
      </v-footer>

    </v-navigation-drawer>

    <v-main class="pl-0 pt-0">
      <router-view />
    </v-main>
</v-app>
</template>

<script>

 export default {
    name:'Dashboard',
    data () {
      return {
        right: null,
        drawer: true,
        links:[
          {text:'Dashboard',route:'/dashboard', icon: 'mdi-view-dashboard' },
          {text:'All Users', route:'/dashboard/users',icon: 'mdi-account-group'},
          {text:'All Organisations', route:'/dashboard/organisations', icon: 'mdi-store-settings'},
          {text:'Articles', route:'/dashboard/articles', icon: 'mdi-post'},
          {text:'Documentation', route:'/dashboard/documentation', icon: 'mdi-file-document-multiple'},
        ]
      }
    },
    computed: {
      user: {
        get() {
          return this.$store.getters.getUser;
        }
      }
    },
    methods: {
      logout() {
        this.$store.dispatch("logout");
        this.$router.push('/');
      }
    },
  }

</script>
