<template>
  <v-container>
    <v-card class="mb-6 projectsPurple mt-0 dashboardCard">
       <div class="rightBars rightGrayBars rightGrayBarsCard"></div>
        <v-row justify="space-between">
          <v-col lg="4" class="pa-5">
            <div style="float:left">
              <v-avatar fab class="white pa-1 mr-5" >
              <v-icon>mdi-post</v-icon>
              </v-avatar> 
              <p style="color:black; float: right" class="description mt-4">Articles</p>
            </div>
          </v-col>
          <v-col class="pa-5 my-auto" justify="end" lg="4">
            <v-btn to="/dashboard/articles/create" color="secondary" style="float: right">
              Write an article
            </v-btn>
          </v-col>
        </v-row>
    </v-card>
    
    <v-card class="my-6 dashboardCard">
    <v-card-title>
      Articles
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="articles"
      :search="search"
    >
      <template v-slot:item.category_id="{ item }">
        <p v-for="category in categories" :key="category.id">
          <span v-if="item.category_id==category.id">{{category.name}}</span>
        </p>
      </template>

      <template v-slot:item.is_published="{ item }">
        <v-switch 
          color="primary"
          value
          :input-value="!!item.published_at"
          @click="togglePublish(item)"
        ></v-switch>
      </template>

      <template v-slot:item.published_at="{ item }">
        {{!!item.published_at ? item.published_at.substr(0,10) : ''}}
      </template>

    <template v-slot:item.actions="{ item }">
       <a style="text-decoration:none" :href="`/blogs/${item.slug}`">
          <v-icon
            small
            @click="item"
              class="mx-2"
          >
             mdi-eye
          </v-icon>
      </a>

       <a style="text-decoration:none" :href="`/dashboard/articles/${item.slug}/edit`">
          <v-icon
            small
            @click="item"
            class="mx-2"
          >
            mdi-pencil
          </v-icon>
        </a>

        <v-dialog
          content-class="elevation-0"
          v-model="item.delete_dialog"
          max-width="700"
        >
          <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mx-2"
                v-bind="attrs"
                v-on="on"
              >
                mdi-delete
              </v-icon>
          </template>

          <v-card>
            <v-alert type="error"> </v-alert>

            <v-card-title class="text-h6">
              Delete {{item.title}}
            </v-card-title>

            <v-card-text> 
              Are you sure you want to Proceed with deleting?.
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="item.delete_dialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="red darken-1"
                text
                @click="deleteArticle(item); item.delete_dialog = false"
              >
                Delete
              </v-btn>

            </v-card-actions>
          </v-card>
        </v-dialog>

    </template>
    </v-data-table>
  </v-card>

  </v-container>
</template>

<script>
import axios from "axios";
import SuccessAlert from '../../../mixins/successAlert'
import ErrorAlert from '../../../mixins/errorAlert'

 export default {
      mixins:[SuccessAlert,ErrorAlert],
      name:'Articles',
      
      data () {
        return {
          switch1: true,
          switch2: false,
          categories:[],
          delete_dialog:false,
          articles:[],
          search: '',
        }
      },

      computed: {
      headers () {
        return [
          {
            text: 'Title',
            align: 'start',
            sortable: true,
            value: 'title',
          },

          { text: 'Excerpt',sortable: false, value: 'excerpt' },
          { text: 'Category', value: 'category_id' },
          { text: 'Published',  value: 'is_published'},
          { text: 'Date',  value: 'published_at', width: '10%' },
          { text: 'Actions', value: 'actions', width: '15%'},
        ]
      },
      },

      mounted: function() {
        this.getArticles();
      },

      methods: {
        getArticles() {
          axios
          .get(process.env.VUE_APP_API_URL + '/articles/all')
          .then((response) => {
            this.articles = response.data.articles;
          })

           axios
          .get(process.env.VUE_APP_API_URL + '/articles/categories/all')
          .then((response) => {
             this.categories = response.data.categories;
         })
        },

        togglePublish(article){
          
          var was_published = !!article.published_at;
          if(was_published){
            article.published_at = new Date();
          } else {
            article.published_at = null;
          }

          axios
          .put(process.env.VUE_APP_API_URL + '/articles/publish/'+ article.slug, article)
          .then((response) => {
            this.getArticles();
            this.successAlert("Article " + (was_published ? 'un': '') + "published.",response.message);
          })
          
          .catch((error) => {
            this.errorAlert("Failed to Update article.",Object.values(error.response.data.errors).join(' '));
          });
        },

        deleteArticle(article){
          axios
          .delete(process.env.VUE_APP_API_URL + '/articles/'+article.slug)
           .then(() => {
            this.successAlert("Article Deleted", "successfully"); //this is a mixin function successAlert($title,$message)
            this.getArticles();
          })

          .catch((error) => {
            this.errorAlert("Failed to delete.",Object.values(error.response.data.errors).join(' ')); 
          });
        },

        filterOnlyCapsText (value, search) {
          return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().toLocaleUpperCase().indexOf(search) !== -1
        },
      },
  }

</script>
