<template>
<div>
<v-container fluid style="min-height: 100vh;">
    <v-container  v-if="article">
        <a class="smallBody" style="color: #78786A !important; text-decoration:none; font-size: 14px;" href="/blogs">Blog <v-icon>mdi-arrow-right</v-icon>{{article.category.name}}<v-icon>mdi-arrow-right</v-icon>{{article.title}}</a>
         <h2 class="my-10">{{article.title}}</h2> 

        <v-divider class='black my-10'></v-divider>
        <v-row class="pa-5">
          <v-flex sm12 md4>
            <div class="mr-lg-15 mr-md-10 smallBody" style="  font-size: 14px;">
              <p>Published {{article.published_at}} 
                <br> Viewed {{article.number_of_views > 7 ? article.number_of_views : 7}} times
              </p>
              <BlogCategoryCard :category_name="article.category.name" :link="`/blogs/${article.slug}`"/>
             <v-divider class='black mt-5'></v-divider>
             <p class="mt-5">{{article.excerpt}}</p>
            </div>
          </v-flex>
          <v-flex sm12 md8>
             <div v-html="article.body" style="white-space: pre-wrap;"></div>
             <div>
              <p class="mediumBody"><strong>Share this article</strong></p>
              <v-row class="mt-5">
                <v-col cols="1">
                  <ShareNetwork
                    network="facebook"
                    :url="page_url"
                    :title="article.title"
                    :description="article.excerpt"
                    style="text-decoration:none !important"
                  >
                   <v-icon> mdi-facebook</v-icon>
                  </ShareNetwork>
                </v-col>
                <v-col cols="1">
                  <ShareNetwork
                    network="linkedin"
                    :title="article.title"
                    :url="page_url"
                    style="text-decoration:none !important"
                  >
                   <v-icon>mdi-linkedin</v-icon>
                  </ShareNetwork>
                </v-col>
                <v-col cols="1">
                   <ShareNetwork
                    network="twitter"
                    :title="article.title"
                    :url="page_url"
                    style="text-decoration:none !important"
                  >
                    <v-icon>mdi-twitter</v-icon>
                  </ShareNetwork>
                </v-col>
              </v-row>
           
             </div>
          </v-flex>
          <v-flex>
             
          </v-flex>
        </v-row>
        <v-divider class='black my-15'></v-divider>
    </v-container>
</v-container>

        <v-container fluid v-if="article">
            <v-container >
                <h4 class="my-10">Related articles</h4>
                   <v-row>
                    <v-flex sm12 md6 lg4  v-for="blog in articles_in_category" :key="blog.id">
                        <v-card
                          class="mx-auto"
                          max-width="344"
                          tile
                          flat
                          router :to="`/blogs/${blog.slug}`"
                        >
                            <img v-if="blog.thumbnail_uuid" :src="blog.thumbnail_url"  height="220px" style="max-width:344px; background-size:fill;"/>
                            <v-img  v-else :src="temporary_images.filter((e)=>{return e.id == blog.id})[0].url" class="mx-auto mt-md-2 mr-lg-8"/>
                        
                            <p class="my-3 mediumBody"><strong>{{blog.title}}</strong> <v-icon>mdi-arrow-top-right</v-icon></p>
                            <p class="smallBody">{{blog.excerpt}}</p>
                            <BlogCategoryCard :category_name="blog.category.name" :link="`/blogs/${blog.slug}`"/>
                        </v-card>
                    </v-flex>
                  </v-row>
            </v-container>
        </v-container>
    </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import BlogCategoryCard from '@/components/BlogCategoryCard.vue';

export default {
    name:'Blog',
    components:{
        BlogCategoryCard
    },
    data () {
        return {
        articles_in_category:null,
        thumbnail_uuid: null,
        timer:null,
        hero_image_uuid: null,
        thumbnail: null,
        page_url:null,
        component_key: 0,
        hero_image:null,
        thumbnail_file_name: "",
        attachment_file_names: [],
        // upload_hero_image:false,
        categories:"",
        temporary_images:[
          {
            id: 1,
            url: '/images/assets/Images/unsplash_i9w4Uy1pU-s (2).png'
          },
          {
            id: 2,
            url: '/images/assets/Images/unsplash_i9w4Uy1pU-s.png'
          },
          {
            id: 3,
            url: '/images/assets/Images/purple-illustration.png'
          },
          {
            id: 4,
            url: '/images/assets/Images/unsplash_i9w4Uy1pU-s (1).png',
          },
          {
            id: 5,
            url: '/images/assets/Images/concept-of-offsetting.png'
          },
        ],
        published_status:"",
        created_article:"",
        article:"",
        }
    },

    mounted: function() {
        this.getData();
    },

    created: function() {
        this.page_url = window.location.href;

        this.timer = setTimeout(()=>{
            axios
            .post(process.env.VUE_APP_API_URL + '/articles/update-view-count',this.article)
            .then(() => {})
         }, 10000);
    },

    beforeDestroy: function() {
      clearTimeout(this.timer);
    },

    methods:{
    getData() {
          axios
          .get(process.env.VUE_APP_API_URL + '/articles/categories/all')
          .then((response) => {
             this.categories = response.data.categories;
          })
   
        // .catch((error) => console.log(error));

      //get blog article
      if(this.$route.params.slug) {
        axios
          .get(process.env.VUE_APP_API_URL + '/articles/published/' + this.$route.params.slug)
          .then((response) => {
            this.article = response.data.article;
            this.article.published_at = this.formatDate(this.article.published_at);
            this.getArticlesByCategory(this.article.category.slug);

          })
          .catch((error) => { 
            console.log(error);
        });
      } 
    },

    getArticlesByCategory(category) {
      axios
      .get(process.env.VUE_APP_API_URL + '/articles?category='+category)
      .then((response) => {
        this.articles_in_category = response.data.articles;

        for (let i = 0; i < this.articles_in_category.length; i++) {
        if(this.articles_in_category[i].thumbnail_uuid != null){
          this.getThumbnail(this.articles_in_category[i].thumbnail_uuid, i);
        }
      }
      })
    },

    // determinePurpose(value) {
    //   if(value.purpose=="thumbnail"){
    //     this.thumbnail_uuid = value.uuid;
    //     this.getThumbnail(this.thumbnail_uuid, value.purpose);
    //   }

    //   if(value.purpose=="heroImage"){
    //     this.hero_image_uuid = value.uuid;
    //     this.getThumbnail(this.hero_image_uuid, value.purpose);
    //   }
    // },
    
    getThumbnail(uuid, post_index) {
        axios
          .get(process.env.VUE_APP_API_URL + "/uploads/download/public/" + uuid ,
          {
            responseType: 'blob',
          })
          .then((response) => {
            var thumbnail_url = window.URL.createObjectURL(new Blob([response.data]));
            this.articles_in_category[post_index].thumbnail_url = thumbnail_url;
            this.component_key += 1;
          })
    },

    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },

    }
}
</script>
