<template>
  <v-container fluid>
     <v-container>

    <v-card class="mb-6 projectsPurple mt-0 dashboardCard">
       <div class="rightBars rightGrayBars rightGrayBarsCard"></div>
      <v-row justify="start">
        <v-col lg="1" class="pa-5">
          <v-avatar style="float:right;" fab class="white pa-1" >
           <v-icon>mdi-post</v-icon>
          </v-avatar> 
        </v-col>
        <v-divider class="my-2" vertical></v-divider>
        <v-col lg="9" class="pa-5">
          <p v-if="article" style="float:left; color:black" class="description mt-4">{{article.title}}</p>
          <p v-else style="float:left; color:black" class="description mt-4">Create an Article</p>
        </v-col>
      </v-row>
    </v-card>    

      <v-spacer></v-spacer>
      <v-form>
          <v-row>
            <v-col cols="12" lg="6" md="12">
              <v-card class="my-6 dashboardCard"> 
              <v-textarea v-model="updated_article.title" label="Title"></v-textarea>
             </v-card>
            </v-col>

            <v-col cols="12" lg="6" md="12">
              <v-card class="my-6 dashboardCard"> 
              <v-textarea v-model="updated_article.excerpt" label="Excerpt"></v-textarea>
              </v-card>
            </v-col>
          </v-row>
      </v-form>
    </v-container>

    <v-container>
      <v-card class="my-6 dashboardCard">
        <v-row>
          <v-col>
            <p class="description mb-8 mt-1 ml-2">Body</p>
          </v-col>
          <v-col>
            <v-switch class="float-right mt-0 pt-0 mr-2" label="Rich Text Editor" v-model="rich_text"></v-switch>
          </v-col>
        </v-row>
      <v-container v-show="rich_text" class="mb-8" ref="editor"></v-container>
      <v-textarea v-show="!rich_text" rows="15" v-model="updated_article.body"></v-textarea>
                            
      <v-col class="d-flex mb-8" cols="12" sm="6">
        <v-select
          v-model="updated_article.category_id"
          dense
          :items="categories"
          item-value="id"
          item-text="name"
          label="Category"
          outlined
        ></v-select>
      </v-col>

      <v-row>
        <v-col cols="6">
          <p class="description mb-8">Published At</p>
          <input type="date" v-model="updated_article.published_at" />
        </v-col>
      </v-row>
      </v-card>
    </v-container>

    <v-container class="mb-10">
      <v-card class="my-6 dashboardCard">
      <v-row>
        <v-col>
          <p class="description mb-8">Thumbnail</p>
          <v-img v-if="thumbnail"
            class="mb-8"
            :src="thumbnail"
            height="200px"
            width="200px"
          ></v-img>
        
          <v-file-input
            name="thumbnail"
            accept="image/png, image/jpeg, image/bmp, image/jpg, image/gif"
            placeholder="Pick an image for the article"
            prepend-icon="mdi-camera"
            label="Upload New Article Thumbnail"
            @change="handleFile($event, 'thumbnail')"
          ></v-file-input>
        </v-col>

      </v-row>
      </v-card>
    </v-container>

      <v-divider></v-divider>

    <v-container>

    <v-btn
      to="/dashboard/articles"
      class="ma-2"
      color="secondary"
    >
       Back to Articles
    </v-btn>

    <v-btn
    v-if="!!this.$route.params.slug" @click="updateArticle()"
    class="ma-2"
    color="success"
    >
      Save
    </v-btn>

    <v-btn
    v-if="!this.$route.params.slug" @click="createArticle()"
    class="ma-2"
    color="success"
    >
      Save
    </v-btn>


    </v-container>

  </v-container>
</template>

<script>
import axios from "axios";
import Quill from "quill";
import moment from "moment";
import SuccessAlert from '../../../mixins/successAlert'
import ErrorAlert from '../../../mixins/errorAlert'

export default {
    mixins: [SuccessAlert, ErrorAlert],
    name:'EditArticle',
      data () {
        return {
        rich_text:true,
        updated_article:{
          title:"",
          body:"",
          category_id:"",
          excerpt:"",
          published_at:null,
          user_id:"",
        },
        upload:{
          fqcn:"",
          uploadable_id:"",
          folder_path:"",
          file:"",
          file_name:"",
          purpose:"",
          file_extension:""
        },
        hero_image_upload:{
          fqcn:"",
          uploadable_id:"",
          folder_path:"",
          file:"",
          file_name:"",
          purpose:"",
          file_extension:""
        },
        editor: null, // Quill editor
        quill_text: "",
        thumbnail_uuid: null,
        hero_image_uuid: null,
        thumbnail: null,
        hero_image:null,
        hero_image_upload_ready:false,
        thumbnail_upload_ready:false,
        thumbnail_file_name: "",
        attachment_file_names: [],
        file: "",
        file_name:"",
        categories:[],
        published_status:"",
        created_article:"",
        article:"",
        }
      },

    mounted: function() {
    this.getData();
    },

    computed: {
      creator: {
        get() {
          return this.$store.getters.getUser;
        },
      },
    },

    created() {
        this.$store.dispatch("fetchUser");
    },

    methods:{
    updateArticle(){

       axios
          .put(process.env.VUE_APP_API_URL + '/articles/'+ this.$route.params.slug, this.updated_article)
          .then((response) => {
            this.created_article = response.data;
            this.uploadFiles(this.created_article.article.id);
            this.successAlert("Article updated", "successfully"); //this is a mixin function successAlert($title,$message)
            this.$router.push('/dashboard/articles');
          })
          
          .catch((error) => {
            this.errorAlert("Failed to update article.",Object.values(error.response.data.errors).join(' '));  //this is a mixin function errorAlert($message,$error)
          });
    },

     createArticle(){
       this.updated_article.user_id=this.creator.id;

       axios
          .post(process.env.VUE_APP_API_URL + '/articles/', this.updated_article)
          .then((response) => {
            this.created_article = response.data;
            this.uploadFiles(this.created_article.article.id);
            this.successAlert("Article Created", "successfully"); //this is a mixin function successAlert($title,$message)
            this.$router.push('/dashboard/articles');
          })
          
          .catch((error) => {
            this.errorAlert("Failed to Create article.",Object.values(error.response.data.errors).join(' '));  //this is a mixin function errorAlert($message,$error)
          });
     },

     uploadFiles(post_id) {

      if(this.thumbnail_upload_ready==true){
        this.upload.uploadable_id=post_id;
   
        axios
          .post(
              process.env.VUE_APP_API_URL + "/uploads/upload", this.upload,
              { headers: { "Content-Type": "multipart/form-data",},}
          )
          .then(() => {console.log("Saved")})
          .catch((error) => {
           this.errorAlert("Failed to store thumbnail.",Object.values(error.response.data.errors).join(' '));
          });
      }

      if(this.hero_image_upload_ready==true){
      this.hero_image_upload.uploadable_id=post_id;
      axios
        .post(
            process.env.VUE_APP_API_URL + "/uploads/upload", this.hero_image_upload,
            { headers: { "Content-Type": "multipart/form-data",},}
        )
        .then(() => {})
        .catch((error) => {
          this.errorAlert("Failed to store hero Image.",Object.values(error.response.data.errors).join(' '));
        });
      }

       this.successAlert("Image Stored", "successfully");

     },

    handleFile(event, purpose) {

      if(purpose=="thumbnail"){
            var file_name_w_extension = event.name;
            this.upload.file = new Blob([event]);
            this.upload.file_name = file_name_w_extension.substring(0, file_name_w_extension.lastIndexOf('.'));
            this.upload.purpose = purpose;
            this.upload.file_extension = file_name_w_extension.split('.').pop();
            this.upload.fqcn="Modules\\Blog\\Entities\\Article";
            this.upload.folder_path="/blog-posts";
            this.thumbnail_upload_ready=true;
  
      }

      if(purpose=="heroImage"){
            var file_name_h_extension = event.name;
            this.hero_image_upload.file = new Blob([event]);
            this.hero_image_upload.file_name = file_name_h_extension.substring(0, file_name_h_extension.lastIndexOf('.'));
            this.hero_image_upload.purpose = purpose;
            this.hero_image_upload.file_extension = file_name_h_extension.split('.').pop();
            this.hero_image_upload.fqcn="Modules\\Blog\\Entities\\Article";
            this.hero_image_upload.folder_path="/blog-posts";
            this.hero_image_upload_ready=true;

      }
    },

    initializeQuill() {
    this.editor = new Quill(this.$refs.editor, {
        modules: {
            toolbar: [
                [{ header: [1, 2, 3, false] }],
                ['bold', 'italic', 'underline'],
                ['link'],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ]
        },
        theme: 'snow',
        formats: ['bold', 'underline', 'header', 'italic','link','list']
    });

    this.quill_text = this.updated_article.body;
    this.loadQuill();
    },

    loadQuill() {
      this.editor.root.innerHTML = this.quill_text; // Initiation value

      this.editor.on('text-change', () => {
        this.updated_article.body = this.editor.root.innerHTML;
      });
    },

    articlePublishedStatus(){
      if (this.updated_article.published_at != null){this.published_status = true}
      else{this.published_status = false}
    },

    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },

    getData() {
          axios
          .get(process.env.VUE_APP_API_URL + '/articles/categories/all')
          .then((response) => {
             this.categories = response.data.categories;
          })
   
        // .catch((error) => console.log(error));

      //get blog article
      if(this.$route.params.slug) {
        axios
          .get(process.env.VUE_APP_API_URL + '/articles/' + this.$route.params.slug)
          .then((response) => {
            this.article = response.data.article;
            this.updated_article = this.article;
            this.article.published_at = this.formatDate(this.article.published_at);
            if(this.updated_article.published_at === "Invalid date"){
              this.updated_article.published_at=null;
            }

            let uploads_array = this.updated_article.uploads;
            uploads_array.forEach(this.determinePurpose);

          })
          .catch((error) => { 
            console.log(error);
        });
      } 

       setTimeout(() => {
              this.initializeQuill();
       }, 1000);

      this.articlePublishedStatus();
    },

    determinePurpose(value) {
      if(value.purpose=="thumbnail"){
        this.thumbnail_uuid = value.uuid;
        this.getThumbnail(this.thumbnail_uuid, value.purpose);
      }

      if(value.purpose=="heroImage"){
        this.hero_image_uuid = value.uuid;
        this.getThumbnail(this.hero_image_uuid, value.purpose);
      }
    },
    
    getThumbnail (uuid, purpose) {
      axios
        .get(process.env.VUE_APP_API_URL + "/uploads/download/public/" + uuid ,
        {
          responseType: 'blob',
        })
        .then((response) => {
          if(purpose=="thumbnail"){ this.thumbnail = window.URL.createObjectURL(new Blob([response.data]));}
          if(purpose=="heroImage"){ this.hero_image = window.URL.createObjectURL(new Blob([response.data]));}
        })
      }
    },
}

</script>
