var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"mb-6 projectsPurple mt-0 dashboardCard"},[_c('div',{staticClass:"rightBars rightGrayBars rightGrayBarsCard"}),_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"pa-5",attrs:{"lg":"4"}},[_c('div',{staticStyle:{"float":"left"}},[_c('v-avatar',{staticClass:"white pa-1  mr-5",attrs:{"fab":""}},[_c('v-icon',[_vm._v("mdi-store-settings")])],1),_c('p',{staticClass:"description mt-4",staticStyle:{"color":"black","float":"right"}},[_vm._v("Custom-built stores")])],1)]),_c('v-col',{staticClass:"pa-5 my-auto",attrs:{"justify":"end","lg":"4"}})],1)],1),_c('v-card',{staticClass:"dashboardCard "},[_c('v-card-title',[_vm._v(" All custom-built stores on the platform "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.organisations,"search":_vm.search},scopedSlots:_vm._u([{key:"item.glutenfree",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"color":"primary","value":"","input-value":_vm.organistionStatus(item)},on:{"click":function($event){return _vm.changeOrganisationStatus(item)}}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":("/dashboard/organisations/" + (item.slug) + "/transactions")}},[_c('v-icon',{staticClass:"mx-2",attrs:{"small":""},on:{"click":item}},[_vm._v(" mdi-receipt-text-outline ")])],1),_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":("/dashboard/organisations/" + (item.slug))}},[_c('v-icon',{staticClass:"mx-2",attrs:{"small":""},on:{"click":item}},[_vm._v(" mdi-eye ")])],1),_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":("/dashboard/organisations/" + (item.slug) + "/edit")}},[_c('v-icon',{staticClass:"mx-2",attrs:{"small":""},on:{"click":item}},[_vm._v(" mdi-pencil ")])],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-alert',{attrs:{"type":"error"}}),_c('v-card-title',{staticClass:"text-h6"},[_vm._v(" Delete "+_vm._s(item.name)+" ")]),_c('v-card-text',[_vm._v(" Are you sure you want to Proceed with deleting?. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){return _vm.deleteOrganisation(item)}}},[_vm._v(" Delete ")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }