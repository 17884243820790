<template>
  <div>
    <v-container fluid class="pricing-hero-image" style=" top: 0">
        <v-container class="my-10 pa-10" wrap>
            <v-row>
                <v-col cols="12" md="6">
                <p class="subTitle">e-commerce plugin</p>
                <h2 class="mb-10">Pricing</h2>
            
                <p class="mediumBody">
                    Below you can see a detailed description of the pricing for our e-commerce carbon offsetting plugin. Please see our FAQ’s for more questions if you cannot find what you are looking for here. 
                </p>
                </v-col>
            </v-row>
        </v-container>
    </v-container>

    <v-container fluid class="lightGray">
        <v-container class="mt-5 mb-10 pa-10">
            <v-row>
                <v-col cols="12">
                   <p class="mediumBody">
                    We have two product offerings: <strong>customer adoption</strong> and <strong>merchant adoption</strong>. Your store’s choice of product depends on where you are in your sustianability journey.
                    <br><br>
                    <strong>Customer adoption:</strong>  Put the power to offset the emissions in your customer’s hands, allowing them to offset the carbon emissions of their purchases.
                    <br><br>
                    <strong>Merchant adoption:</strong> Allows you as the merchant to offset the emissions of all your customer’s purchases, allowing your business to take a giant leap towards carbon neatrality and a more consicous shopping experience.
                    <br><br>
                    <strong>Store offset percentage:</strong> When you sign up, your store will be given the default rate of 2%. In order to calculate the rate specific to you, you can complete a store information form that will tell us a bit more about the products you sell. From this we can adjust your offset percentage to more accurately offset the carbon emissions.
                   </p>
                </v-col>
            </v-row>

            <v-row class="mt-10">
                <v-col cols="12" md="6">
                    <div class="pricing-hero-image" style="border:1px black solid; border-radius:5px; max-width:420px; max-height:800px;  box-shadow: 0 4px 3px 0 rgba(0, 0, 0, 0.2), 0 8px 10px 0 rgba(0, 0, 0, 0.19); line-height: 50px;">
                        <div class="pa-6">
                            <h3>Customer adoption</h3>
                            <p>$ 0 service fee. <br> Customer pays to offset their purchase depending on offset % </p>
                            <v-btn class="cobalite rounded-1 smallBody" to="/register">Get started</v-btn>
                            <hr class="my-5" style="border: 0.5px solid black">
                            <ul>
                            <li>Unlimited monthly offsets </li>
                                <li>Customisable Plugin</li>
                                <li>Access to marketing material </li>
                                <li>Integration support</li>
                            </ul>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" md="6">
                    <div class="pricing-hero-image" style="border:1px black solid; border-radius:5px; max-width:420px; max-height:800px;  box-shadow: 0 4px 3px 0 rgba(0, 0, 0, 0.2), 0 8px 10px 0 rgba(0, 0, 0, 0.19); line-height: 50px;">
                        <div class="pa-6">
                            <h3>Merchant adoption</h3>
                            <p>$ 0 service fee. <br> Merchant pays to offset each purchase depending on offset %</p>
                            <v-btn class="cobalite rounded-1 smallBody" to="/register">Get started</v-btn>
                            <hr class="my-5" style="border: 0.5px solid black">
                            <ul>
                            <li>Unlimited monthly offsets </li>
                                <li>Customisable Plugin</li>
                                <li>Access to marketing material </li>
                                <li>Integration support</li>
                            </ul>
                        </div>
                    </div>
                </v-col>
            </v-row>

        </v-container>
    </v-container>
  </div>
</template>

<script>
export default {
    name:'Pricing'
}
</script>
