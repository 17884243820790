<template>
<v-app>
  <v-main>
    <v-container fluid class="px-0 py-0">
        <router-view name="header" class="mx-4"/>
      <router-view/>
        <router-view name="footer"/>
    </v-container>
  </v-main>
</v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
