import Vue from "vue";
import Router from "vue-router";

// layout imports
import Navbar from "../layout/Navbar.vue";
import Footer from "../layout/Footer.vue";

//pages imports
import Home from "../pages/Home.vue";
import About from "../pages/About.vue";
// import Contact from "../pages/Contact.vue";
import Learn from "../pages/Learn";
import Blogs from "../pages/Blogs";
import HelpCenter from "../pages/HelpCenter";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsOfService from "../pages/TermsOfService";
import Blog from "../pages/Blog";
import Forbidden from "../pages/403.vue";
import NotFound from "../pages/404.vue";
import Pricing from "../pages/Pricing.vue";

//dashboard pages
import Dashboard from "../layout/Dashboard";
import ShopAdminDashboard from "../pages/dashboard/ShopAdminDashboard.vue";
import CurbonAdminDashboard from "../pages/dashboard/CurbonAdminDashboard.vue";
import Leads from "../pages/dashboard/leads/Leads.vue";
import Users from "../pages/dashboard/users/Users";
import UserForm from "../pages/dashboard/users/UserForm";
import UserProfile from "../pages/dashboard/users/UserProfile.vue";
import Transaction from "../pages/dashboard/transactions/Transaction"

//documentation pages
import Documentation from "../pages/documentation/Documentation";


//organisation pages
import Organisations from "../pages/dashboard/organisations/Organisations";
import Organisation from "../pages/dashboard/organisations/Organisation";
import OrganisationForm from "../pages/dashboard/organisations/OrganisationForm";
import CreateApiKey from "../pages/dashboard/organisations/CreateApiKey";
import IntegrationSetup from "../pages/dashboard/organisations/IntegrationSetup";
import Transactions from"../pages/dashboard/transactions/Transactions";

//Blog pages
import Articles from "../pages/dashboard/articles/Articles.vue"
import EditArticle from "../pages/dashboard/articles/EditArticle.vue"

//projects
import Projects from "../pages/Projects";
import Project from "../pages/Project";

// auth pages
import AuthLayout from "@/layout/AuthLayout.vue";
import Register from "../pages/auth/Register.vue";
import Login from "../pages/auth/Login.vue";
import AccountDeactivated from "../pages/auth/AccountDeactivated.vue";
import ForgotPassword from "../pages/auth/ForgotPassword.vue";
import Verified from "../pages/auth/Verified.vue";
import Verify from "../pages/auth/Verify.vue";
import ChangePassword from "../pages/auth/ChangePassword.vue";
import ResetPassword from "../pages/auth/ResetPassword.vue";

// middlesware
// import guest from '@/middleware/guest';
import auth from '@/middleware/auth';
import isActive from '@/middleware/isActive';
import verified from '@/middleware/verified';

Vue.use(Router);

const router = new Router({
  mode: "history",
  scrollBehavior: function (to) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
  },
  routes: [
    {
      path: "/",
      name: "Home",
      components: { default: Home, header: Navbar, footer: Footer },
      meta: {
        title: "Home",
        metaTags: [
          {
            name: "description",
            content: "",
          },
        ],
      },
    },

    {
      path: "/about",
      name: "About",
      components: { default: About, header: Navbar, footer: Footer },
      meta: {
        title: "About",
        metaTags: [
          {
            name: "description",
            content: "",
          },
        ],
      },
    },

    {
      path: "/blogs/:slug",
      name: "Blog",
      components: { default: Blog, header: Navbar, footer: Footer },
      meta: {
        title: "Blog",
        metaTags: [
          {
            name: "description",
            content: "",
          },
        ],
      },
    },

    {
      path: "/blogs",
      name: "Blogs",
      components: { default: Blogs, header: Navbar, footer: Footer },
      meta: {
        title: "Blogs",
        metaTags: [
          {
            name: "description",
            content: "",
          },
        ],
      },
    },

    // {
    //   path: "/contact",
    //   name: "Contact",
    //   components: { header: Navbar, default: Contact, footer: Footer },
    //   meta: {
    //     title: "Contact",
    //     metaTags: [
    //       {
    //         name: "description",
    //         content: "",
    //       },
    //     ],
    //   },
    // },

    {
      path: "/help-center",
      name: "HelpCenter",
      components: { header: Navbar, default: HelpCenter, footer: Footer },
      meta: {
        title: "Help Center",
        metaTags: [
          {
            name: "description",
            content: "",
          },
        ],
      },
    },
    {
      path: "/pricing",
      name: "Pricing",
      components: { default: Pricing, header: Navbar, footer: Footer },
      meta: {
        title: "Pricing",
        metaTags: [
          {
            name: "description",
            content: "",
          },
        ],
      },
    },
    {
      path: "/privacy-policy",
      name: "PrivacyPolicy",
      components: { header: Navbar, default: PrivacyPolicy, footer: Footer },
      meta: {
        title: "Privacy Policy",
        metaTags: [
          {
            name: "description",
            content: "",
          },
        ],
      },
    },
    {
      path: "/privacy",
      name: "PrivacyPolicy",
      components: { header: Navbar, default: PrivacyPolicy, footer: Footer },
      meta: {
        title: "Privacy Policy",
        metaTags: [
          {
            name: "description",
            content: "",
          },
        ],
      },
    },
    {
      path: "/terms-of-service",
      name: "TermsOfService",
      components: { header: Navbar, default: TermsOfService, footer: Footer },
      meta: {
        title: "Terms of Service",
        metaTags: [
          {
            name: "description",
            content: "",
          },
        ],
      },
    },
    {
      path: "/terms",
      name: "TermsOfService",
      components: { header: Navbar, default: TermsOfService, footer: Footer },
      meta: {
        title: "Terms of Service",
        metaTags: [
          {
            name: "description",
            content: "",
          },
        ],
      },
    },


    {
      path: "/documentation",
      name: "Documentation",
      components: { header: Navbar, default: Documentation, footer: Footer },
      meta: {
        title: "Documentation",
        metaTags: [
          {
            name: "description",
            content: "",
          },
        ],
      },
    },

    {
      path: "/learn",
      name: "Learn",
      components: { default: Learn, header: Navbar, footer: Footer },
      meta: {
        title: "Learn",
        metaTags: [
          {
            name: "description",
            content: "",
          },
        ],
      },
    },

    {
      path: "/projects",
      name: "Projects",
      components: { default: Projects, header: Navbar, footer: Footer },
      meta: {
        title: "Projects",
        metaTags: [
          {
            name: "description",
            content: "",
          },
        ],
      },
    },
    
    //project page temp/ate
    {
      path: "/projects/:slug",
      name: "Project",
      components: { default: Project, header: Navbar, footer: Footer },
      meta: {
        title: "Carbon Project",
        metaTags: [
          {
            name: "description",
            content: "",
          },
        ],
      },
    },

  /*

   Login and user account related routes

  */
    {
      path: "/register",
      name: "Register",
      components: { default: Register, header: Navbar},
      meta: {
        title: "Register",
        metaTags: [
          {
            name: "description",
            content: "",
          },
        ],
      },
    },

    //Auth layout pages like login, forgot password, email verification
    {
      path: "/",
      component: AuthLayout, //Includes Navbar
      children: [

          {
            path: "/login",
            name: "Login",
            component: Login,
            meta: {
              title: "Log in",
              metaTags: [
                {
                  name: "description",
                  content: "",
                },
              ],
            },
          },

          {
            path: "/account-deactivated",
            name: "AccountDeactivated",
            component: AccountDeactivated,
            meta: {
              title: "Account Deactivated",
              metaTags: [
                {
                  name: "description",
                  content: "",
                },
              ],
            },
          },

          {
            path: "/forgot-password",
            name: "ForgotPassword",
            component: ForgotPassword,
            meta: {
              title: "ForgotPassword",
              metaTags: [
                {
                  name: "description",
                  content: "",
                },
              ],
            },
          },

          {
            path: "/change-password",
            name: "ChangePassword",
            component: ChangePassword,
            meta: {
              middleware: [
                isActive,
                auth
              ],
              title: "ChangePassword",
              metaTags: [
                {
                  name: "description",
                  content: "",
                },
              ],
            },
          },

          {
            path: "/password/reset/:token",
            name: "ResetPassword",
            component: ResetPassword,
            meta: {
              title: "Reset password",
              metaTags: [
                {
                  name: "description",
                  content: "",
                },
              ],
            },
          },

        {
          path: "/email/verify",
          name: "Verify",
          component: Verify,
          meta: {
            middleware: [
              auth
            ],
            title: "Verify",
            metaTags: [
              {
                name: "description",
                content: "",
              },
            ],
          },
        },

        {
          path: "/email/verified",
          name: "Verified",
          component: Verified,
          meta: {
            middleware: [
              auth,
            ],
            title: "Verified",
            metaTags: [
              {
                name: "description",
                content: "",
              },
            ],
          },
        },
      ]
    },

    /* 
    
    dashboard routes
    
    */

    {
      path: "/dashboard",
      component: Dashboard,
      children: [
        {
          path: "/",
          name: "Dashboard",
          components: { default: ShopAdminDashboard},
          meta: {
            middleware: [
              isActive,
              auth,
              verified
            ],
            title: "Dashboard",
            metaTags: [
              {
                name: "description",
                content: "",
              },
            ],
          },
        },

        {
          path: "admin",
          name: "AdminDashboard",
          components: { default: CurbonAdminDashboard},
          meta: {
            middleware: [
              isActive,
              auth,
              verified
            ],
            title: "Dashboard",
            metaTags: [
              {
                name: "description",
                content: "",
              },
            ],
          },
        },

        {
          path: "articles",
          name: "Articles",
          components: { default: Articles},
          meta: {
            middleware: [
              isActive,
              auth,
              verified
            ],
            title: "Articles",
            metaTags: [
              {
                name: "description",
                content: "",
              },
            ],
          },
        },

        {
          path: "organisations",
          name: "Organisations",
          components: { default: Organisations},
          meta: {
            middleware: [
              isActive,
              auth,
              verified
            ],
            title: "Organisations",
            metaTags: [
              {
                name: "description",
                content: "",
              },
            ],
          },
        },

        {
          path: "organisations/create",
          name: "OrganisationForm",
          components: { default: OrganisationForm},
          meta: {
            middleware: [
              isActive,
              auth,
              verified
            ],
            title: "Create Organisation",
            metaTags: [
              {
                name: "description",
                content: "",
              },
            ],
          },
        },

        {
          path: "organisations/:slug/transactions",
          name: "Integration Setup",
          components: { default: Transactions},
          meta: {
            middleware: [
              isActive,
              auth,
              verified
            ],
            title: "Organisation",
            metaTags: [
              {
                name: "description",
                content: "",
              },
            ],
          },
        },


        {
          path: "organisations/:slug",
          name: "Organisation",
          components: { default: Organisation},
          meta: {
            middleware: [
              isActive,
              auth,
              verified
            ],
            title: "Organisation",
            metaTags: [
              {
                name: "description",
                content: "",
              },
            ],
          },
        },

        {
          path: "organisations/:slug/transactions/:id",
          name: "Transaction",
          components: { default: Transaction},
          meta: {
            middleware: [
              isActive,
              auth,
              verified
            ],
            title: "Transaction",
            metaTags: [
              {
                name: "description",
                content: "",
              },
            ],
          },
        },

        {
          path: "organisations/:slug/integration-setup",
          name: "Integration Setup",
          components: { default: IntegrationSetup},
          meta: {
            middleware: [
              isActive,
              auth,
              verified
            ],
            title: "Organisation",
            metaTags: [
              {
                name: "description",
                content: "",
              },
            ],
          },
        },

        {
          path: "organisations/:slug/api-keys/create",
          name: "Create API key",
          components: { default: CreateApiKey},
          meta: {
            middleware: [
              isActive,
              auth,
              verified
            ],
            title: "Organisation",
            metaTags: [
              {
                name: "description",
                content: "",
              },
            ],
          },
        },

        {
          path: "organisations/:slug/edit",
          name: "EditOrganisation",
          components: { default: OrganisationForm},
          meta: {
            middleware: [
              isActive,
              auth,
              verified
            ],
            title: "Edit Organisation",
            metaTags: [
              {
                name: "description",
                content: "",
              },
            ],
          },
        },

        {
          path: "leads",
          name: "Leads",
          components: { default: Leads},
          meta: {
            middleware: [
                auth,
                verified
            ],
            title: "Leads",
            metaTags: [
              {
                name: "description",
                content: "",
              },
            ],
          },
        },

        {
          path: "users",
          name: "Users",
          components: { default: Users},
          meta: {
            middleware: [
                auth,
                verified
            ],
            title: "Users",
            metaTags: [
              {
                name: "description",
                content: "",
              },
            ],
          },
        },

        {
          path: "users/create",
          name: "UserForm",
          components: { default: UserForm},
          meta: {
            middleware: [
              auth,
            ],
            title: "Create User",
            metaTags: [
              {
                name: "description",
                content: "",
              },
            ],
          },
        },

        {
          path: "users/:slug",
          name: "User Profile",
          components: { default: UserProfile},
          meta: {
            middleware: [
                auth,
                verified
            ],
            title: "User Profile",
            metaTags: [
              {
                name: "description",
                content: "",
              },
            ],
          },
        },

        {
          path: "users/profile",
          name: "My User Profile",
          components: { default: UserProfile},
          meta: {
            middleware: [
              isActive,
              auth
            ],
            title: "UserProfile",
            metaTags: [
              {
                name: "description",
                content: "",
              },
            ],
          },
        },

        {
          path: "articles/create",
          name: "CreateArticle",
          components: { default: EditArticle},
          meta: {
            middleware: [
              isActive,
              auth,
              verified
            ],
            title: "Create Article",
            metaTags: [
              {
                name: "description",
                content: "",
              },
            ],
          },
        },
        
        {
          path: "articles/:slug/edit",
          name: "EditArticle",
          components: { default:EditArticle},
          meta: {
            middleware: [
              isActive,
              auth,
              verified
            ],
            title: "Edit Article",
            metaTags: [
              {
                name: "description",
                content: "",
              },
            ],
          },
        },

      ]
    },

    /*

        Catch everything else with a 404 route
  
    */

    {
      path: "/forbidden",
      name: "Forbidden",
      components: { default: Forbidden, header: Navbar, footer: Footer  },
      meta: {
        title: "Forbidden",
      },
    },

    {
      path: "/:pathMatch(.*)*",
      name: "NotFound",
      components: { default: NotFound, header: Navbar, footer: Footer  },
      meta: {
        title: "Not Found",
      },
    },
  ],
});

export default router;
