<template>
  <v-footer
    bottom 
    relative
    padless
    class="footerColor"
  >
    <v-container fluid>
    <v-row class="mt-5 mb-md-5 mx-5">
      <v-col cols="12" md="4" class="text-center text-md-right">
        <p class="mb-md-15 mb-lg-10 bodyBig">Powering positive change.</p>
        <br><br class="hidden-lg-and-down"><br class="hidden-lg-and-down">
        <v-img max-width="33%" 
              class="my-10 mt-lg-15 hidden-sm-and-down" style="transform: scale(5);"
              src="/images/assets/Images/Group 1246.svg">
        </v-img>
      </v-col>
      
      <v-spacer></v-spacer>

      <v-col cols="6" sm="4" md="2" class="text-left">
        <p class="smallBody"><strong>Company</strong></p>
        <v-list flat class="footerColor text-left">
            <v-list-item-group>
           <v-list-item style="color: #78786A !important;" text v-for="link in links" :key="link.text" router :to="link.route" class="px-0">
            <p class="text-left">{{link.text}}</p>
          </v-list-item>
            </v-list-item-group>
        </v-list>
      </v-col>
<!-- 
      <v-col cols="6" sm="4" md="2">
          <p class="smallBody"><strong>Address</strong></p>
          <v-list class="softGray footerColor">
          <v-list-item class="px-0" style="color: #78786A !important;">
           <p>1st Floor <br>
              Workshop 17 <br>
              32 Kloof St <br>
              Cape Town <br>
              8001 <br>
              South Africa
            </p>
          </v-list-item>
        </v-list>
      </v-col> -->

      <v-col cols="6" sm="4" md="2">
          <p class="smallBody"><strong>Proudly remote </strong></p>
          <v-list class="softGray footerColor">
          <v-list-item class="px-0" style="color: #78786A !important;">
            <p>Built in Cape Town</p> 
          </v-list-item>
        </v-list>
        <p class="smallBody mt-5 mb-0"><strong>Connect</strong></p>
        <v-list class="softGray footerColor">
          <v-list-item class="px-0" style="color: #78786A !important;">
            <p>Support <br><a style="color: #78786A !important;" href="mailto:support@curbon.io">support@curbon.io</a></p> 
          </v-list-item>
          <v-list-item class="px-0">
            <p><a style="color: #78786A !important;" href="https://www.instagram.com/curbon.io/" target="_blank">Instagram</a></p> 
          </v-list-item>
          <v-list-item class="px-0">
            <p><a style="color: #78786A !important;" href="https://www.linkedin.com/company/curbonio/" target="_blank">LinkedIn</a></p> 
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
     <v-row justify="end" class="mt-n10 pt-n10 my-md-5 hidden-md-and-up" >
      <v-col cols="6">
        <div style="height:50px; width:50px; border-radius:5%; float:right" class="charcoal pa-2 ma-5 hidden-md-and-up">
            <v-img width="100%" src='/images/curbon/Curbon_logomark_white.svg'></v-img>
        </div>
      </v-col>
     </v-row>

      <v-divider ></v-divider>
      <v-row justify-space-around class="mt-4 mb-2 px-7">
        <v-col cols="12" md="6" justify="start">
          &copy; Curbon {{ new Date().getFullYear() }}.  All Rights Reserved. 
        </v-col>

        <v-col cols="12" md="6" class="text-md-right">
         Please visit our <a style="color: #78786A !important;" href="/terms-of-service">Terms of Service</a> for more details. Read our <a style="color: #78786A !important;" href="/privacy-policy">Privacy Policy</a>.
        </v-col>
      </v-row>
      </v-container> 
  </v-footer>
</template>

<script>
export default {
    name:'Footer',
     data () {
      return {
        links:[
          {text:'How it works', route:'/'},
          {text:'Learn', route:'/learn'},
          {text:'About', route:'/about'},
          {text:'Projects', route:'/projects'},
          {text:'Blog', route:'/blogs'},
          {text:'FAQ', route:'/help-center'},
        ]
      }
    },
}
</script>
