<template>
<div>
<v-container fluid class="home-hero-image">
  <v-container>
      <v-row
      align="center"
      class="mt-10 mb-6 ml-6"
      >
      <v-col
        cols="12" md="8"
      >
        <p class="subheading">Documentation</p>
        <h2 class="mb-4">
          Integrating a Custom-built Store
        </h2>
        <p>Here's how to add CURBON to your online store. If you can’t find the information you’re looking for <a style="color: #78786A !important;" href="mailto:info@curbon.io" target="_blank">please reach out to our friendly team.</a> </p>
      </v-col>
       <!-- <v-flex xs12 md6 lg5>
          <v-text-field
          class=" rounded-0 pt-15"
          append-icon="mdi-magnify"
          label="Search"
          outlined
          ></v-text-field>
      </v-flex> -->
    </v-row>
  </v-container>
</v-container>

<v-container fluid>
    <v-container>
      <v-tabs :vertical="(screen_width > 959) ? true : false" hide-slider show-arrows color="black" class="ml-md-10" v-model="tab">
            <v-tab class="listItems" active-class="activeImpactStep">
              01&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Getting started
            </v-tab>
            <v-tab class="listItems" active-class="activeImpactStep">
              02&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Creating an API key
            </v-tab>
            <v-tab class="listItems" active-class="activeImpactStep">
              03&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Checkout setup
            </v-tab>
            <v-tab class="listItems" active-class="activeImpactStep">
              04&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Submitting the order
            </v-tab>
            <v-tab class="listItems" active-class="activeImpactStep">
              05&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Accounting
            </v-tab>

            <!-- Getting started -->
            <v-tab-item>
              <v-card flat class="mt-md-n5 ml-md-3">
                <v-card-text>
                  <h3 class="h3 mb-5">Registration</h3>
                  <p>
                    Step 1 is to register for a CURBON account, if you have not done so already.  You can <router-link target="_blank" style="color: #78786A !important;" to="/register"> sign up here.</router-link>
                  </p>
                  <p>
                    As you register you will automatically start setting up your first online store with CURBON. To add another store, simply click on the "Add Store" button on the dashboard or follow <router-link target="_blank" style="color: #78786A !important;" to="/dashboard/organisations/create"> this link to add a new store.</router-link>
                  </p>
                  <h3 class="h3 mb-5 mt-10">Tax details</h3>
                  <p>
                    Add or update your store's tax information by clicking the "Edit Details" button with the pen icon.  This tax information will then be used on the invoice CURBON issues your store.
                  </p>
                  <h3 class="h3 mb-5 mt-10">Collaborators</h3>
                  <p>
                    To add other users to your store, click the button with the eye icon to view the store and then click on the "Add User" button.
                  </p>
                  <p>
                    All users have the same access and permissions over the store.
                  </p>
                </v-card-text>
              </v-card>
            </v-tab-item>


            <!-- create API key -->
            <v-tab-item>
              <v-card flat class="mt-md-n5 ml-md-3">
                <v-card-text>
                  <h3 class="h3 mb-5">API keys</h3>
                  <p>
                    CURBON uses API keys to authenticate the store when displaying the cart plug-in and when saving purchases of carbon credits.
                  </p>
                  <p>
                    From the dashboard, navigate to the integration setup page by clicking the gear icon or "Integration Setup" button.  Here you will see a section showing all the API keys for the store and a button to create an API key.  Copy and save both the API key and the API secret.
                  </p>
                  <p>
                    The CURBON platform encrypts the API key like a password and you should treat it as such.
                  </p>
                </v-card-text>
              </v-card>
            </v-tab-item>


            <!-- adding to cart -->
            <v-tab-item>
              <v-card flat class="mt-md-n5 ml-md-3">
                <v-card-text>
                  <h3 class="h3 mb-5">Plug-in Template</h3>
                  <p>
                    CURBON integrates with the shopping cart on the check-out page.
                  </p>
                  <p>
                    You can select the template to display for you store by clicking the integration setup button with the gear icon.  On this page you will see the plug-in design section.  Click on the "Set Design" and select the template for your store. You can also set the default currency for your store here.
                  </p>
                  <p>
                    Here is an example of a CURBON plug-in template:
                  </p>
                  <CartTemplate default_currency="$" amount="2" i_icon="By covering the carbon emission cost of this purchase, you're helping offset the cost these products have on the planet, from the raw materials and manufacturing to the last-mile delivery." header="Carbon neutral checkout" primary_color="#A09FFA" secondary_color="#F15A29"></CartTemplate>
                  <h3 class="h3 mb-5 mt-10">HTML</h3>
                  <p>
                    Copy this HTML into the code of your checkout page where you want to display the CURBON plug-in.
                  </p>
                  <v-card id="plugin_html" class="dashboardCard charcoal white--text">
                     <!-- @click="copyText('plugin_html')" -->
                    &lt;div id="curbonDiv"&gt;&lt;/div&gt;   
                  </v-card>
                  <h3 class="h3 mb-5 mt-10">Setting the font and font-size with CSS</h3>
                  <p>
                    If you would like to style the fonts and colors of the plugin, you can build on the following code snippet.
                  </p>
                  <v-card id="plugin_html" class="dashboardCard charcoal white--text">
                     <!-- @click="copyText('plugin_html')" -->
<pre>
&lt;!-- fonts --&gt;
&lt;style type="text/css"&gt;
  /*set the font-family and text-color for the plugin's div, which is "curbonDiv" in our case*/
  #curbonDiv {
    font-family: 'Poppins', sans-serif;
    color: #3d3d3d;
    font-size: 12px;
  }
  .curbon-header{
    font-size: 24px !important;
  }
  .curbon-button{
    font-size: 12px !important;
  }
&lt;/style&gt;
&lt;!-- of course, you will need to imports the fonts you use --&gt;
&lt;link rel="preconnect" href="https://fonts.googleapis.com"&gt;
&lt;link rel="preconnect" href="https://fonts.gstatic.com" crossorigin&gt;
&lt;link href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet"&gt;
</pre>
                  </v-card>


                  
                  <h3 class="h3 mb-5 mt-10">JavaScript</h3>
                  <p>You can either access the JavaScript file on Curbon's CDN, as shown in the snippet below, or download it to include in your website, for the fastest load times.  <a style="color: #78786A !important;" href="/lib/curbon.js" download>Download curbon.js here.</a></p>

                  <v-card id="plugin_js" class="dashboardCard charcoal white--text">
                    <!-- @click="copyText('plugin_html')" -->
                    &lt;script src="https://curbon.io/lib/curbon.js">&lt;/script&gt;
                  </v-card>

                  <p>
                    The next step is to copy the JavaScript below into the code of your checkout page.  The curbon.js file retrieves the carbon() function, which then fetches the plugin to display in your store. 
                  </p>
                  <p>Note the sections marked with the comment "// TODO " indicate where your custom code is required. </p>
                  <p>
                    The parameters of the curbon() function are:
                    <ol>
                      <li>The API key for your store,</li>
                      <li>The ID of the div wherein the plug-in will be displayed,</li>
                      <li>The currency of the transaction, and</li>
                      <li>The cart total of the purchase that will be the input for the carbon credit calculation.</li>
                    </ol>
                  </p>
                  <p>
                    
                  </p>

                  <v-card id="plugin_js" class="dashboardCard charcoal white--text">
                     <!-- @click="copyText('plugin_html')" -->
<pre>
&lt;script type="text/javascript"&gt;

  // call curbon with API_KEY,ID of div to hold UI,currency,cart total
  Curbon('your-api-key-goes-here','curbonDiv','$',2000);

  document.addEventListener('click', function(e) {

    if (e.target.matches('#curbonOptOut')) {
      // TODO: implement your code to allow the user to proceed without a carbon offset
    }

    // Check that we only add the Curbon offset if the user clicked on the curbonContribute button
    if (!e.target.matches('#curbonContribute')) {
      return;
    }

    // TODO: implement your code here to update the cart total, which is the variable: e.target.dataset.curbonContribution
    alert(e.target.dataset.curbonContribution);

  });

&lt;/script&gt;  
</pre>
                  </v-card>
                </v-card-text>
              </v-card>
            </v-tab-item>


            <!-- submitting the order -->
            <v-tab-item>
              <v-card flat class="mt-md-n5 ml-md-3">
                <v-card-text>
                  <h3 class="h3 mb-5">Purchase API</h3>
                  <p>
                    Once someone pays for offsetting the carbon emissions associated with their purchase, that information should be sent to Curbon using this transaction API.
                  </p>
                  <p>
                    The API has the following properties:
                    <ol>
                      <li>Method: Post</li>
                      <li>URL: https://api.curbon.io/api/v1/custom/transactions</li>
                      <li>Headers: X-API-key, X-API-secret</li>
                      <li>Request body: JSON object</li>
                    </ol>
                  </p>
                  <h3 class="h3 mb-5 mt-10">Request body</h3>
                  <p>
                    Here are the properties of the request body, which needs to be formatted as a JSON object.  Note that the purchase reference and the purchase ID are fields for your use such that you have reference information to link your sales to offsets on the Curbon platform.
                  </p>
                  <v-data-table 
                    hide-default-footer
                    items-per-page="100"
                    :headers="[
                      {
                        'text':'Property',
                        'value':'property',
                        'sortable': false
                      },
                      {
                        'text':'Description',
                        'value':'description',
                        'sortable': false
                      },
                      {
                        'text':'Validation',
                        'value':'validation',
                        'sortable': false
                      },
                      {
                        'text':'Type',
                        'value':'type',
                        'sortable': false
                      }
                    ]"
                    :items="[
                      {
                        'property':'email',
                        'type':'varchar(255)',
                        'description':'Email address of the person making the purchase',
                        'validation':'Must be an email address'
                      },
                      {
                        'property':'name',
                        'type':'varchar(255)',
                        'description':'Name of the person making the purchase',
                        'validation':'None'
                      },
                      {
                        'property':'phone',
                        'type':'varchar(15)',
                        'description':'Phone number of the person making the purchase',
                        'validation':'None'
                      },
                      {
                        'property':'currency',
                        'type':'varchar(3)',
                        'description':'Currency of the purchase',
                        'validation':'Required and 3 characters or less'
                      },
                      {
                        'property':'items',
                        'type':'Array of JSON objects',
                        'description':'Items in the cart in an array of JSON objects formatted as {`item`:`itemName`,`price`:`itemPrice`,`quantity`:`itemQuantity`}',
                        'validation':'Optional'
                      },
                      {
                        'property':'total_price',
                        'type':'decimal(14,2)',
                        'description':'Total price of all the items in the cart except the carbon offset and taxes',
                        'validation':'Required'
                      },
                      {
                        'property':'tax',
                        'type':'decimal(14,2)',
                        'description':'Tax on all cart items excluding the carbon offset',
                        'validation':'Required'
                      },
                      {
                        'property':'offset_price',
                        'type':'decimal(14,2)',
                        'description':'The cost specified by Curbon as sufficient to offset carbon emissions associated with the cart',
                        'validation':'Required'
                      },

                      {
                        'property':'invoice_price',
                        'type':'decimal(14,2)',
                        'description':'The price of all cart items plus the carbon offset and taxes',
                        'validation':'Required'
                      },
                      {
                        'property':'purchase_reference',
                        'type':'varchar(255)',
                        'description':'The purchase reference number or code given to the client',
                        'validation':'Required'
                      },
                      {
                        'property':'purchase_id',
                        'type':'varchar(255)',
                        'description':'The purchase ID on the store database',
                        'validation':'Required, unique for store'
                      },
                      {
                        'property':'billing_address',
                        'type':'json',
                        'description':'The address of the person making the purchase formatted as {`address_line1`: `apartment`, `address_line2`: `streetAddress`, `city`: `city`, `code`: `postalCode`, `country`: `countryCode`}',
                        'validation':'None'
                      }

                    ]"
                  >
                  </v-data-table> 
                  <h3 class="h3 mb-5 mt-10">Example</h3>
                  <p>
                    Consider this Axios call below as an illustrative example.  You are welcome, of course, to use any other tool or library to make your API calls.
                  </p>

                  <v-card id="txn_js" class="dashboardCard charcoal white--text">
<pre>
axios
.post('https://curbon.io/api/v1/custom/transactions', {
  "email": "joe@example.com",
  "name":"Joe",
  "phone":"+27766277844",
  "currency":"USD",
  "items":[
      {
          "item":"Shoe 123",
          "quantity": 1,
          "price":"50"
      },
      {
          "item":"Shoe 9000",
          "quantity": 2,
          "price":"25"
      }
  ],
  "total_price":"100",
  "tax":"15",
  "offset_price":"2",
  "invoice_price":"117",
  "purchase_reference":"A-010042",
  "purchase_id":42,
  "billing_address": {
      "address_line1": "Apartment 1",
      "address_line2": "1 test rd",
      "city": "Johannesburg",
      "code": "1234",
      "country": "ZA"
  }
},{
  headers: {
      'X-API-KEY': 'your-api-key-goes-here',
      'X-API-SECRET': 'your-api-secret-goes-here'
  }
})
.then(function (response) {

  //TODO: Record the successful carbon offset
  console.log('response');
  console.log(response);

})
.catch(function (error) {

  //TODO: Add error handling
  console.log('error');
  console.log(error);

});
</pre>
                  </v-card>
                </v-card-text>
              </v-card>
            </v-tab-item>



            <v-tab-item>

              <v-card flat class="mt-md-n5 ml-md-3">
                <v-card-text>
                  <h3 class="h3 mb-5">Transaction records</h3>
                  <p>
                    Log into the Curbon platform to see the transactions and reports for your store.
                  </p>
                </v-card-text>
              </v-card>
            </v-tab-item>
      </v-tabs>
      <v-row class="justify-center mb-10">
        <v-col cols="12">
          <v-btn v-if="tab != 4" @click="nextTab()" class="float-right mr-n5">
             Next page
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-btn v-if="tab != 0" @click="previousTab()" class="float-right mr-5"> 
            <v-icon>mdi-chevron-left</v-icon>
            Previous page
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
</v-container>
</div>
</template>

<script>
import CartTemplate from '@/components/CartTemplate.vue';
import SuccessAlert from '@/mixins/successAlert';
import ErrorAlert from '@/mixins/errorAlert';

export default {
    name:'Documentation',
    mixins:[SuccessAlert,ErrorAlert],
    components: {
      CartTemplate,
    },
    data () {
      return {
        screen_width: window.innerWidth, //default
        tab: 0,
        pluging_html: "",
        pluging_js: "",
        transaction_js: "",
      }
    },
    created() {
      window.addEventListener("resize", this.resizeHandler);
    },
    destroyed() {
      window.removeEventListener("resize", this.resizeHandler);
    },
    methods: {
      resizeHandler() {
        this.screen_width = window.innerWidth;
      },
      nextTab(){
        this.tab++; 
        window.scrollTo(0, 0);
      },
      previousTab(){
        this.tab--; 
        window.scrollTo(0, 0);
      },
      copyText(div_id){
        console.log(div_id);
        console.log(document.getElementById(div_id));
        var text = document.getElementById(div_id).innerHTML;
        navigator.clipboard.writeText(text);
        this.successAlert("Copied code",text);
      }
    }
}

</script>
