import Vue from 'vue';
import Vuex from 'vuex';
import auth from "@/store/modules/auth";
import projects from "@/store/modules/projects";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    projects
  },
});