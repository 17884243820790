//import theme js
import '../public/theme/js/theme.js';
import '../public/theme/css/theme.css';
import '../public/theme/fonts/theme-fonts.css';


//import pluggins
import Vue from 'vue';
import App from './App.vue';
import loading from 'vuejs-loading-screen'; //TODO: check if still in use
import router from './router';
import store from "./store";
import VueCookies from 'vue-cookies';
import vuetify from './plugins/vuetify'
import VueSweetalert2 from 'vue-sweetalert2';
import "sweetalert2/dist/sweetalert2.min.css";
import VueSocialSharing from 'vue-social-sharing'

//authentication and authorization functions
import middlewarePipeline from '@/middleware/middlewarePipeline';
import interceptorsSetup from "./helpers/interceptors";
interceptorsSetup();

Vue.use(loading);
Vue.use(VueCookies);
Vue.use(VueSocialSharing);

Vue.use(VueSweetalert2);
Vue.config.productionTip = false


//router should scroll to top on each page visit
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  return next();
})

//middleware
//explained here: https://blog.logrocket.com/vue-middleware-pipelines/
router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
      return next()
  }

  const middleware = to.meta.middleware

  const context = {
      to,
      from,
      next,
      store
  }

  if(store.getters.getIsLoggedIn == false || store.getters.getLoginStatus == '') {
    //get user details then proceed
    store.dispatch('fetchUser').then(()=>{
      return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
      }) 
    });
  } else {
    //proceed with next middleware
    return middleware[0]({
      ...context,
      next: middlewarePipeline(context, middleware, 1)
    })
  }
})


//SEO meta properties
router.beforeEach((to, from, next) => {

  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title =
      process.env.VUE_APP_TITLE + " " + nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title =
      process.env.VUE_APP_TITLE + " " + previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();

  //end of seo
});


new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
