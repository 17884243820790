<template>
    <div>
        <v-btn v-if="category_name == 'Markets'" router :to="link" text class="smallBody sapling">{{category_name}}</v-btn>
        <v-btn v-if="category_name == 'Science'" router :to="link" text class="smallBody combustion">{{category_name}}</v-btn>
        <v-btn v-if="category_name == 'Policy'" router :to="link" text class="smallBody cobalite">{{category_name}}</v-btn>
        <v-btn v-if="category_name == 'Action'" router :to="link" text class="smallBody terra">{{category_name}}</v-btn>
    </div>
</template>

<script>
export default {
    name:'BlogCategoryCard',
    props: {
            category_name: String,
            link: String
        }
}
</script>
