const Swal = require("sweetalert2");

export default {
  methods: {
    successAlert($title, $message) {
      Swal.fire({
        icon: "success",
        title: $title,
        text: $message,
      });
    },
  },
};
