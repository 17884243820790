var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"lightGray",attrs:{"fluid":""}},[_c('v-row',{staticClass:"fullscreen",staticStyle:{"background":"white"}},[_c('v-col',{staticClass:"login-hero-image",attrs:{"cols":"12","md":"7"}},[_c('div',{staticClass:"ma-10"},[_c('div',{staticClass:"text-left pa-10 transparent-box hidden-sm-and-down"},[_c('h3',[_vm._v(" You’re doing your part in working toward a net-zero future. ")])])])]),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-row',{staticClass:"mx-10 my-md-10 mt-md-15 pa-10"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"h3 display-1"},[_vm._v("Reset Password")]),_c('p',{staticClass:"smallBody my-5"},[_vm._v("Complete the form to create your new password.")]),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mx-auto rounded-0",attrs:{"error-messages":errors,"label":"Email","type":"text","required":"","dense":"","outlined":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mx-auto rounded-0",attrs:{"error-messages":errors,"label":"Password","type":"password","required":"","dense":"","outlined":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"password_confirmation","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mx-auto rounded-0",attrs:{"error-messages":errors,"label":"Password confirmation","type":"password","required":"","dense":"","outlined":""},model:{value:(_vm.form.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "password_confirmation", $$v)},expression:"form.password_confirmation"}})]}}],null,true)}),_c('v-btn',{staticClass:"black white--text rounded-0",attrs:{"width":"100%","depressed":"","type":"button","disabled":invalid},nativeOn:{"click":function($event){return _vm.resetPassword()}}},[_vm._v(" Reset ")])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }