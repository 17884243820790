<template>
   <v-container>
        <v-card class="mb-6 projectsPurple mt-0 dashboardCard">
       <div class="rightBars rightGrayBars rightGrayBarsCard"></div>
      <v-row justify="start">
        <v-col lg="1" class="pa-5">
          <v-avatar style="float:right;" fab class="white pa-1" >
           <v-icon>mdi-account-group</v-icon>
          </v-avatar> 
        </v-col>
        <v-divider class="my-2" vertical></v-divider>
        <v-col lg="2" class="pa-5">
          <p style="float:left; color:black" class="description mt-4">Create User</p>
        </v-col>
      </v-row>
    </v-card>

    <v-card  class="my-6 dashboardCard">
   <v-form @submit.prevent="register()" ref="form" v-model="valid">
    <v-container>
    <v-row>
        <v-col
        cols="12"
        lg="6"
        >
        <v-text-field
            v-model='user.first_name'
            :rules="rules.first_name"
            :counter="20"
            label="First name"
            required
        >
        </v-text-field>
        </v-col>

        <v-col
        cols="12"
        lg="6"
        >
        <v-text-field
            v-model="user.last_name"
            :rules="rules.last_name"
            :counter="20"
            label="Last name"
            required
        ></v-text-field>
        </v-col>
    </v-row>
    <v-row>
        <v-col
            cols="12"
            lg="6"
        >
        <v-text-field
            v-model="user.email"
            :rules="rules.email"
            label="E-mail"
            required
        ></v-text-field>
        </v-col>
    
        <v-col
            cols="12"
            lg="6"
        >
        <v-text-field
            v-model="user.phone"
            label="Phone"
        ></v-text-field>
        </v-col>
        
    </v-row>
    <v-row>
        <v-col
            cols="12"
            lg="6"
        >
        <!-- organisations -->
        <v-select
            label="Store"
            :items="organisations"
            item-text="name"
            item-value="id"
            v-model="user.organisation_id"
            :rules="rules.organisation_id"
            dense
            required
        ></v-select>
        </v-col>                           
    </v-row>
        <v-btn
            class="ma-2"
            dark
            @click="$router.go(-1)"
        >
            Back
        </v-btn>

    
        <v-btn
            class="ma-2"
            color="success"
            @click="saveUser"
        >
            Save
        </v-btn>
    </v-container>
  </v-form> 
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import SuccessAlert from '@/mixins/successAlert'
import ErrorAlert from '@/mixins/errorAlert'

export default {
    mixins:[SuccessAlert,ErrorAlert],
    name:'CreateUser',

      data() {
        return {
            valid: true,
            user:{
                first_name:"",
                last_name:"",
                email:"",
                phone:"",
                creator_id:"",
                organisation:"",
                organisation_id:""
            },
            organisations:[],
            rules: {
                first_name: [val => (val || '').length > 0 || 'This field is required'],
                last_name: [val => (val || '').length > 0 || 'This field is required'],
                email: [val => (val || '').length > 0 || 'This field is required'],
                organisation_id: [val => (val || '') > 0 || 'This field is required'],
            },
        }
    },

    computed: {
        creator: {
          get() {
            return this.$store.getters.getUser;
          },
        },
    },
    created() {
        this.$store.dispatch("fetchUser");
        this.getOrganisations();
    },

    methods: {
        getOrganisations(){ 
            axios
            .get(process.env.VUE_APP_API_URL + '/organisations/my-organisations')
            .then((response)=>{
                this.organisations = response.data.organisations;
            })
        },
        saveUser()
        {
            if(!this.$refs.form.validate()) {
                //don't post
                return ;
            }
              
            axios
            .post(process.env.VUE_APP_API_URL + "/users/", this.user)
            .then((response) => {
                this.successAlert("User Saved", response.data.success); //this is a mixin function successAlert($title,$message)
                setTimeout(() => {   this.$router.go(-1); }, 1000);
            })
            
            .catch((error) => {
                this.errorAlert("Failed to save user.",Object.values(error.response.data.errors).join(' '));  //this is a mixin function errorAlert($message,$error)
            });
        },

    }
}

</script>
