function state () {
  return {
    projects: [
        {
          slug: "community-recyling-project-south-africa",
          thumbnail: "/images/assets/Images/learn-community-recyling-project-thumbnail.webp",
          banner: "/images/assets/Images/learn-community-recyling-project-with-yellow-bars.webp",
          suppliers: [
            {
              name: "gold-standard",
              link: "https://www.crediblecarbon.com/offset-projects/kuyasa/",
              image: "/images/assets/Icons/credible-carbon-square.png",
            }
          ],
          title: "Community Recyling Project",
          title_plain_text: "Community Recyling Project",
          country: "Stellenbosch, South Africa",
          intro: "<p>In South Africa, 87% of all recyclable waste either ends up in landfill or is illegally dumped. The Stellenbosch Community Recycling Project seeks to address South Africa’s waste problem and empower ‘waste-preneurs’ by making recycling more accessible and financially viable. The project sees local households, schools, restaurants, and commercial ventures reducing their quantity of waste to landfill.<br><br>The proceeds from these Carbon Credits improve the financial viability of establishing additional waste collection drop-offs across the Western Cape and empower “waste-preneurs” with the opportunity to earn an income from collecting and recycling waste.<br> <br>Within 10 years, landfills could be responsible for up to a 10% of manmade greenhouse gases. The Stellenbosch Community Recycling Project avoids carbon emissions by preventing waste from ending in up landfills. The project’s emissions savings have been more than 39 000 tCO2. </p>",
          impact_cards: [
            {
              card_title:"Income and job creation ",
              card_text: "The project provides employment to over 600 waste-preneurs who earn an income from collecting and recycling waste. The project pays market-related rates to waste-preneurs, making their waste collection efforts economically feasible."
            },
            {
              card_title:"Livelihood creation",
              card_text: "The project facilates the creation of livelihoods for many rehabilitated criminals with no other (legal) means of making a living and supporting their families."
            },
            {
              card_title:"Greenhouse gas savings",
              card_text: "By preventing waste from ending up in landfills, the project has saved  more than 39 000 tCO2."
            },
            {
              card_title:"Diverting waste from landfill",
              card_text: "The project recycles over 14 000 tons of solid waste annually, which would otherwise end up in landfills."
            }
          ]
        },
        {
          slug: "fairtrade-project-for-coffee-farmers",
          thumbnail: "/images/assets/Images/learn-ethiopia.png",
          banner: "/images/assets/Images/learn-ethiopia-with-yellow-bars.webp",
          suppliers: [
            // {
            //   name: "gold-standard",
            //   link: "https://marketplace.goldstandard.org/collections/projects/products/fairtrade-project-cookstoves-coffee-farmers-ethiopia",
            //   image: "/images/assets/Icons/Goldstandard-c.png",
            // },
            {
              name: "fairtrade",
              link: "",
              image: "/images/assets/Icons/fairtrade-logo-square.png",
            },
          ],
          title: "Fairtrade® Project for Coffee Farmers",
          title_plain_text: "Fairtrade® Project for Coffee Farmers",
          country: "Ethiopia",
          intro: "<p>Climate change and rampant deforestation are threatening the coffee sector in Ethiopia. Coffee plants are extremely sensitive to rising temperatures. Coffee plants will be drastically less suitable for cultivation in coffee-producing regions due to the change in temperature. Small-scale coffee farmers - most often women - are likely to lose their livelihoods.</p><p>This project has currently provided 20,000 households with a more efficient cookstove (Tikikil and Mirt) which radically reduces the amount of wood used in cooking. By investing in this project, CURBON aims to address climate change and support Ethiopian coffee farmers by offering them a sustainable low-cost energy solution to power their own household’s cooking needs.</p>",
          impact_cards: [
            {
              card_title:"Improving home conditions ",
              card_text: "Clean cooking prevents the inhalation of harmful smoke by those in the household."
            },
            {
              card_title:"Climate and environment",
              card_text: "There is a substantial decrease in deforestation and CO2 emissions as a result of eradicating the need for wood-burning cooking processes. Cooking with the Tikikil and Mirt has been proven to reduce CO2 emissions by 40%."
            },
            {
              card_title:"Limiting the burden of climate impact",
              card_text: "Small-scale coffee farmers now have access to a sustainable cooking solution to feed themselves and their families"
            },
            {
              card_title:"Local employment",
              card_text: "A local economy has grown around the production and maintenance of cookstoves."
            },
          ]
        },
        {
          slug: 'biodiverse-reforestation-panama',
          thumbnail: "/images/assets/Images/learn-panama.png",
          banner: "/images/assets/Images/learn-panama-with-yellow-bars.png",
          suppliers: [
            // {
            //   name: "gold-standard",
            //   link: "https://marketplace.goldstandard.org/collections/projects/products/planting-biodiverse-forests-panama",
            //   image: "/images/assets/Icons/Goldstandard-c.png",
            // },
          ],
          title: "Biodiverse <br>Reforestation <br> Programme",
          title_plain_text: "Biodiverse Reforestation Programme",
          country: "panama, south America",
          intro: "<p>The timber trade has depleted large swaths of tropical rainforests. This project encourages sustainable timber production while reforesting degraded pastureland with a variety of native tree species, including Theobroma cacao trees.The resulting forests offer a natural habitat for native flora and fauna, protect and enrich the soil, conserve and filter water while contributing to the mitigation of climate change. </p> <p>This reforestation programme makes use of a model that combines sustainable, high-quality timber production with biodiversity protection and ecosystem restoration. By supporting this project, CURBON helps to generate a high-value timber and cacao yield that is ecologically sustainable and conscious of its impact on local communities.</p>",
          impact_cards: [
            {
              card_title:"Reforestation",
              card_text: "More than 7.5 million trees of 20 different native species have been planted, each naturally capturing carbon and helping to combat climate change."
            },
            {
              card_title:"Conservation",
              card_text: "25% Of the reforested land has been declared a nature reserve, ensuring the protection of the forest and the animals which inhabit it."
            },
            {
              card_title:"Protecting endangered animals",
              card_text: "15 Threatened animal species from the The International Union for Conservation of Nature’s Red List populate the reforested land."
            },
            {
              card_title:"Fair wages",
              card_text: "All employees receive pay above the legal minimum wage as well as health insurance and a pension fund."
            },
            {
              card_title:"Livelihood creation",
              card_text: "This programme provides job creation for the local community, supporting the livelihoods of indigenous families."
            },
            {
              card_title:" ",
              card_text: " "
            },
            {
              card_title:" ",
              card_text: " "
            },
            {
              card_title:" ",
              card_text: " "
            },
          ]
        },
        // {
        //   slug: "solar-cooking-for-refugee-famalies-chad",
        //   thumbnail: "/images/assets/Images/pulka_grandmother_child.jpeg",
        //   banner: "/images/assets/Images/learn-chad-with-yellow-bars.webp",
        //   suppliers: [
        //     {
        //       name: "gold-standard",
        //       link: "https://marketplace.goldstandard.org/collections/projects/products/solar-cooking-refugee-families-chad",
        //       image: "/images/assets/Icons/Goldstandard-c.png",
        //     }
        //   ],
        //   title: "Solar Cooking for Refugee Families",
        //   title_plain_text: "Solar Cooking for Refugee Families",
        //   country: "Chad, Africa",
        //   intro: "<p>Thousands of refugees from Darfur are offered the chance to improve their quality of life by using CooKit solar cookers. Camps in Eastern Chad near the Sudanese border offer refuge to those seeking escape from the violence in Darfur. Having to venture outside of the camp to collect firewood and risk being attacked is an everyday fear for these refugees. This project provides refugees with the material, knowledge, and hands-on training to set up their own solar cookers and prepare their meals using a sustainable energy source.</p><p>By supporting this project, CURBON helps feed refugee families, ensuring their safety by providing an eco-conscious solution to this social issue.</p>",
        //   impact_cards: [
        //     {
        //       card_title:"Ensures the safety of women",
        //       card_text: "More than 50,000 women and girls eliminate the risk of potentially fatal attacks by not having to venture outside of the camp."
        //     },
        //     {
        //       card_title:"Produces clean energy",
        //       card_text: "An average of 20,000 tonnes of CO2-equivalent greenhouse gasses are eliminated annually by the project."
        //     },
        //     {
        //       card_title:"Improved health",
        //       card_text: "An estimated 50,000 smoke-free solar cookers distributed across six refugee camps eliminate health hazards like smoke inhalation and the outbreak of fires."
        //     },
        //     {
        //       card_title:"Reduces deforestation",
        //       card_text: "A team consisting of around 20 women and men  per camp are responsible for the correct assembly, distribution, and supervision of solar cookers."
        //     },
        //     {
        //       card_title:"Generates employment",
        //       card_text: "As a result of the initiative, more than 50% of local vegetation is conserved."
        //     },
        //     {
        //       card_title:"Promotes responsible consumption",
        //       card_text: "Families are accountable for the maintenance of their own solar cooking system, developing a deeper understanding of energy consumption."
        //     },
        //     {
        //       card_title:" ",
        //       card_text: " "
        //     },
        //     {
        //       card_title:" ",
        //       card_text: " "
        //     },
        //   ]
        // },
        // {
        //   slug: "wind-farm-project-indonesia",
        //   thumbnail: "/images/assets/Images/learn-indonesia-thumbnail.png",
        //   banner: "/images/assets/Images/learn-indonesia-with-yellow-bars.webp",
        //   suppliers: [
        //     // {
        //     //   name: "gold-standard",
        //     //   link: "https://marketplace.goldstandard.org/collections/projects/products/sidrap-wind-farm-project-indonesia",
        //     //   image: "/images/assets/Icons/Goldstandard-c.png",
        //     // },
        //   ],
        //   title: "Wind Farm Project",
        //   title_plain_text: "Wind Farm Project",
        //   country: "Indonesia, Asia",
        //   intro: "<p>Established in 2018 in South Sulawesi, this wind farm comprises of 30 wind turbines with a total installed capacity of 75MW. </p><p>The initiative produces enough renewable energy to power over 70,000 surrounding homes. The project is Indonesia’s first utility-scale wind farm.</p>",
        //   impact_cards: [
        //     {
        //       card_title:"Energy Transition",
        //       card_text: "This project reduces Indonesia’s dependence on fossil fuels for power generation, providing clean electricity to a nation in need of power"
        //     },
        //     {
        //       card_title:"Job creation",
        //       card_text: "35 New jobs have been created through the project, each committed to providing equal pay for work of equal value."
        //     },
        //     {
        //       card_title:"Reduces air pollution",
        //       card_text: "By replacing coal and other fossil fuel-fired power plants with clean power, the project is actively reducing the impact on the surrounding air quality."
        //     },
        //     {
        //       card_title:"Raises awareness",
        //       card_text: "The wind farm cultivates awareness around new power alternatives and forward-thinking solutions that combat climate change."
        //     }
        //   ]
        // },
        {
          slug: "the-utsil-naj-project-latin-america",
          thumbnail: "/images/assets/Images/learn-guatemala.png",
          banner: "/images/assets/Images/learn-guatemala-with-yellow-bars.webp",
          suppliers: [
            // {
            //   name: "gold-standard",
            //   link: "https://marketplace.goldstandard.org/collections/projects/products/sidrap-wind-farm-project-indonesia",
            //   image: "/images/assets/Icons/Goldstandard-c.png",
            // },
          ],
          title: "The Utsil Naj Project",
          title_plain_text: "The Utsil Naj Project",
          country: "Guatemala, Latin America",
          intro: "<p>Central America is among one of the world’s most vulnerable regions. Extreme weather changes have had a devastating impact on the area’s crop yields. Given the disrupted crop cycles, the livelihoods of local communities have been threatened. This project - driven by Microsol - aims to address these social and environmental ills with the introduction of improved cookstoves.</p><p>Established in 2012, the project has installed more than 22 000 cookstoves in indigenous homes, benefitting over 100,000 people living in poverty. By eliminating the need to cook with firewood, these new cookstoves eradicate the strain on local forestry as well as decreasing household cook-related air pollution.</p><p>By investing in this project, CURBON  helps families receive stoves that are more environmentally efficient, and kinder to communities’ health and the surrounding forestry.</p>",
          impact_cards: [
            {
              card_title:"Radical reduction in emissions",
              card_text: "Since the beginning of the programme, 172,000 tonnes of greenhouse gases have been avoided."
            },
            {
              card_title:"Saving the local forests ",
              card_text: "Eradicating the need for cutting down local trees, the project has saved 9,6 million trees since its inception."
            },
            {
              card_title:"Healthier homes",
              card_text: "The use of these cookstoves expel no harmful smoke, eliminating the household air pollution previously associated with wood-burning cooking methods."
            },
            {
              card_title:"Happier families ",
              card_text: "A more efficient method of cooking means women of the household have time to cultivate other skills while avoiding harsh manual labour."
            }
          ]
        },
        {
          slug: 'low-carbon-housing-for-those-in-need-south-africa',
          thumbnail: '/images/assets/Images/learn-south-africa-thumbnail.png',
          banner: "/images/assets/Images/learn-south-africa-with-bars.png",
          suppliers: [
            {
              name: "gold-standard",
              link: "https://www.crediblecarbon.com/offset-projects/kuyasa/",
              image: "/images/assets/Icons/credible-carbon-square.png",
            }
          ],
          title: "Low-Carbon <br> Housing for Those <br> in Need",
          title_plain_text: "Low-Carbon Housing for Those in Need",
          country: "Khayelitsha, South Africa",
          intro: "<p>Located in a corner of one of the Western Cape’s most impoverished areas, Kuyasa is a low-carbon housing development. The development offers safe, structurally-sound housing to those in the community, and is also equipped with carbon-conscious solar heaters and lightbulbs. </p> <p>By 2014, the project had installed solar water heaters and compact fluorescent energy-efficient lightbulbs in over 2000 surrounding homes. The revenue from the sale of the project’s certified carbon credits has been reinvested into the community project, ensuring ongoing maintenance and job creation.</p>",
          impact_cards: [
            {
              card_title:"Supporting community health",
              card_text: "The reduction in wood-burning cooking has resulted in cleaner community air. There has been a noticeable decrease in carbon monoxide poisoning and other respiratory illnesses within the community"
            },
            {
              card_title:"Boosting job creation",
              card_text: "The installation and maintenance of the project’s energy-efficient alternatives has created 85 full time jobs."
            },
            {
              card_title:"Raising awareness",
              card_text: "The Kuyasa project has cultivated community awareness around climate change and the capacity we each hold to change our relationship with our environment."
            },
            {
              card_title:"Protecting the environment",
              card_text: "The first audit of the project in 2014 showed that the initiative’s efforts had eradicated 23 683 tonnes CO2."
            }
          ]
        },
    ]
  }
}

function getProjects(state){
  return state.projects;
}

//Getters
const getters = {
  getProjects,
};


//Actions
const actions = {
  //
};

//Mutations
const mutations = {
  //
};

export default {
  state,
  getters,
  actions,
  mutations,
};
