import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            charcoal: '#3D3D3D',
            black:'#000000',
            softGray:'#FAF8F5',
            terra:'#78786A',
            footerColor:"#F4F3EF",
            white:'#FFFFFF',
            lightGray:'#FAF8F5',
            darkGray:'#C4C4C4',
            dashboardGrey:'#dbdbe7',
            combustion:'#F7FE8B',
            dustyOrange:'#F7AC93',
            projectsYellow:'#F6F8C7',
            sapling:'#A7F19E',
            cobalite:'#A09FFA',
            lightGreen:'#d1faca',
            projectsPurple:'#B9B8F7',
            energy:'#F15A29',
          },
        },
      },
});
