var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"bottom":"0","fluid":""}},[_c('v-card',{staticClass:"mb-6 projectsPurple mt-0 dashboardCard"},[_c('div',{staticClass:"rightBars rightGrayBars rightGrayBarsCard"}),_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"pa-5",attrs:{"lg":"4"}},[_c('div',{staticStyle:{"float":"left"}},[_c('v-avatar',{staticClass:"white pa-1  mr-5",attrs:{"fab":""}},[_c('v-icon',[_vm._v("mdi-account-multiple-plus")])],1),_c('p',{staticClass:"description mt-4",staticStyle:{"color":"black","float":"right"}},[_vm._v("leads")])],1)]),_c('v-col',{staticClass:"pa-5 my-auto",attrs:{"justify":"end","lg":"4"}})],1)],1),_c('v-card',{staticClass:"my-6 dashboardCard"},[_c('v-card-title',[_vm._v(" Leads "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.leads,"search":_vm.search},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"content-class":"elevation-0","max-width":"700"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"float-right",attrs:{"color":"transparent","elevation":"0"}},'v-btn',attrs,false),on),[_c('span',{class:[item.status == 'new' ? 'sapling--text' : item.status == 'lost' ? 'dustyOrange--text' : 'cobalite--text']},[_vm._v("●")]),_vm._v(" "+_vm._s(item.status)+" "),_c('v-icon',_vm._b({staticClass:"mx-2",attrs:{"small":""}},'v-icon',attrs,false),[_vm._v(" mdi-list-status ")])],1)]}}],null,true),model:{value:(item.status_dialog),callback:function ($$v) {_vm.$set(item, "status_dialog", $$v)},expression:"item.status_dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6"},[_vm._v(" Update lead status for "+_vm._s(item.store)+" ")]),_c('v-card-text',[_c('p'),_c('v-form',[_c('label',[_vm._v("Select lead status")]),_c('v-select',{attrs:{"items":['new','contacted','lost','closed'],"item-value":"name","item-text":"name","outlined":"","dense":"","required":""},model:{value:(item.updated_status),callback:function ($$v) {_vm.$set(item, "updated_status", $$v)},expression:"item.updated_status"}})],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"float-left",attrs:{"color":"charcoal darken-1","text":""},on:{"click":function($event){item.status_dialog = false;}}},[_vm._v(" Cancel ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"sapling darken-1","text":""},on:{"click":function($event){_vm.changeLeadStatus(item); item.status_dialog = false;}}},[_vm._v(" Save ")])],1)],1)],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_at.slice(0,19).replace('T',' '))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.updated_at.slice(0,19).replace('T',' '))+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }