<template>
 <div>
<v-container fluid class="home-hero-image">
  <v-container>
      <v-row
      align="center"
      class="mt-10 mb-6 ml-6"
      justify="left"
      >
      <v-col
        cols="12" sm="6"
      >
        <p class="subheading">FAQS</p>
        <h2 class="mb-4">
          Help center
        </h2>
        <p>Many frequently asked questions are answered below. If you can’t find the answer you’re looking for <a style="color: #78786A !important;" href="mailto:support@curbon.io.io" target="_blank">please reach out to our friendly team.</a> </p>
      </v-col>
    </v-row>
  </v-container>
</v-container>

<v-container fluid >
<v-container>
  <p class="text-center">Carbon Offsetting</p>
  
  <div class="mb-8">
    <v-expansion-panels
      v-model="panel"
      multiple
      flat 
    >
      <v-expansion-panel
       v-for="q_and_a in general_q_and_a" :key="q_and_a.title"
      >
      <v-divider></v-divider>
        <v-expansion-panel-header>
          <template v-slot:actions>
            <v-icon>mdi-plus</v-icon>
          </template>
          {{q_and_a.question}}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div v-html="q_and_a.answer"></div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-divider></v-divider>
  </div>

    <p class="text-center">Our product and how to use it</p>
    <div class="mb-8">
      <v-expansion-panels
        v-model="panel"
        multiple
        flat 
      >
        <v-expansion-panel
        v-for="q_and_a in product_q_and_a" :key="q_and_a.title"
        >
        <v-divider></v-divider>
          <v-expansion-panel-header>
            <template v-slot:actions>
              <v-icon>mdi-plus</v-icon>
            </template>
            {{q_and_a.question}}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div v-html="q_and_a.answer"></div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-divider></v-divider>
    </div>

    <p class="text-center">Privacy</p>
    <div class="mb-8">
      <v-expansion-panels
        v-model="panel"
        multiple
        flat 
      >
        <v-expansion-panel
        v-for="q_and_a in privacy_q_and_a" :key="q_and_a.title"
        >
        <v-divider></v-divider>
          
          <v-expansion-panel-header>
            <template v-slot:actions>
              <v-icon>mdi-plus</v-icon>
            </template>
            {{q_and_a.question}}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div v-html="q_and_a.answer"></div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-divider></v-divider>
    </div>
</v-container>
</v-container>

<v-container fluid class="charcoal">
    <v-container>
        <ReadyToGetStarted></ReadyToGetStarted>
    </v-container>
</v-container>

</div>
</template>

<script>
import ReadyToGetStarted from '../components/ReadyToGetStarted.vue'
export default {
    name:'HelpCenter',
    components:{
         ReadyToGetStarted,
    },

    data () {
      return {
        general_q_and_a:[

          {question:'What is a carbon credit?', answer:'<p>A carbon credit is an instrument representing the reduction, avoidance, or sequestration of 1 metric ton of carbon emissions. Also referred to as carbon offsets, these instruments are used to compensate for an equal amount of carbon emissions elsewhere. Carbon offset projects are verified by third parties to ensure that they reduce, avoid, or sequester the amount of carbon that they claim.</p>'},

          {question:'What is carbon offsetting?', answer:'<p>Carbon offsetting involves calculating the amount of carbon emissions generated by a particular activity (such as manufacturing a pair of shoes) and then purchasing carbon credits that pay for avoiding or removing the same amount of carbon elsewhere in the environment.</p><p>Because emissions move through the atmosphere, removing an equivalent amount of emissions elsewhere in the environment has the same net impact as if your emissions didn`t occur. Buying carbon credits that reduce or eliminate greenhouse gases also creates a market that stimulates the creation of additional carbon-removal projects.</p>'},

          {question:'What does it mean for a product to be carbon neutral?', answer:'<p>A product becomes carbon neutral when the emissions of the product`s manufacturing and distribution are calculated and compensated for via carbon offsetting projects. Offsetting carbon emissions, in addition to avoidance and reduction, is an important step in climate action.</p>'},

          {question:'How are CURBON’s carbon credits verified?', answer:'<p>All our offsets that are publicly available on our <a style="color:#3D3D3D" href="/projects">projects page</a> and are registered and tracked by the most accepted and reputable organizations in the voluntary markets, and verified by third parties. Gold Standard, the Verified Carbon Standard (VCS), the American Carbon Registry (ACR), and other internationally recognized organizations ensure the quality, accuracy, and integrity of our carbon credits.</p><p>These verification bodies ensure that the carbon savings would not have happened without the offset project and that the project would not have happened without the ability to sell carbon offsets. </p>'},
        ],
        product_q_and_a:[
          {question:'How does CURBON calculate emissions?', answer:'<p>CURBON’s API calculates carbon emissions and corresponding offsets in real-time, using inputs tailored to the type of activity. By harnessing the data and insight developed by leading research institutions, we are able to calculate the emissions generated by the products and services of over 1000 product categories. For complex use cases, our carbon emissions team can work with you to determine the best approach. </p>'},
          {question:'How much does it cost for merchants and customers? ', answer:'<p>CURBON’s e-commerce offsetting API is free for merchants. The cost to your customers is between 1.8% and  4% of their cart total, depending on the products being purchased.</p>'},
          {question:'Which e-commerce platforms does CURBON cater for?', answer:'<p>CURBON has built an API for custom stores and we are in the process of completing the API for Shopify, Woo Commerce, Magento, Squarespace and Wix. The go-live dates for these platforms are: <ul><li>Woo, Magento: 31/09/2022</li><li>Shopify: 21/10/2022</li><li>Squarespace & Wix: 31/11/2022</li></ul></p>'},
          {question:'How do I get CURBON for my store? ',answer:'Getting the CURBON service in your store is easy! Simply add our plugin to your ecommerce store (if you use Shopify, Woo Commerce, Wix, Squarespace or Magento) or sign up for a custom store integration, and we’ll help you get things set up in no time :-). '},
          {question:'I have signed up for the CURBON plugin through Shopify, Woo Commerce, Wix, Squarespace or Magento, now what?',answer:'Once you have an account set up, you can add CURBON to your online store`s checkout in minutes. Depending on the shopping application you use, you should have received specific steps to get your store set up. If you didn’t or need some help, please reach out to our team by mailing us here: support@curbon.io.'},
          {question:'I have signed up for a custom store integration, now what?', answer:'<p>Once you sign up for a custom store integration with CURBON, you should have received steps on how to get things set up. If you didn’t, or need some help, please reach out to our team by mailing us here: support@curbon.io.</p>'},
        ],
        privacy_q_and_a:[
          {question:'What information does CURBON collect?', answer:'<p>We only collect information that we believe can help customers accurately offset the carbon impact of their online orders. Like most web services, CURBON collects technical information about your device and how you use CURBON to make sure that our products are working, to help calculate the carbon emissions generated by customers orders, and to detect and prevent fraud. </p>'},
          {question:'What do you do with customers data? ', answer:'<p>We do not and will never sell your data. We’re in the business of protecting the planet, not violating your privacy. We promise never to give your data to third parties. We’ll only use your data in ways you’d expect, like improving our customer experience, which we explain in our privacy policy. We’re proud of our privacy policy, read it <a style="color:#3D3D3D" href="/curbon-popi-and-privacy.pdf">here</a>.</p>'},
        ],
      }
    },



          // {question:'How does CURBON calculate a product’s emissions?',answer:'<p>CURBON’s API calculates carbon emissions and corresponding offsets in real-time, using inputs tailored to the type of activity. By harnessing the data and insight developed by leading research institutions, we are able to calculate the emissions generated by the products and services of over 1000 product categories. For complex use cases, our carbon emissions team can work with you to determine the best approach.</p>'},


          // {question:'How much does it cost for merchants and customers?',answer:'<p>CURBON’s e-commerce offsetting API is free for merchants. The cost to your customers is between 1.8% and  4% of their cart total, depending on the products being purchased.</p>'},

    methods: {
      // Create an array the length of our items
      // with all values as true
      all () {
        this.panel = [...Array(this.items).keys()].map((k, i) => i)
      },
      // Reset the panel
      none () {
        this.panel = []
      },
    },
}

</script>
