<template>
<div>
<v-container fluid class="home-hero-image" style=" top: 0">
    <v-container>
        <v-row align="center" class="d-flex justify-space-between">
            <v-col cols="12" md="6" class="mx-sm-auto mt-md-15">
                <h1 class="mb-6"  style="font-size: 40px; line-height: 1.5;">Ecommerce <br> <span class="textGradient">carbon offsetting </span> <br> made simple</h1>
                <p class="description mb-6">EFFORTLESS CARBON OFFSETTING ECOMMERCE PLUGINS</p>
                <v-tabs vertical v-model="hero_tab" class="my-10" hide-slider color="black" background-color="rgba(255, 255, 255, 0)">
                    <v-tab  class="heroListItems" active-class="activeHeroListItem"  v-for="topic in hero_list" :key="topic.title">
                        {{ topic.title }}
                    </v-tab>
                </v-tabs>
                <v-btn class="black white--text rounded-0 mb-6" router to="/register">Get started</v-btn>
            </v-col>
            <v-col cols="12" md="6" xl="4" text-right class="mx-sm-auto">
                <v-img src="images/assets/Illustrations/widescreen-hero.webp" style="width:90%; float:right" class="ma-auto hidden-sm-and-down"></v-img>
                <v-img src="images/assets/Illustrations/mobile-hero-banner.png" style="width:80%;" class="ma-auto hidden-md-and-up"></v-img>
            </v-col>
        </v-row>
    </v-container>
<v-container fluid>
  <v-row justify="end" class="mb-5">
    <div style="height:50px; width:50px; border-radius:5%" class="charcoal pa-2 ma-5">
        <v-img width="100%" src='images/curbon/Curbon_logomark_white.svg'></v-img>
    </div> 
  </v-row> 
</v-container>
</v-container>

<v-container fluid class="softGray" style="position:relative;" id="stats">
    <div class="rightBars rightGrayBars hidden-sm-and-down"></div>
    <v-container class="mt-10 pa-10" style="position:relative">
        <h3 class="mb-10">Eco-conscious business <br> is the new normal</h3>
        <br>
        <p class="bodyBig">Evolve your business practice to meet the needs of your <br> customers and the planet by offering a more sustainable <br> shopping experience.</p>
        <br>
        <v-row class="text-left">
            <v-col  cols="12" sm="6" lg="4">
                <v-card class=" mb-6 charcoal" max-width="344" height="160" style="border-radius: 15px; color:white">
                    <v-card-text style="color:white">
                        <v-row class="pa-auto ma-auto">
                            <v-flex lg6>
                                <p class="my-5 bigBody" style="font-size:34px;">80%</p>
                            </v-flex>
                            <v-flex lg6>
                                <v-avatar style="float:right; transform: scale(0.6);" fab class="sapling pa-1" width="5">
                                    <v-icon>mdi-arrow-up</v-icon>
                                </v-avatar> 
                            </v-flex>
                        </v-row>
                        <p class="smallBody">of consumers are more inclined to buy from climate-conscious platforms. </p>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" sm="6" lg="4">
                <v-card class=" mb-6" max-width="344" height="160" style="border-radius: 15px">
                    <v-card-text>
                        <v-row class="pa-auto ma-auto">
                            <v-flex lg6>
                                <p class="my-5 bigBody" style="font-size:34px;">65%</p>
                            </v-flex>
                            <v-flex lg6>
                                <v-avatar style="float:right; transform: scale(0.6)" fab class="sapling pa-1" width="5">
                                    <v-icon>mdi-arrow-up</v-icon>
                                </v-avatar> 
                            </v-flex>
                        </v-row>
                        <p class="smallBody">of consumers will pay a higher price for eco-friendly products.</p>
                    </v-card-text>
                </v-card>
            </v-col>
        
            <v-col cols="12" sm="6" lg="4">
                <v-card class=" mb-6" max-width="344" height="160" style="border-radius: 15px">
                    <v-card-text>
                        <v-row class="pa-auto ma-auto">
                            <v-flex lg6>
                                <p class="my-5 bigBody" style="font-size:34px;">50%</p>
                            </v-flex>
                            <v-flex lg6>
                                <v-avatar style="float:right; transform: scale(0.6)" fab class="combustion pa-1" width="5">
                                    <v-icon>mdi-arrow-down</v-icon>
                                </v-avatar> 
                            </v-flex>
                        </v-row>
                        <p class="smallBody">of potential employees would accept a lower salary to join a workspace comitted to climate action.</p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</v-container>

<v-container fluid class="white px-10" style="position:relative" id="info">
    <div class="leftBars rightGrayBars hidden-sm-and-down"></div>
    <v-container class="my-10">
        <v-row>
            <v-flex class="hidden-md-and-down">
            </v-flex>
            <v-flex md6> 
                <h3 class="mb-5">Impact driven sustainable shopping for your store </h3>
                <p class="bodyBig">We’ve built a carbon offsetting checkout plugin for all leading  and custom ecommerce platforms, offering your customers climate action with just a click. </p>
                <v-btn class="px-0 smallBody" text router to="/learn" target="_top">Here’s how  <v-icon>mdi-arrow-top-right</v-icon></v-btn>
            </v-flex>
        </v-row>
    </v-container>
    <div @scroll="hideArrow()" style="overflow-x:auto; white-space: nowrap; position:relative">
        <div id="illustration_btn"  class="rightArrow hidden-md-and-up"><v-btn x-small min-height="135px" outlined disabled><v-icon style="color:black !important;">mdi-chevron-double-right</v-icon></v-btn></div>
        <v-img src="images/assets/Illustrations/Infographic-1.webp" class="ma-auto mb-10" style="min-width: 900px; "></v-img>   
    </div>
</v-container>

<v-container fluid id="steps">
    <v-container class="mt-10">
      <v-row class="mb-0" wrap>
            <v-col cols="12">
                <h3 class="mb-8 ml-md-8">Real impact in just a few <br> simple steps</h3>
            </v-col>
            <v-col cols="12" md="6">
                <div class="mx-5">
                    <v-tabs vertical hide-slider v-model="impact_tab" class="hidden-sm-and-down" color="black">
                        <v-tab  class="listItems" active-class="activeImpactStep" v-for="(topic,i) in impact_steps" :key="i" @click="stopImpactTabAmination()">
                            0{{i+1}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ topic.title }}
                        </v-tab>
                    </v-tabs>
                </div>
                <v-spacer></v-spacer>
                <v-btn class="charcoal white--text rounded-0 mt-15 ml-5 smallBody hidden-sm-and-down" to="/register">Get started</v-btn>
            </v-col>

            <v-col cols="12" md="6">
                <v-main class="mx-md-8 pt-0">
                    <v-tabs-items v-model="impact_tab" class="hidden-sm-and-down" style="height: 80vh">
                        <v-tab-item  v-for="step in impact_steps" :key="step.title">
                            <v-main class="text-left pt-0">
                                <div v-html="step.text" class="mediumBody mb-10" @click="stopImpactTabAmination()"></div>
                                <v-img width="100%" class="my-5" :src='step.image' @click="stopImpactTabAmination()"></v-img>
                            </v-main>
                        </v-tab-item>
                    </v-tabs-items>

                    <!-- mobile screen impact section -->
                    <v-expansion-panels flat inset class="hidden-md-and-up">
                        <v-expansion-panel v-for="(topic,i) in impact_steps" :key="i">
                            <v-divider></v-divider>
                            <v-expansion-panel-header>
                                <v-row>
                                    <v-col cols="2" sm="1">
                                        0{{i+1}}
                                    </v-col>
                                    <v-col cols="10">
                                        {{ topic.title }}
                                    </v-col>
                                </v-row>
                                <template v-slot:actions>
                                  <v-icon>mdi-plus</v-icon>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <div class="mb-8" v-html="topic.text"></div>
                                <v-img width="100%" :src='topic.image'></v-img>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-main>
            </v-col>
        </v-row>
    </v-container>
</v-container>

<v-container fluid class="cobalite mt-md-10">
    <v-container wrap>
         <v-row class="my-10 pa-md-10" wrap>
            <v-col cols="12" lg="6" class="mx-auto pa-2 order-lg-2">
                <h3 class="mb-6">We only work with internationally acredited organisations and like-minded partners </h3>
                <br>
                <p class="mediumBody">We want to do things that matter. Each step of our carbon offsetting method is verified by leading international oversight bodies. This means both your company and your customers can trust that every transaction made is working toward a low-carbon future.</p>
                <br>
                <v-btn class="px-0 smallBody" text router to="/about#partners">View all partners <v-icon>mdi-arrow-top-right</v-icon></v-btn>
            </v-col>
            <v-col cols="12" lg="6" class="py-md-5 pr-md-15 order-lg-1" >
                <v-row justify="space-around">
                    <v-col cols="4" sm="2" class="px-2" v-for="partner in partners" :key="partner.image">
                        <a :href="partner.link" target="_blank">
                            <div style="height:80px; width:80px; padding:10px; margin-left: auto; margin-right: auto;" class="charcoal">
                                <v-img :src='partner.image' style="height:60px"></v-img>
                            </div>
                        </a>
                         <p class="partnersLable mt-2 text-center">{{partner.name}}</p>
                    </v-col>
                </v-row>
            </v-col>
         </v-row>
    </v-container>
</v-container>

<v-container fluid class="softGray">
    <v-container class="my-10 text-center">
        <h3 class="mb-6">Join these sustainable brands</h3>
        <v-btn class="cobalite rounded-0 smallBody" to="/register">Get started</v-btn>
        <v-row justify="center" class="" >
            <v-col cols="12" sm="2" class="mt-n10 pt-0 mt-sm-0 pb-md-0" v-for="community in communities" :key="community.image">
                <v-img width="50%" class="mx-auto" :src='community.image'></v-img>
            </v-col>
        </v-row>
    </v-container>
</v-container>
<v-container fluid class="cobalite">
    <v-container class="my-10 text-center">
        <h3 class="mb-6">Featured in</h3>
        <v-row justify="center" class="" >
            <v-col cols="7" sm="3" class="mt-n10 pt-0 mt-sm-0 pb-md-0" v-for="article in newspaper_articles" :key="article.image">
                <a :href="article.link" target="_blank">
                 <v-img width="90%" class="mx-auto" :src='article.image'></v-img>
                </a>
            </v-col>
        </v-row>
    </v-container>
</v-container>

<v-container fluid>
    <v-container class="text-center my-10" wrap>
        <h3 class="mb-10 pb-md-10">Got questions?<br>We have answers.</h3>
        <v-row class="mb-6">
            <v-col>
            <v-expansion-panels flat inset>
                <v-expansion-panel v-for="q_and_a in faq" :key="q_and_a.title">
                    <v-divider></v-divider>
                    <v-expansion-panel-header class="bodyBig">
                        <template v-slot:actions>
                          <v-icon>mdi-plus</v-icon>
                        </template>
                        {{q_and_a.question}}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="text-left smallBody">
                        <div v-html="q_and_a.answer"></div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            </v-col>
        </v-row>
        <p class="smallBody">Need more help? Visit our <router-link style="color: #78786A !important;" to="/help-center">Help center</router-link> .</p>
    </v-container>
</v-container>

<v-container fluid>
    <v-row wrap style="color:white; height:500px">
        <v-flex  md12 lg6 class="mx-auto green-blue-image hidden-md-and-down "></v-flex>
        <v-flex  md12 lg6 class="mx-auto charcoal pa-10" text-center style=" position: relative;">
            <div class="centered">
                <h3>Get your store sustainable in minutes </h3>
                <br>
                <p class="mediumBody">Your business, your customers, our planet.</p>
                <br>
                <v-btn class="combustion rounded-0 smallBody" router to="/register">Get started</v-btn>
            </div>
        </v-flex>
    </v-row>
</v-container>
</div>
</template>

<script>

export default {
    name:'Home',
    data () {
      return {
        dialog: false,
        inset: true,
        hero_tab: null,
        impact_tab: null,
        tab_is_animated: true,
        faq:[
          {question:'What is carbon offsetting?', answer:'<p>Carbon offsetting involves calculating the amount of carbon emissions generated by a particular activity (such as manufacturing a pair of shoes) and then purchasing carbon credits that pay for avoiding or removing the same amount of carbon elsewhere in the environment.</p><p>Because emissions move through the atmosphere, removing an equivalent amount of emissions elsewhere in the environment has the same net impact as if your emissions didn`t occur. Buying carbon credits that reduce or eliminate greenhouse gases also creates a market that stimulates the creation of additional carbon-removal projects.</p>'},

          {question:'What is a carbon credit?', answer:'<p>A carbon credit is an instrument representing the reduction, avoidance, or sequestration of 1 metric ton of carbon emissions. Also referred to as carbon offsets, these instruments are used to compensate for an equal amount of carbon emissions elsewhere. Carbon offset projects are verified by third parties to ensure that they reduce, avoid, or sequester the amount of carbon that they claim.</p>'},

          {question:'What does it mean for a product to be carbon neutral?', answer:'<p>A product becomes carbon neutral when the emissions of the product`s manufacturing and distribution are calculated and compensated for via carbon offsetting projects. Offsetting carbon emissions, in addition to avoidance and reduction, is an important step in climate action.</p>'},

          {question:'How does CURBON calculate a product’s emissions?',answer:'<p>By harnessing the data and insight developed by leading research institutions, our team at CURBON is able to calculate the emissions generated by the products and services of over 1000 product categories. CURBON calculates carbon emissions and corresponding offsets on a per-store basis, using inputs tailored to the type of activity the merchant undertakes. For complex use cases, our carbon emissions team can work with you to determine the best approach.</p>'},

          {question:'How much does it cost for merchants and customers?',answer:'<p>CURBON’s e-commerce offsetting service is  free to use for merchants. The cost to your customers is between 1.8% and  4% of their cart total, depending on the products being purchased.</p>'},
        ],
        impact_steps: [
        {title: 'Integrate with a click', text:'Integrating CURBON into your online store requires just a few steps. Add the plugin from the app store and follow the prompts. Once the plugin is installed, customised and activated, you’re all set.',image:'images/assets/Illustrations/impact-01.png'},
        {title: 'Customise CURBON to your platform',text:'Our customisable templates allow you to modify CURBON’s plugin to seamlessly pair with your site’s design.  Tell us a little about your store offering and our team will evaluate your emissions to give your customers the most accurate carbon offsetting solution.',image:'images/assets/Illustrations/impact-02.png'},
        {title: 'Provide a carbon offsetting solution',text:'This is climate action made easy. Once CURBON’s plugin is up and running, customers have the option to offset the emissions of their purchases with just the click of a button.',image:'images/assets/Illustrations/impact-03.png'},
        {title: 'Analyse store impact',text:'View and analyse the impact you’re making with your personalised dashboard. CURBON offers you data-driven insights into your customers carbon impact and how we’re working to neutralise it.',image:'images/assets/Illustrations/impact-04.png'},
        {title: 'Improve brand awareness',text:'Choosing CURBON means choosing to do things differently. Share your company’s CURBON-captured stats on your channels and let people know you’re doing your part in working toward a cleaner tomorrow.',image:'images/assets/Illustrations/impact-05.png'},
        ],
        partners:[
        {image:'images/assets/Icons/Credible-carbon-white-square.png',name:'Credible Carbon',link:'https://www.crediblecarbon.com/'},
        {image:'images/assets/Icons/Exiobase.svg',name:'Exiobase',link:'https://www.exiobase.eu/'},
        // {image:'images/assets/Icons/Goldstandard.svg',name:'Gold Standard',link:'https://www.goldstandard.org/'},
        {image:'images/assets/Icons/Greenhouse.svg',name:'The Green House',link:'https://tgh.co.za/'},
        ],
        communities:[
        {image:'images/assets/Logos/tvyg.gif',},
        // {image:'images/assets/Logos/happy.gif',},
        {image:'images/assets/Logos/sealand.png',},
        {image:'images/assets/Logos/shifter.png',},
        {image:'images/assets/Logos/ug-logo.png',},
        {image:'images/assets/Logos/conscious-lifestyle-collective-logo.png',},
        ],
        newspaper_articles:[
        {image:'images/assets/Images/moneyweb.png',link:'https://www.moneyweb.co.za/news/tech/local-e-commerce-startup-aims-to-curb-its-industrys-carbon-emissions/'},
        {image:'images/assets/Images/tech-financials.png',link:'https://techfinancials.co.za/2022/10/25/sa-startup-curbon-takes-bold-offset-and-uplift-approach-to-solve-climate-change/'},
        {image:'images/assets/Images/capetalk.png',link:'https://omny.fm/shows/afternoons-with-pippa-hudson/advice-life-hacks-carbon-offsetting-25-25'},
        {image:'images/assets/Images/times-live.png',link:'https://www.timeslive.co.za/sunday-times/lifestyle/fashion-and-beauty/2022-09-25-es-celebrating-the-designers-creators-innovators-changing-fashion-practices/'},
        ],
        hero_list:[
        {title:'Zero cost to company ',},
        {title:'Integrate on all leading platforms',},
        {title:'Increase checkouts',},
        {title:'Set up and go live in minutes ',},
        ],
      }
    },
    mounted: function() {
        this.aminateHeroTabs();
        this.aminateImpactTabs();
        this.showCookie();
    },
    methods: {
        aminateHeroTabs() {
            setTimeout(() => {
                this.hero_tab ++;
                this.aminateHeroTabs();
            },
            2000)
        },

        aminateImpactTabs() {
            setTimeout(()=>{
                if(this.tab_is_animated) {
                    this.impact_tab ++;
                    this.aminateImpactTabs();
                }
            },
            2500)
        },
        stopImpactTabAmination() {
            this.tab_is_animated = false;
        },

        hideArrow(){
            const arrow= document.getElementById('illustration_btn')
            arrow.style.opacity=0;
        },
    }
}
</script>

<style type="text/css">
    .theme--light.v-btn.v-btn--disabled {
        color: rgba(0, 0, 0, 1) !important;
    }
</style>
