import axios from "axios";
import router from '@/router';
import VueCookies from 'vue-cookies';
import { Ability } from '@casl/ability';  

function state () {
  return {
    login_status: '',
    user: {}
  }
}

// Getters
function getIsLoggedIn(state) {
  return (state.login_status == 'success' ? true : false);
}

function getLoginStatus(state){
  return state.login_status;
}

function getUser(state){
  return state.user;
}

const getters = {
  getIsLoggedIn,
  getLoginStatus,
  getUser,
};

//Actions
const actions = {
  login({ commit }, credentials) {
    return new Promise((resolve, reject) => {
      commit("AUTH_REQUEST");
      
      axios.get(process.env.VUE_APP_API_URL + '../../../sanctum/csrf-cookie')
      .then(() => {
           axios
              .post(process.env.VUE_APP_API_URL + "/login", credentials)
              .then((response) => {
                commit("AUTH_SUCCESS");
                commit("SET_USER", response.data.user);
                resolve(response);
              })
              .catch((err) => {
                commit("AUTH_ERROR");
                commit("SET_USER", {email: null});
                reject(err);
              });
      });

    });
  },

  async fetchUser({ commit }) {
    try {
        var xsrf_cookie = VueCookies.get('XSRF-TOKEN');
        if(xsrf_cookie != null) {
          const response = await axios.get(process.env.VUE_APP_API_URL + '/users/me');
          commit('SET_USER', response.data.user);
          commit("AUTH_SUCCESS");
        }
      }
      catch (error) {
        commit("AUTH_ERROR");
      }
  },

  logout({ commit }) {
    return new Promise((resolve) => {

      axios
        .get(process.env.VUE_APP_API_URL + "/logout")
        .then(() => {
          commit("LOGOUT");
          if(router.currentRoute.path != '/login') {
            router.push('/login');
          }
          
        });
      resolve();
    });
  },

  networkError({ commit }) {
    commit("AUTH_ERROR");
  }
};

//Mutations
const mutations = {
  SET_USER(state, data){
    //Set user from API call
    state.user = data;

    //Also create ability object
    state.user.ability = new Ability(data.casl_permissions);
  },
  AUTH_REQUEST(state) {
    state.login_status = "loading";
    state.loginError = false;
  },
  AUTH_SUCCESS(state) {
    state.login_status = "success";
  },
  AUTH_ERROR(state) {
    state.login_status = "error";
  },
  LOGOUT(state) {
    state.login_status = "";
    state.user = {};
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
