<template>
  <v-container>
    <v-card class="mb-6 projectsPurple mt-0 dashboardCard">
       <div class="rightBars rightGrayBars rightGrayBarsCard"></div>
        <v-row justify="space-between">
          <v-col lg="4" class="pa-5">
            <div style="float:left">
              <v-avatar fab class="white pa-1  mr-5" >
              <v-icon>mdi-account</v-icon>
              </v-avatar> 
              <p style="color:black; float: right" class="description mt-4">User Profile</p>
            </div>
          </v-col>
          <v-col class="pa-5 my-auto" justify="end" lg="4">
          </v-col>
        </v-row>
    </v-card>

    <v-card  class="my-6 dashboardCard">
      <v-form v-if="user != {}">
        <v-container>
          <v-row>
              <v-col
              cols="12"
              md="4"
              >
              <v-text-field
                  v-model='form.first_name'
                  :counter="20"
                  label="First name"
                  required
              ></v-text-field>
              </v-col>
               <v-col
              cols="12"
              md="4"
              >
              <v-text-field
                  v-model="form.last_name"
                  :counter="20"
                  label="Last name"
                  required
              ></v-text-field>
              </v-col>
          </v-row>
          
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
            <v-text-field
                v-model="form.phone"
                label="Phone"
            ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                :value="form.email"
                label="Email"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        <div>
        </div>


        <v-btn
          class="ma-2"
          dark
          to="/dashboard/users"
        >
          <!-- <v-icon
            dark
            left
          >
          mdi-minus-circle
          </v-icon> -->
          Back
        </v-btn>

        <v-btn
          class="ma-2"
          :loading="loading"
          :disabled="loading"
          color="success"
          @click="updateUser(user_profile.slug)"
        >
          Save
        </v-btn>
        <v-dialog
          v-model="changePassword"
          persistent
          max-width="600px"
        >
          <template v-slot:activator="{ on, attr }">
            <v-btn
              color="primary"
              class="ma-2"
              dark
              v-bind="attr"
              v-on="on"
            >
              Change password
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">Change Password</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="password_form.current_password"
                      label="Current Password *"
                      type="password"
                      required
                    ></v-text-field>
                  </v-col>
                    <v-col cols="12">
                    <v-text-field
                      v-model="password_form.new_password"
                      label="New Password *"
                      type="password"
                      required
                    ></v-text-field>
                  </v-col>
                    <v-col cols="12">
                    <v-text-field
                      v-model="confirm_password"
                      label="Confirm Password *"
                      type="password"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
              <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="changePassword = false"
              >
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                 @click="updatePassword(user_profile)"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="dialog"
          persistent
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ma-2" color="red" dark
              v-bind="attrs"
              v-on="on"
            >
              Deactivate Account
            </v-btn>
          </template>

          <v-card>
            <v-alert type="error"> </v-alert>

            <v-card-title class="text-h6">
              You are about to Delete your account!
            </v-card-title>

            <v-card-text> 
              Are you sure you want to Proceed with deleting your account?.
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="dialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="red darken-1"
                text
                @click="deleteUserAccount(user)"
              >
                Deactivate
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </v-container>
  </v-form>

    <div v-if="user.ability.can('view', 'users')"> 
      <h3>Roles</h3>
      <ul>
        <li v-for="role in user.roles" :key="role.id">{{role.name}}</li>
      </ul>

      <br>

      <h3>Permissions</h3>
      <ul v-for="role in user.roles" :key="role.id">
        <li v-for="permission in role.permissions" :key="permission.id">{{permission.name}}</li>
      </ul>
    </div>
        </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import SuccessAlert from "@/mixins/successAlert";
import ErrorAlert from "@/mixins/errorAlert";

export default {
  name: "UserProfile",
  mixins: [SuccessAlert, ErrorAlert],
  data() {
    return {
      ability: Object,

      dialog: false,
      changePassword:false,
      confirm_password:"",

      password_form:{
        current_password:"",
        new_password:"",
      },

      form:{
        first_name:"",
        last_name:"",
        phone:"",
        email:"",
        creator_id:"",
        is_active:"",
        pw_reset_required:"",
      },
      loading:false,
    }
  },

  computed: {
    user: {
      get() {
        return this.$store.getters.getUser;
      },
    },
  },
  
  mounted() {
    this.getUserProfile();
  },

  methods: {
    getUserProfile() {
      axios
        .get(process.env.VUE_APP_API_URL + '/users/'+ this.$route.params.slug)
        .then((response) => {
          this.form = response.data.user;
          this.user_profile = response.data.user;
        })
    },

    deleteUserAccount(user) {
      this.dialog = false;
      axios
        .delete(process.env.VUE_APP_API_URL + '/users/'+ user.slug)
        .then(() => {
          this.successAlert("User Deactivated", "successfully"); //this is a mixin function successAlert($title,$message)
          setTimeout(() => {
          this.$router.push("/dashboard/users");
          }, 4000);
        })

        .catch((error) => {
          this.errorAlert("Deactivation failed.",Object.values(error.response.data.errors).join(' '));
        });
    },

    updatePassword(user){

         if(this.password_form.new_password!=this.confirm_password){
                this.errorAlert("Password mismatch!","Your passwords didn't match.");
            return;
        }

        axios
          .put(process.env.VUE_APP_API_URL + '/users/' + user.slug + '/password',this.password_form)
          .then(() => {
            this.successAlert("Password Updated", "successfully"); //this is a mixin function successAlert($title,$message)
            this.changePassword=false;
            setTimeout(() => {location.reload();}, 4000);
          })
          
          .catch((error) => {
            this.errorAlert("Failed to Update password.",Object.values(error.response.data.errors).join(' '));  //this is a mixin function errorAlert($message,$error)
          });
    },

    updateUser(user)
    {
        if(this.form.password_confirmation!=this.form.new_password){
                this.errorAlert("Password mismatch!","Your passwords didn't match.");
            return;
        }
        axios
          .put(process.env.VUE_APP_API_URL + "/users/"+user,this.form)
          .then(() => {
            this.successAlert("User Saved", "user account updated"); //this is a mixin function successAlert($title,$message)
            this.$router.push('/dashboard/users');
          })
          
          .catch((error) => {

            var error_object = error.response.data.errors;
            var error_string = '';
            var error_count = Object.keys(error_object).length;
            for(let k = 0; k < error_count; k++) {
              error_string = error_string + ' ' + error_object[Object.keys(error_object)[k]];
            }

            this.errorAlert("Failed to save user.",Object.values(error.response.data.errors).join(' '));  //this is a mixin function errorAlert($message,$error)
          });
    },
  },

  // data() {
  //     return {
  //         form: {
  //             email: "",
  //             password: "",
  //         },
  //     }
  // },

  // computed: {
  //   // user: {
  //   //   get() {
  //   //     return this.$store.getters.user;
  //   //   }
  //   // },
  //   // ability: {
  //   //   get() {
  //   //     return ability;
  //   //   }
  //   // }
  // },
};
</script>
<style></style>
