<template>
<div>
<v-container fluid class="purple-grad2" style="position:relative;">
  <div class="leftBars rightGrayBars projectsRightGrayBars hidden-sm-and-down"></div>
    <v-container  class="my-10" wrap style="position:relative">
      <v-row class="mt-md-15">
        <v-col cols="12" md="6" class="ml-5 ml-md-0">
            <p class="subTitle" >making a difference</p>
            <br>
            <p class="mr-10 projectsHeading">All of these projects were hand selected by our team of sustainability specialists, based on 
                their potential in offsetting carbon equivalent emissions and empowering communities. 
            </p>
        </v-col>
        <v-divider vertical class="lightGray hidden-md-and-down"></v-divider>
        <v-col cols="12" md="6" class="pt-0 hideScrollBar" v-scroll.self="projectScroll" style="overflow-y: scroll; scrollbar-width: none; height: 70vh">
          <div v-for="(project, index) in scrolled_projects" :key="index">
              <v-card tile class="mb-5 mt-0 mx-5 pa-5 projectsYellow" max-width="640px" flat router :to="`/projects/` + project.slug">
                <v-row>
                  <v-col>
                    <v-img max-width="300" :src='project.thumbnail'></v-img>  
                  </v-col>
                  <v-col>
                    <v-card-title class="bodyBig pl-0 pt-0">
                      <strong><div v-html="project.title_plain_text" style="white-space: wrap; word-break: initial"></div></strong>
                    </v-card-title>
                    <v-card-subtitle class="description pl-0">
                      {{project.country}}
                    </v-card-subtitle>
                    <v-card-actions class="pl-0">
                     <p class="smallBody">Learn more <v-icon>mdi-arrow-top-right</v-icon></p> 
                    </v-card-actions>
                  </v-col>
                </v-row>
              </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
</v-container>

<v-container fluid class="charcoal">
  <ReadyToGetStarted></ReadyToGetStarted>
</v-container>

</div>
</template>

<script>
import ReadyToGetStarted from '../components/ReadyToGetStarted.vue'

export default {
    name:'Projects',
    components:{
      ReadyToGetStarted
    },
    data: () => ({
      scroll_invoked: 0,
      scrolled_projects: null,
    }),
    computed: {
      projects: {
        get() {
          return this.$store.getters.getProjects;
        },
      },
    },
    mounted() {
      this.scrolled_projects = this.projects;
    },
    methods: {
      projectScroll() {
        this.scroll_invoked ++;
        if(this.scroll_invoked % 10 == 0) {
          this.scrolled_projects = this.scrolled_projects.concat(this.projects);
        }
      }
    }
}

</script>
