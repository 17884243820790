<template>
  <v-container bottom="0" fluid>
     <v-card class="mb-6 projectsPurple mt-0 dashboardCard">
       <div class="rightBars rightGrayBars rightGrayBarsCard"></div>
         <v-row justify="space-between">
          <v-col lg="4" class="pa-5">
            <div style="float:left">
              <v-avatar fab class="white pa-1  mr-5" >
              <v-icon>mdi-account-group</v-icon>
              </v-avatar> 
              <p style="color:black; float: right" class="description mt-4">Users</p>
            </div>
          </v-col>
          <v-col class="pa-5 my-auto" justify="end" lg="4">
            <v-btn to="/dashboard/users/create" color="secondary"  style="float: right">
              Create A User
            </v-btn>
          </v-col>
        </v-row>
    </v-card>

    <UsersTable :users="users"/>
  </v-container>
</template>

<script>
import axios from "axios";
import SuccessAlert from '@/mixins/successAlert';
import ErrorAlert from '@/mixins/errorAlert';

import UsersTable from './UsersTable';

export default {
    mixins:[SuccessAlert,ErrorAlert],
    name:'Users',

    components: {
      UsersTable,
    },
      
    data () {
      return {
        users:[],
        status:null,
      }
    },


    mounted: function() {
      this.getUsers();
    },

    methods: {
      getUsers() {
        axios
        .get(process.env.VUE_APP_API_URL + '/users/')
        .then((response) => {
          this.users = response.data.users;
        })
        // .catch((error) => 
        //   console.log(error)
        // );
      },

    },
  }

</script>
