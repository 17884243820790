<template>
    <v-container class="verify">
        <v-row class="mx-md-10 my-md-10 mt-md-15 pa-10">
            <v-col cols="12">
                <h1 class="h3 display-1">Please verify your email address</h1>
                
                <v-btn
                    elevation="2"
                    @click="resendEmailLink()"              
                    class="black white--text rounded-0 my-5 my-md-10"
                    width="100%"
                    depressed
                >
                    Request another verification email
                </v-btn>

                <v-btn
                    elevation="2"
                    @click="checkVerification()"              
                    class="rounded-0 my-5 my-md-10"
                    width="100%"
                    depressed
                >
                    Check verification
                </v-btn>

                <div v-if="is_loading">
                    <p class="h3">Sending...</p>
                </div>
                <div v-if="sending_failed">
                    <p class="h3">Please <a href="/login">log in</a> again.</p>
                </div>
                <div v-if="message_sent">
                    <p class="h3">Your verification email has been sent.</p>
                </div>
            </v-col>
        </v-row> 
    </v-container>
</template>

<script>
import axios from "axios";
import ErrorAlert from "../../mixins/errorAlert";
import SuccessAlert from "../../mixins/successAlert";

export default {
    mixins: [ErrorAlert, SuccessAlert],
    name: 'VerifyEmail',
    data() {
        return {    
            message_sent: false,
            is_loading: false,
            sending_failed: false,
        }
    },
    methods: {
        resendEmailLink() {
            this.sending_failed = false;
            this.message_sent = false;
            this.is_loading = true;
            
            axios
                .get(process.env.VUE_APP_API_URL + "/email/resend")
                .then((response) => {
                    this.is_loading = false;
                    this.message_sent = true;
                    if(response.status == 200) {
                        this.$router.push('/user-profile');
                    }
                })
                .catch(() => {
                    this.is_loading = false;
                    this.sending_failed = true;
                });
        },
        checkVerification() {
            axios
            .get(process.env.VUE_APP_API_URL + "/users/me")
            .then((response) => {
                if(response.data.user.email_verified_at != null) {
                    this.successAlert("Email verified","Redirecting...");
                    this.$store
                        .dispatch("fetchUser", this.form)
                        .then(() => {
                            this.$router.push('/dashboard');
                        })
                        .catch((err) => {
                            this.err = err;
                        });
                } else {
                    this.errorAlert("Email verification still required.", "Please request another verification link.");
                }
            })
            .catch(() => {
                //
            });
        }
    },
    mounted() {
        //do nothing
    }
}
</script>
