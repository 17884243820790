<template>
  <v-container>
     <v-card class="mb-6 projectsPurple mt-0 dashboardCard">
      <div class="rightBars rightGrayBars rightGrayBarsCard"></div>
      <v-row justify="space-between">
        <v-col lg="12" class="pa-5">
          <div style="float:left">
            <v-avatar fab class="white pa-1  mr-5" >
            <v-icon>mdi-store-settings</v-icon>
            </v-avatar> 
            <p style="color:black; float: right" class="description mt-4">Create an API key {{organisation ? 'for '+organisation.name : ''}}</p>
          </div>
        </v-col>
      </v-row>
    </v-card>
 
      <v-card class="dashboardCard">
      <p class=" description">Important note</p>   
      <ul class="smallBody">
        <li>This is the only time the API secret will be shown before it is encrypted in the database.</li>
        <li>You will be the only person to see the API secret.</li>
        <li>It is your responsibility to keep both the API key and secret secure.</li>
      </ul>
      </v-card>

     <v-card class="dashboardCard">
      <p class="description">   API key information</p>   
        <v-form v-if="api_key != null">
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model='api_key.key'
                  label="API Key"
                  required
                  readonly
                  :append-icon="'mdi-content-copy'"
                  @click="copyText('API key', api_key.key)"
                  @click:append="copyText('API key', api_key.key)"
                >
                </v-text-field>
              </v-col>
            </v-row>
          <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model='api_key.secret'
                  label="API secret"
                  required
                  readonly
                  :append-icon="'mdi-content-copy'"
                  @click="copyText('API secret', api_key.secret)"
                  @click:append="copyText('API secret', api_key.secret)"
                >
                </v-text-field>
              </v-col>
          </v-row>

          <v-btn
            class="ma-2"
            dark
            :to="'/dashboard/organisations/' + $route.params.slug + '/integration-setup'"
          >
            Back
          </v-btn>

          <v-btn
              class="ma-2"
              color="success"
              @click="saveApiKey"
          >
              Save and encrypt secret
          </v-btn>
          </v-container>
        </v-form>
    </v-card>
  </v-container>
</template>


<script type="text/javascript">
  
import axios from "axios";
import SuccessAlert from '@/mixins/successAlert';
import ErrorAlert from '@/mixins/errorAlert';

export default {
  name: "CreateApiKeys",
  mixins:[SuccessAlert,ErrorAlert],
      
  data () {
    return {
      organisation: null,
      api_key: {
        key: null,
        secret: null
      },
      search: ''
    }
  },

  mounted: function() {
    this.getOrganisation();
    this.getApiKeySeed();
  },

  methods: {
    getOrganisation() {
      axios
      .get(process.env.VUE_APP_API_URL + '/organisations/' + this.$route.params.slug)
      .then((response) => {
        this.organisation = response.data.organisation;
        this.users = this.organisation.users;
      })
    },
    getApiKeySeed(){
      axios
      .get(process.env.VUE_APP_API_URL + '/organisations/' + this.$route.params.slug + '/api-keys/create')
      .then((response) => {
        this.api_key = response.data.api_key;
      })
    },
    saveApiKey(){
      axios
      .post(process.env.VUE_APP_API_URL + '/organisations/' + this.$route.params.slug + '/api-keys', this.api_key)
      .then(() => {
        this.successAlert("Success", "API key created.");
        setTimeout(
          this.$router.push('/dashboard/organisations/' + this.$route.params.slug + '/integration-setup'),
          2000
        );
      })
      .catch((error) => {
        this.errorAlert("Error",Object.values(error.response.data.errors).join(' '));
      })
    },
    copyText(key, value){
      navigator.clipboard.writeText(value);
      this.successAlert(key + " copied",value);
    }
  },
}
</script>