<template>
    <div>
        <v-sheet
        max-width="800"
        height="300"
        class="mx-auto"
        >
        <h1 class="text-center">Your email has been verified</h1> 
        <p>Redirecting...</p>
      
        </v-sheet>
    </div>    
</template>

<script>
// import axios from "axios";

export default {
    name: 'EmailVerified',
    data() {
        return {    
            message_sent: false,
            is_loading: false,
            sending_failed: false,
        }
    },
    created() {
        setTimeout(
            () => {this.$router.push({ path: '/dashboard'})},
            2000
        )
    }
}
</script>
