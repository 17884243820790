<template>
  <v-container>
    <v-row class="text-left">
        <v-col cols="12" md="4">
            <v-card v-if="leads" class="mb-6 dashboardCard" max-width="344" height="160" router to="/dashboard/leads">
                <v-card-text>
                    <v-row class="pa-auto ma-auto">
                        <v-flex lg6>
                            <p class="my-5 bigBody" style="font-size:34px;">Leads</p>
                        </v-flex>
                        <v-flex lg6>
                            <v-avatar style="float:right; transform: scale(1)" fab class="combustion pa-1" width="10">
                                <v-icon>mdi-account-multiple-plus</v-icon>
                            </v-avatar> 
                        </v-flex>
                    </v-row>
                    <v-divider></v-divider>
                    <h3 class="mt-2">{{leads.length}}</h3>
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="12" md="4">
            <v-card v-if="users" flat class="mb-6 dashboardCard" max-width="344" height="160" router to="/dashboard/users">
                <v-card-text>
                    <v-row class="pa-auto ma-auto">
                        <v-flex lg6>
                            <p class="my-5 bigBody" style="font-size:34px;">Users</p>
                        </v-flex>
                        <v-flex lg6>
                            <v-avatar style="float:right; transform: scale(1);" fab class="dustyOrange pa-1" width="10">
                                <v-icon>mdi-account-group</v-icon>
                            </v-avatar> 
                        </v-flex>
                    </v-row>
                    <v-divider></v-divider>
                    <h3 class="mt-2">{{users.length}}</h3>
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="12" md="4">
            <v-card v-if="organisations" class="mb-6 dashboardCard" max-width="344" height="160" router to="/dashboard/organisations">
                <v-card-text>
                    <v-row class="pa-auto ma-auto">
                        <v-flex lg10>
                            <p class="my-5 bigBody" style="font-size:34px;">Stores</p>
                        </v-flex>
                        <v-flex lg2>
                            <v-avatar style="float:right; transform: scale(1)" fab class="sapling pa-1" width="10">
                                <v-icon>mdi-store-settings</v-icon>
                            </v-avatar> 
                        </v-flex>
                    </v-row>
                    <v-divider></v-divider>
                    <h3 class="mt-2">{{organisations.length}}</h3>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
    name:'dashboard',
    data() {
        return {
            organisations: null,
            leads: null,
            users: null,
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            axios
            .get(process.env.VUE_APP_API_URL + '/organisations')
            .then((response) => {
                this.organisations = response.data.organisations;
            })

            axios
            .get(process.env.VUE_APP_API_URL + '/users')
            .then((response) => {
                this.users = response.data.users;
            })

            axios
            .get(process.env.VUE_APP_API_URL + '/lead/leads')
            .then((response) => {
                this.leads = response.data.leads;
            })
        }
    }
}

</script>
